import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { Patient } from '@chiroup/core/types/Patient.type';
import {
  ConsolidatedTransactionGetResponse,
  PatientTransaction,
} from '@chiroup/core/types/PatientTransaction.type';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { QueryFunctionContext, useQuery, useQueryClient } from 'react-query';
import { MeContext } from '../../../../../contexts/me.context';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';
import patientBillingService from '../../../../../services/patientBilling.service';

export const CONSOLIDATED_TRANSACTION_KEY_BASE = ['consolidated-transaction'];

export type SetDataManuallyOptions = {
  trace?: boolean;
  updateSnapshot?: boolean;
};

const getQuery = function (clinicId: number, setQueryCache: any) {
  return async (context: QueryFunctionContext) => {
    const billingKey = context.queryKey[1] as string;
    if (!billingKey) return {} as any;
    if (billingKey === 'new') return {} as any;
    const res = (await patientBillingService.getConsolidatedTransaction(
      clinicId,
      billingKey,
    )) as unknown as ConsolidatedTransactionGetResponse;
    if (res && res?.items?.length) {
      setQueryCache(res);
    }
    return res;
  };
};

export const useConsolidatedTransaction = function ({
  billingKey,
  onFetchFail,
  trace = false,
  enabled = true,
}: {
  billingKey?: string | null;
  onFetchFail?: (e: any) => void;
  trace?: boolean;
  enabled?: boolean;
}) {
  const queryClient = useQueryClient();

  /**
   * Single point of truth please...
   *    Cached by groupId and by _each_ billingKey.
   *
   * [8746744786 by Brian] Yeah, I have no idea WHY
   * this is not updating the items in the ledger.
   * Everything is passed in. And if you close the
   * transaction and open it again, it is correct.
   * But it WILL NOT reflect the value of the data
   * that comes back from the REST call. Too deep?
   *
   * Anyway, setTimeout seems to fix it.
   *
   * @param payload
   */
  const setQueryCache = (payload: ConsolidatedTransactionGetResponse) => {
    setTimeout(() => {
      // console.log({ setQueryCache: payload });
      const groupId = payload?.items?.[0]?.groupId;
      payload?.items?.forEach((item: any) => {
        if (item?.billingKey) {
          const key = [...CONSOLIDATED_TRANSACTION_KEY_BASE, item?.billingKey];
          // console.log({ setQueryCache: { key } });
          queryClient.setQueryData(key, () => ({
            ...payload,
            dirty: Date.now(),
          }));
        }
      });
      if (groupId) {
        const key = [...CONSOLIDATED_TRANSACTION_KEY_BASE, groupId];
        // console.log({ setQueryCache: { key } });
        queryClient.setQueryData(key, () => ({
          ...payload,
          dirty: Date.now(),
        }));
      }
    }, 133); // Zero isn't enough...oy vey.
  };

  const QUERY_KEY_ARRAY = useMemo(() => {
    return [...CONSOLIDATED_TRANSACTION_KEY_BASE, billingKey];
  }, [billingKey]);
  const { me } = useContext(MeContext);
  const { createToast } = useContext(ToastContext);
  const [snapshot, setSnapshot] = useState<string>();
  const [items, setItems] = useState<PatientTransaction[]>([]);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [memoCount, setMemoCount] = useState<number>(0);
  const [isSaving, setIsSaving] = useState(false);
  const [failed, setFailed] = useState(false);
  const { status, data, isFetching, refetch, isError, error } =
    useQuery<ConsolidatedTransactionGetResponse>(
      QUERY_KEY_ARRAY,
      getQuery(me?.selectedClinic?.ID || -1, setQueryCache),
      {
        onError: (error: any) => {
          createToast({
            title: `Request Failed`,
            description: `${
              error?.response?.data?.message ??
              error?.response?.data?.error ??
              error?.message ??
              'Unknown error.'
            }`,
            type: ToastTypes.Fail,
            duration: 5000,
          });
          onFetchFail?.(error);
          setIsSaving(false);
          setFailed(true);
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
        keepPreviousData: false,
        enabled,
      },
    );

  const onCloseCleanup = useCallback(() => {
    setSnapshot(undefined);
    setItems([]);
    setPatient(null);
  }, []);

  const save = async ({
    payload,
  }: {
    payload: PatientTransaction[] | null | undefined;
  }) => {
    setIsSaving(true);
    try {
      // console.log('save', payload);
      const res = await patientBillingService.saveConsolidatedTransaction({
        clinicId: me?.selectedClinic?.ID,
        payload,
      });
      // console.log({ saveConsolidatedTransaction: res });
      if (res?.statusCode !== 200) {
        return res;
      }
      // The save response only returns issues and items.
      // we need to maintain the rest of the data.
      const existingData = ChiroUpJSON.clone(
        data,
      ) as ConsolidatedTransactionGetResponse;
      const newData = { ...existingData, items: res?.items ?? [] };
      setQueryCache(newData);
      setSnapshot(() => JSON.stringify(newData));
      // Not sure why this can't be returned and set... :-( without new REST all.
      queryClient.invalidateQueries({ queryKey: ['patientPackageList'] });
      queryClient.invalidateQueries({
        queryKey: ['patientsBillingBalances', payload?.[0]?.patient?.id],
        exact: true,
      });
      /**
       * If an appointment is there, we update any cached by the query client.
       * That way, if the transaction is open from the appointment flyout, the
       * entire screen does not have to reload. The hit to get the the appt. is
       * not that big compared to the appt. and the consolidated transaction.
       *
       * If you're curious, all the appointments, regardless of the key you
       * ask for, are identical except the _root_ appt. has the 'id' of the
       * one that was asked for.
       */
      if (res?.appointment) {
        for (const item of res?.items ?? []) {
          if (!item?.billingKey) continue;
          const queryKey = ['appointments', 'detail', item?.billingKey];
          queryClient.setQueryData(queryKey, (prev: any) => {
            return {
              ...prev,
              ...res?.appointment,
            };
          });
        }
      }

      return res;
    } catch (e: any) {
      createToast({
        title: `Failed to save transaction`,
        description: `${
          e?.response?.data?.message ??
          e?.response?.data?.error ??
          e?.message ??
          'Unknown error.'
        }`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  // When the data changes, we need to take a new snapshot AND
  // update the queryClient with the new data to prevent an
  // unnecessary REST call.
  useEffect(() => {
    if (data) {
      if (!snapshot) setSnapshot(JSON.stringify(data));
      const items = data?.items ?? [];
      const policies = data?.policies ?? [];
      const patient = data?.patient ?? {};
      const memos = data?.memos ?? null;

      for (const o of items) {
        queryClient.setQueryData(['transaction', o.billingKey], () => o);
      }
      setItems(items);
      setPatient(patient);

      if (memos) {
        setMemoCount(memos?.data?.length ?? 0);
        queryClient.setQueryData(
          [
            'patientNotes',
            patient.ID,
            {
              category: null,
              type: ['alert', 'notification'],
              locations: ['transaction'],
            },
          ],
          {
            pages: [
              {
                data: (memos as any)?.data ?? [],
                skip: 0,
              },
            ],
            pageParams: [null],
          },
        );
      }
      if (patient) {
        queryClient.setQueryData(['patients', patient.ID], patient);
      }
      if (trace) {
        console.log({
          NewData: {
            items,
            policies,
            patient,
          },
        });
      }
    }
  }, [data, queryClient, snapshot, trace]);

  const createAppointmentTransaction = async (
    appointmentId: string,
    clinicId: number,
  ) => {
    setIsSaving(true);

    try {
      await patientBillingService.createAppointmentTransaction({
        appointmentId,
        clinicId,
      });
      queryClient.invalidateQueries([
        ...CONSOLIDATED_TRANSACTION_KEY_BASE,
        appointmentId,
      ]);

      queryClient.setQueryData(
        ['appointments', 'detail', appointmentId],
        (prev: any) => {
          return {
            ...prev,
            hasPurchase: true,
          };
        },
      );
      return true;
    } catch (err) {
      console.error(err);
      createToast({
        title: 'Failed to create transaction',
        description: 'An error occurred while creating the transaction.',
        type: ToastTypes.Fail,
        duration: 5000,
      });
      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const rollback = () => {
    if (snapshot) {
      const data = ChiroUpJSON.parse(snapshot);
      setDataManually(data);
    }
  };

  const takeSnapshot = (snap?: ConsolidatedTransactionGetResponse) => {
    if (!snap) {
      snap = data;
    }
    setSnapshot(() => JSON.stringify({ ...snap, _: Date.now() }));
  };

  const setDataManually = (
    data: ConsolidatedTransactionGetResponse,
    options?: SetDataManuallyOptions,
  ) => {
    const updateSnapshot = options?.updateSnapshot === true;
    setQueryCache(data);
    if (updateSnapshot) {
      takeSnapshot(data);
    }
  };

  /**
   * This _should_ encapsulate updating a transaction _and_ the parent
   * consolidated transaction.
   *
   * Note: This does some shenanigans since it needs to update the
   * query cache under multiple keys:
   *
   *    1) The groupId
   *    2) The billing key for _each_ transaction in the items.
   *
   * The reason for this is due to the fact that someone may click on
   * either transaction in a list of transactions to see the single
   * consolidated transaction. So, 'data' may be cached under either
   * billingKey.
   *
   * The front-end component uses a useEffect to watch the groupData
   * in here. So, update data. The useEffect here will update the
   * groupData and the UI will show the new information.
   *
   * BTW, the groupId one is so we _can_ always find the data.
   *
   * Simple...right?
   *
   * @param transaction
   */
  const setTransactionManually = (transaction: PatientTransaction) => {
    queryClient.setQueryData(
      ['transaction', transaction.billingKey],
      transaction,
    );
    const groupId = transaction?.groupId;
    // Try #1 by groupId
    let currentData = queryClient.getQueryData([
      ...CONSOLIDATED_TRANSACTION_KEY_BASE,
      groupId,
    ]) as ConsolidatedTransactionGetResponse | undefined;
    // Try #2 by billingKey
    if (!currentData) {
      currentData = queryClient.getQueryData([
        ...CONSOLIDATED_TRANSACTION_KEY_BASE,
        transaction?.billingKey,
      ]) as ConsolidatedTransactionGetResponse | undefined;
    }
    if (currentData && currentData.items) {
      const newData = ChiroUpJSON.clone(currentData);
      newData.items = newData.items.map((item: any) => {
        if (item.billingKey === transaction.billingKey) {
          return transaction;
        }
        return item;
      });
      setQueryCache(newData);
      setSnapshot(() => JSON.stringify(newData));
    }
  };

  const invalidate = async () => {
    await queryClient.invalidateQueries(QUERY_KEY_ARRAY);
  };

  const statusCode = useMemo(() => {
    return (error as any)?.response?.data?.statusCode;
  }, [error]);

  const specificTransaction = useMemo(() => {
    return (data?.items ?? []).find((o) => o.billingKey === billingKey);
  }, [data, billingKey]);

  return {
    data,
    failed,
    isError,
    isFetching,
    isSaving,
    items,
    memoCount,
    onCloseCleanup,
    patient,
    refetch,
    rollback,
    save,
    setDataManually,
    setTransactionManually,
    setSnapshot,
    snapshot,
    status,
    takeSnapshot,
    createAppointmentTransaction,
    is404: statusCode === 404,
    invalidate,
    specificTransaction,
  };
};
