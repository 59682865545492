import { Transaction } from '@tiptap/pm/state';
import type { Editor, JSONContent } from '@tiptap/react';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import {
  forwardRef,
  useCallback,
  useId,
  useImperativeHandle,
  useRef,
} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Icons } from '../icons';
import EditorIcon from './EditorIcon';
import { FieldErrors } from './FieldErrors';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import { classNames } from '@chiroup/core/functions/classNames';

type EditorStuffProps = {
  ref: any;
};

const EditorStuff = forwardRef<any, EditorStuffProps>((_, ref) => {
  const { editor } = useCurrentEditor();

  const editorRef: React.MutableRefObject<Editor | null> = useRef(null);

  useImperativeHandle(ref, () => ({
    clearContent: () => {
      editorRef.current?.commands.clearContent();
    },
    focus: () => {
      editorRef.current?.commands.focus();
    },
    setContent: (content: string) => {
      editorRef.current?.commands.setContent(content);
    },
    getContent: () => {
      return editorRef.current?.getHTML();
    },
  }));
  if (!editor) return null;
  editorRef.current = editor;
  return null;
});

type MenuBarProps = {
  extraButtons?: {
    icon: Icons;
    type: 'setContent';
    fn: () => any;
  }[];
  ref: any;
};

const MenuBar = forwardRef<any, MenuBarProps>(({ extraButtons }, ref) => {
  const { editor } = useCurrentEditor();

  const setLink = useCallback(() => {
    if (!editor) return;
    const isLinked = editor.isActive('link');
    if (isLinked) {
      editor.chain().focus().unsetLink().run();
    } else {
      const previousUrl = editor.getAttributes('link').href;
      let url = window.prompt('Enter a URL to link to', previousUrl);

      if (url === null) {
        return;
      }

      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }
  }, [editor]);

  const clearFormatting = useCallback(() => {
    if (!editor) return;
    editor
      .chain()
      .focus('all')
      .selectAll()
      .clearNodes()
      .unsetAllMarks()
      .focus(1)
      .run();
  }, [editor]);

  if (!editor) return null;

  return (
    <Scrollbars
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      className="h-12 w-full overflow-y-hidden flex flex-row justify-between"
    >
      <div className="flex flex-row gap-1 border border-b-0 w-full border-gray-300 rounded-t-md transition duration-150 ease-in-out sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 outline-none p-2 h-12 bg-gray-50 justify-between">
        <div>
          <EditorIcon
            icon={Icons.bold}
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            isActive={editor.isActive('bold')}
          />
          <EditorIcon
            icon={Icons.italic}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
          />
          <EditorIcon
            icon={Icons.strikethrough}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            isActive={editor.isActive('strike')}
          />
          <EditorIcon
            icon={Icons.link}
            isActive={editor.isActive('link')}
            onClick={setLink}
          />
          <EditorIcon
            icon={Icons.unorderedList}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            isActive={editor.isActive('bulletList')}
          />
          <EditorIcon
            icon={Icons.orderedList}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            isActive={editor.isActive('orderedList')}
          />
          <EditorIcon
            icon={Icons.blockQuote}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            isActive={editor.isActive('blockquote')}
          />
          <EditorIcon
            icon={Icons.columnsHorizontal}
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
          />
          <EditorIcon
            icon={Icons.undo}
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().chain().focus().undo().run()}
          />
          <EditorIcon
            icon={Icons.redo}
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().chain().focus().redo().run()}
          />
          <EditorIcon icon={Icons.eraser} onClick={clearFormatting} />
        </div>
        <div>
          {extraButtons?.map((button) => (
            <EditorIcon
              icon={button.icon}
              onClick={async () => {
                const res = await button.fn?.();
                if (button.type === 'setContent') {
                  editor.commands.setContent(res || '');
                }
              }}
            />
          ))}
        </div>
      </div>
    </Scrollbars>
  );
});

type Props = {
  value?: JSONContent | string;
  name?: string;
  label?: string;
  onChange?: (val?: any) => any;
  className?: string;
  containerClassName?: string;
  small?: boolean;
  toolbar?: boolean;
  html?: boolean;
  text?: boolean;
  disabled?: boolean;
  extraButtons?: {
    icon: Icons;
    type: 'setContent';
    fn: () => any;
  }[];
  errors?: FormError;
  labelColor?: boolean;
  extensions: any[];
  rightSide?: React.ReactNode;
};

export const TiptapEditorBase = forwardRef<any, Props>(
  (
    {
      value,
      name,
      label,
      className = 'border block w-full border-gray-300 rounded-b-md transition duration-150 ease-in-out sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 outline-none p-2',
      containerClassName,
      onChange,
      small,
      toolbar = true,
      html = false,
      text = false,
      disabled = false,
      extraButtons,
      errors,
      labelColor = false,
      extensions = [],
      rightSide,
    },
    ref,
  ) => {
    const id = useId();
    const debouncedOnUpdate = ({
      editor,
      transaction,
    }: {
      editor: any;
      transaction: Transaction;
    }) => {
      const val = text
        ? editor.getText()
        : html
          ? editor.getHTML()
          : editor.getJSON();
      onChange?.(val);
    };

    return (
      <div
        className={classNames(
          'tiptap prose flex flex-col w-full !max-w-none bg-white dark:bg-darkGray-900',
          small ? 'tiptap-small' : '',
          containerClassName ?? '',
        )}
      >
        <div
          className={classNames(
            'flex flex-row justify-between items-bottom',
            rightSide ? 'pb-2' : '',
          )}
        >
          {label && (
            <label
              className={classNames(
                `block text-sm font-medium leading-5 sm:mt-px`,
                labelColor
                  ? 'text-primary-600 dark:text-darkGray-200'
                  : 'text-gray-900  dark:text-darkGray-200',
                rightSide ? 'sm:pt-4' : 'sm:pt-2',
              )}
              htmlFor={name || id}
            >
              {label}
            </label>
          )}
          {rightSide && (
            <div className="flex flex-row justify-end">{rightSide}</div>
          )}
        </div>
        <EditorProvider
          content={value}
          extensions={extensions}
          onUpdate={debouncedOnUpdate}
          editorProps={{
            attributes: {
              class: classNames(className, toolbar ? '' : 'rounded-t-md'),
              disabled: `${!!disabled}`,
              name: name || id,
            },
          }}
          slotBefore={
            toolbar ? <MenuBar extraButtons={extraButtons} ref={ref} /> : null
          }
        >
          <EditorStuff ref={ref} />
        </EditorProvider>
        <FieldErrors errors={errors} />
      </div>
    );
  },
);
