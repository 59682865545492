/**
 * TODO: What is the SEMANTICS from an invoice status perspective of
 * an individual payor line item that is backed out leaving others
 * alone? Partially pregnant as it were.
 *
 */
import {
  BusinessRuleSolution,
  Button,
  ButtonColors,
  Currency,
  HashCardDisplay,
  HashCardDisplayColors,
  icons,
  Loading,
  MakeBrowserWait,
  OpenClosedStates,
  Select,
  TimestampDisplay,
  Toggle,
  TrimAndTooltip,
  trivialDownload,
  TrivialTooltip,
} from '@chiroup/components';
import {
  BusinessRuleItemType,
  calculatePatientAge,
  STRING_ANY_HASH,
  STRING_BOOLEAN_HASH,
  STRING_NUMBER_HASH,
  STRING_STRING_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpBaseCommon,
  ChiroUpDayJsCommon,
} from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { classNames } from '@chiroup/core/functions/classNames';
import { clog } from '@chiroup/core/functions/clog';
import { createDecimal } from '@chiroup/core/functions/createDecimal';
import { formatCurrency } from '@chiroup/core/functions/format';
import { hasProperty } from '@chiroup/core/functions/hasProperty';
import { createUTCDayjs } from '@chiroup/core/functions/time';
import businessRulesService from '@chiroup/core/services/businessRules.service';
import {
  ClaimResponseEraStatus,
  ClaimResponseEraType,
  ClaimServiceItemType,
} from '@chiroup/core/types/Era.type';
import {
  AdjustmentActionType,
  AllInvoiceStatusOptions,
  AllowedInvoiceStatusTransitions,
  IntegrationInvoice,
  InvoiceGenerationEdiXref,
  InvoiceStatusData,
  InvoiceStatusDataItemType,
  InvoiceStatusEnum,
  InvoiceStatusTips,
  isaInvoiceAdjustableStatus,
  PayorPriorityDisplay,
  SupportedInvoiceActions,
  uiPayorInvoiceLineItem,
} from '@chiroup/core/types/Invoice.type';
import { PatientBillingServiceOptions } from '@chiroup/core/types/PatientBillling.type';
import {
  primaryInsuredNameDisplay,
  primaryInsuredTypeDisplay,
  PrimaryInsuredTypes,
} from '@chiroup/core/types/PatientInsurance.type';
import {
  PAYMENT_TYPE_DISPLAY,
  PayorPaymentTypesHash,
} from '@chiroup/core/types/PatientPayment.type';
import {
  isaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
  TransactionItemTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { ClaimInfo } from '@chiroup/core/types/PatientVisit.type';
import { PayorLineItemType } from '@chiroup/core/types/Payor.type';
import {
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnUpIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentListIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CogIcon,
  DocumentMinusIcon,
  DocumentPlusIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import { integrationService } from '../../services/integration.service';
import patientBillingService from '../../services/patientBilling.service';
import IconButton from '../common/IconButton';
import Modal from '../common/Modal';
import ClaimInfoForm from '../patients/detail/visits/notes/ClaimInfoForm';
import DatabaseTable from '../settings/database/DatabaseTable';
import useIntegrationInvoice from './hooks/useIntegrationInvoice';
import InvoiceNotesModal from './InvoiceNotesModal';
import { metaByInvoiceStatus } from './metaByInvoiceStatus';

const ClassNames = {
  adjustmentSeps: 'px-4 py-2 text-gray-400 font-semibold bg-gray-50',
  noPayments:
    'mb-2 rounded-none px-8 pb-2 text-gray-400 italic border-gray-400',
  cre: {
    numbersValue: 'flex justify-end',
    firstNumberValue: 'flex',
    numbersLabel: 'text-xs flex justify-end text-gray-400',
    firstNumberLabel: 'text-xs flex text-gray-400',
    fieldDiv: 'py-1 flex flex-row justify-between',
    firstIdentLabel: 'text-xs flex text-gray-400 justify-start',
    firstIdentValue: 'flex justify-start',
    identValue: 'flex justify-end',
    identLabel: 'text-xs flex text-gray-400 justify-end',
    lastLabel: 'text-xs flex text-gray-400 justify-end',
    lastValue: 'flex justify-end',
    firstLastLabel: 'text-xs flex text-gray-400 justify-start',
    firstLastValue: 'flex justify-start',
  },
  callback:
    'flex justify-end font-semibold text-primary-400 hover:text-primary-600 h-8 w-8 w-full pt-1',
  sectionClassName: 'px-4 text-sm font-semibold flex',
  table: {
    firstTh: 'font-normal text-sm',
    th: 'font-normal text-sm text-right',
    padTh: 'font-normal text-sm text-right px-2',
    leftTh: 'font-normal text-sm text-left',
    firstCell: '',
    cell: 'text-right',
    leftCell: 'text-left',
    iFirstTh: 'font-normal text-sm',
  },
  subHeadingNote: 'font-normal text-sm',
  subHeadingNoteSep: 'px-4 pt-2 text-black font-normal',
  circle:
    'min-w-6 flex items-center justify-center w-6 h-6 rounded-full text-primary-500 border border-primary-500',
  trash:
    'flex justify-end grow min-w-6 text-primary-400 cursor-pointer hover:text-primary-600',
  noServicesFound: 'text-gray-400 italic',
  td: {
    xySize: 'h-8 w-8',
  },
  iblock: {
    container: 'w-1/4',
    heading: 'flex flex-row space-x-2',
    title: 'uppercase sans-serif text-base font-semibold after:content-[":"]',
    link: 'font-semibold',
    content: 'mt-2 text-sm',
    singleton: 'text-base font-semibold whitespace-nowrap overflow-hidden',
  },
  kv: {
    block: 'flex flex-row space-x-2',
    k: `text-base whitespace-nowrap overflow-hidden after:content-[':']`,
    v: 'text-base font-semibold',
    blockRow: 'flex flex-row justify-between px-4',
  },
  thClassName: 'bg-gray-100 text-gray-900 font-semibold uppercase text-sm',
};

type Props = {
  invoiceId: number;
  state: OpenClosedStates;
  close?: (isDirty?: boolean) => void;
  clinicId: number;
  trace?: boolean;
  containerCallback: (_: IntegrationInvoice | null | undefined) => void;
  updateInvoiceCallback?: (_: {
    invoice: IntegrationInvoice | null | undefined;
    refetch?: boolean;
  }) => void;
  readonly?: boolean;
  onModalStateChange?: (args?: any) => void;
  fixAppliedCallback?: (response: any) => void;
  patientBillingServiceOptions?: PatientBillingServiceOptions;
  view?: 'legacy' | 'new';
};

const CommonInvoiceModal: React.FC<Props> = ({
  invoiceId,
  state,
  trace = false,
  close = (isDirty?: boolean) => {
    if (trace) clog(`Close not implemented: ${isDirty}.`);
  },
  updateInvoiceCallback = (_) => {
    if (trace) {
      clog('CommonInvoiceModal :: updateInvoiceCallback not implemented.');
    }
  },
  readonly = false,
  fixAppliedCallback,
  patientBillingServiceOptions = {},
  view = 'new',
}) => {
  const { pathname } = useLocation();
  const [expandedClaimInfo, setExpandedClaimInfo] = useState(false);
  if (!invoiceId || invoiceId <= 0) {
    const parts = pathname.split('/');
    invoiceId = Number(parts[parts.length - 1]);
  }
  const { me } = useContext(MeContext);
  const clinicId = me?.selectedClinic?.ID ?? -1;
  const {
    adjust,
    data: rawInvoice,
    toggleElectronicBilling,
    isRestActive: toggleRestActive,
    setInvoiceData,
    isFetching,
    restFailed,
    refetchData,
    setRestFailed,
  } = useIntegrationInvoice({
    clinicId,
    id: invoiceId,
  });
  const invoice = useMemo(() => {
    if (!rawInvoice) {
      return {} as IntegrationInvoice;
    }
    if (
      rawInvoice?.status === InvoiceStatusEnum.Pending ||
      rawInvoice?.status === InvoiceStatusEnum.Unsubmitted
    ) {
      setExpandedClaimInfo(true);
    }
    return rawInvoice;
  }, [rawInvoice]);

  const metaComponents = useMemo(() => {
    return (
      metaByInvoiceStatus({
        invoice,
        readonly,
        callback: (args: any) => {
          updateInvoiceCallback(args);
          const invoice = args?.invoice || null;
          if (invoice) setInvoiceData(invoice);
        },
      }) ?? {}
    );
  }, [invoice, readonly, setInvoiceData, updateInvoiceCallback]);

  const lastStatusUpdate = useMemo(() => {
    return invoice?.statusData?.items?.[0] as InvoiceStatusDataItemType & {
      userName: string;
    };
  }, [invoice?.statusData?.items]);

  const [adjustPayload, setAdjustPayload] = useState<any>(null);
  const [adjustmentActions, setAdjustmentActions] = useState<
    AdjustmentActionType[]
  >([]);

  const adjustResponses = useMemo(() => {
    if (!adjustPayload?.adjust?.responses) return [];
    return adjustPayload?.adjust?.responses ?? [];
  }, [adjustPayload]);

  const adjustPayments = useMemo(() => {
    // return [];
    if (!adjustPayload?.adjust?.payments) return [];
    return adjustPayload?.adjust.payments;
  }, [adjustPayload]);

  const adjustEras = useMemo(() => {
    return adjustPayload?.adjust?.eras ?? [];
  }, [adjustPayload]);

  const adjustItems = useMemo(() => {
    return adjustPayload?.adjust?.items ?? [];
  }, [adjustPayload]);

  const actionContext = useMemo(() => {
    const actionsByKey: STRING_NUMBER_HASH = {},
      actionObjectsByKey: STRING_ANY_HASH = {},
      unpostByCode: { [key: string]: AdjustmentActionType[] } = {},
      postByCode: { [key: string]: AdjustmentActionType[] } = {},
      responsesWithoutServices: STRING_ANY_HASH = {},
      creIdsWithoutServices: STRING_ANY_HASH = {};

    let countOfResponsesWithoutServices = 0;

    const erasById = adjustEras.reduce(
      (acc: AdjustmentEraTypeHash, era: AdjustmentEraType) => {
        acc[String(era.id)] = era;
        return acc;
      },
      {} as AdjustmentEraTypeHash,
    );

    const insuranceById: {
        [key: string]: {
          name?: string;
          code?: string;
          payorId?: number;
          invoice?: boolean;
          pli?: boolean;
          cre?: boolean;
          payment?: boolean;
        };
      } = {},
      key = invoice?.payorId ?? -1;
    if (key > 0) {
      insuranceById[key] = {
        name: invoice?.legalName,
        code: '',
        payorId: key,
        invoice: true,
      };
    }

    invoice?.payorLineItems?.forEach((item) => {
      const key = item.payorId ?? -1;
      if (key > 0) {
        if (!insuranceById[key]) {
          insuranceById[key] = {
            name: '- na -',
            code: '- na -',
            payorId: key,
            pli: true,
          };
        } else {
          insuranceById[key].pli = true;
        }
      }
    });

    adjustmentActions.forEach((item, ord: number) => {
      const key = [item.action, item.type, item.pk].join('.');
      actionsByKey[key] = ord + 1;
      actionObjectsByKey[key] = item;

      if (item.action === 'unpost') {
        const code = item?.row?.adjudicatedProcedureCode ?? '-na-';
        if (!unpostByCode[code]) {
          unpostByCode[code] = [];
        }
        unpostByCode[code].push(item);
      } else if (item.action === 'post') {
        const code = item?.row?.adjudicatedServiceCode ?? '-na-';
        if (!postByCode[code]) {
          postByCode[code] = [];
        }
        postByCode[code].push(item);
      }
    });

    adjustResponses?.forEach((item: any, ord: number) => {
      if (!item?.serviceItems?.length) {
        responsesWithoutServices[item.eraId] = true;
        countOfResponsesWithoutServices++;
        if (item?.id) {
          creIdsWithoutServices[item?.id] = item;
        }
      }
      const key = item?.payorId ?? -1;
      if (key > 0) {
        if (!insuranceById[key]) {
          insuranceById[key] = {
            name: item.payorName,
            code: item.payorCode,
            payorId: key,
            cre: true,
          };
        } else {
          insuranceById[key].code = item?.payorCode || insuranceById[key].code;
          insuranceById[key].cre = true;
        }
      }
    });
    adjustPayments?.forEach((item: any, ord: number) => {
      const key = item?.payorId ?? -1;
      if (key > 0) {
        if (!insuranceById[key]) {
          insuranceById[key] = {
            name: item.payorName,
            code: item.payorCode,
            payorId: key,
            payment: true,
          };
        } else {
          insuranceById[key].code = item?.payorCode || insuranceById[key].code;
          insuranceById[key].payment = true;
        }
      }
    });

    const payorsByCode: STRING_STRING_HASH = Object.keys(insuranceById).reduce(
      (acc, key) => {
        const item = insuranceById[key],
          code = item?.code ?? '-1';
        if (code) {
          acc[code] = item.name ?? '- na -';
        }
        return acc;
      },
      {} as STRING_STRING_HASH,
    );

    return {
      actionObjectsByKey,
      actionsByKey,
      adjustItems,
      adjustmentActions,
      countOfResponsesWithoutServices,
      creIdsWithoutServices,
      erasById,
      insuranceById,
      payorsByCode,
      postByCode,
      responsesWithoutServices,
      unpostByCode,
    };
  }, [
    adjustEras,
    adjustItems,
    adjustPayments,
    adjustResponses,
    adjustmentActions,
    invoice?.legalName,
    invoice?.payorId,
    invoice?.payorLineItems,
  ]);

  const actionsByKey = useMemo(() => {
    return actionContext?.actionsByKey ?? {};
  }, [actionContext?.actionsByKey]);

  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [adjustBilledServices, setAdjustBilledServices] = useState(false);
  const [newStatus, setNewStatus] = useState<InvoiceStatusEnum | null>(null);
  const { selectedLocationFull } = useContext(MeContext);
  const navigate = useNavigate();

  const [showInvoiceNotesModal, setShowInvoiceNotesModal] = useState(false);
  const handleOpenInvoiceNotes = (invoiceId: number) => {
    setShowInvoiceNotesModal(true);
  };
  const flagged = invoice?.isFlagged;
  const noteColor = flagged ? 'text-orange-400' : 'text-darkBlue-400';

  const shrinkButtons = useMemo(() => {
    return (
      invoice.status === InvoiceStatusEnum.Submitted &&
      invoice.electronicBilling === false
    );
  }, [invoice]);

  //  In case we want to do something before closing.
  const componentClose = (isDirty?: boolean) => {
    close(isDirty);
  };

  const eraIds = useMemo(() => {
    return invoice?.eraIds ?? [];
  }, [invoice?.eraIds]);

  const originalDiagnosesByCode = useMemo(() => {
    const items = invoice?.transactionItems ?? [];
    return items.reduce((acc: STRING_STRING_HASH, item) => {
      if (item?.diags) {
        acc[item.code] = item.diags.join(', ');
      }
      return acc;
    }, {} as STRING_STRING_HASH);
  }, [invoice?.transactionItems]);

  const eraHistory = useMemo(() => {
    if (!invoice) return null;
    if (!invoice.statusData) return null;
    if (!invoice.statusData.items) return null;
    let hits = 0;
    const history: STRING_ANY_HASH = {};
    // Reverse order so oldest are on the top if they
    const omg = ChiroUpJSON.clone(
      invoice?.statusData?.items,
    ).reverse() as InvoiceStatusDataItemType[];
    omg.forEach((item) => {
      const ts = Number(item.ts);
      if (item.payorLineItems) {
        hits++;
        item.payorLineItems.forEach((lineItem) => {
          const eraId = [lineItem.eraId ?? 'No ERA', hits].join('.');
          if (!history[eraId]) {
            history[eraId] = [];
          }
          history[eraId].push({ ...lineItem, ts });
        });
      }
    });
    return history;
  }, [invoice]);

  const metaContext = useMemo(() => {
    if (!invoice) return {};
    const res = {
      [invoice.id]: {
        delayReason: invoice.delayReason,
        generation: invoice.generation,
        claimControlNumber: invoice.claimControlNumber,
      },
    };
    return res;
  }, [invoice]);

  useEffect(() => {
    if (!invoice) return;
    setNewStatus(invoice.status as InvoiceStatusEnum);
  }, [invoice]);

  const validTransitions = useMemo(() => {
    if (!invoice) return [];
    return AllInvoiceStatusOptions.filter((option) => {
      return (
        AllowedInvoiceStatusTransitions[invoice.status as InvoiceStatusEnum]?.[
          option.value
        ] || option.value === invoice.status
      );
    });
  }, [invoice]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const disableToggle = useMemo(() => {
    if (!invoice) return false;
    return (
      invoice.status === InvoiceStatusEnum.Submitted ||
      invoice.status === InvoiceStatusEnum.Received ||
      invoice.status === InvoiceStatusEnum.Reprocessed ||
      invoice.status === InvoiceStatusEnum.Partial ||
      invoice.status === InvoiceStatusEnum.Paid
    );
  }, [invoice]);

  const [resolvedSomething, setResolvedSomething] = useState(false);
  const { createToast } = useContext(ToastContext);
  const [isRestActive, setIsRestActive] = useState<{
    resolve?: boolean;
    save?: boolean;
    test?: boolean;
    submit?: boolean;
    download?: boolean;
    downloadComplete?: boolean;
    downloadFields?: boolean;
    updateStatus?: boolean;
    saveResolved?: boolean;
    any?: boolean;
  }>({});
  // const [downloadContent, setDownloadContent] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  // 'saved' is used to decide if the buttons have been pushed once already.
  // The modal goes unresponsive and needs to be closed in re-opened at that
  // point. Might not be the desired behavior, but that was the idea.
  const [saved, setSaved] = useState<boolean>(false);

  const anyRestActive = useMemo(() => {
    return (
      isRestActive.resolve ||
      isRestActive.save ||
      isRestActive.test ||
      isRestActive.submit ||
      isRestActive.download ||
      isRestActive.downloadComplete ||
      isRestActive.downloadFields ||
      isRestActive.updateStatus ||
      isRestActive.saveResolved ||
      isRestActive.any ||
      toggleRestActive
    );
  }, [
    isRestActive.any,
    isRestActive.download,
    isRestActive.downloadComplete,
    isRestActive.downloadFields,
    isRestActive.resolve,
    isRestActive.save,
    isRestActive.saveResolved,
    isRestActive.submit,
    isRestActive.test,
    isRestActive.updateStatus,
    toggleRestActive,
  ]);

  const noStatus = useMemo(() => {
    return (
      !invoice ||
      !invoice.statusData ||
      !invoice.statusData.items ||
      !invoice.statusData.items[0] ||
      !invoice.statusData.items[0].final
    );
  }, [invoice]);

  const latestInvoiceMessages = useMemo(() => {
    if (!invoice) return null;
    const statusData =
        typeof invoice?.statusData === 'string'
          ? ChiroUpJSON.parse(invoice?.statusData)
          : invoice?.statusData,
      items = statusData?.items ?? [],
      latest = items[0] ?? null,
      obj = {
        issues: latest?.issues ?? [],
        notes: latest?.notes ?? [],
      };
    return obj;
  }, [invoice]);

  const remitAmountsTotal = useMemo(() => {
    const plis = invoice?.payorLineItems ?? [];
    return plis
      .reduce((acc: Decimal, pli) => {
        const amt = createDecimal(pli?.remitAmount ?? 0);
        acc = acc.plus(amt);
        return acc;
      }, createDecimal(0))
      .toNumber();
  }, [invoice?.payorLineItems]);

  const hasIssues = useMemo(() => {
    return (
      latestInvoiceMessages &&
      latestInvoiceMessages.issues &&
      latestInvoiceMessages.issues.length !== 0
    );
  }, [latestInvoiceMessages]);

  const issues = useMemo(() => {
    return (latestInvoiceMessages?.issues || []) as BusinessRuleItemType[];
  }, [latestInvoiceMessages]);

  const notes = useMemo(() => {
    return (latestInvoiceMessages?.notes || []) as BusinessRuleItemType[];
  }, [latestInvoiceMessages]);

  const submitDisabled = useMemo(() => {
    if (!hasIssues) return false;
    return !resolvedSomething || hasIssues || saved || isRestActive.submit;
  }, [hasIssues, saved, isRestActive, resolvedSomething]);

  const _save = async (e?: any, passedInvoice?: IntegrationInvoice) => {
    const title = `Save Resolved Issues`;
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setIsRestActive((p) => ({ ...p, saveResolved: true }));

    try {
      const results = await patientBillingService.setInvoiceStatus({
          clinicId,
          invoiceId: invoice.id,
          issues: {
            [invoice.id]:
              passedInvoice?.statusData?.items?.[0]?.issues ?? issues ?? [],
          },
          options: patientBillingServiceOptions,
        }),
        result =
          results && results?.invoices?.[0] ? results?.invoices?.[0] : null,
        saveIssues = results?.issues ?? [],
        saveNotes = results?.notes ?? [];

      if (result) {
        setSaved(false); // Okay, we SAVED it, but we still want the buttons to be available.
        setIsDirty(false);
        setResolvedSomething(false);
        if (saveIssues && saveIssues.length) {
          createToast({
            title,
            description: `${
              saveIssues?.length
                ? saveIssues.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        } else {
          setInvoiceData(result);
          updateInvoiceCallback({ invoice: result });
        }
        if (saveNotes && saveNotes.length) {
          createToast({
            title,
            description: `${
              saveNotes?.length
                ? saveNotes.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No notes.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        }
      } else {
        createToast({
          title,
          description: `No result returned.`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
    } catch (e: any) {
      createToast({
        title: `Save Resolved Issues`,
        description: `${e.message ?? e.response?.data?.message}`,
        type: ToastTypes.Fail,
        duration: 5000,
      });
      console.error({ e });
    }
    setIsRestActive((p) => ({ ...p, saveResolved: false }));
  };

  const saveActiveIndicator = useMemo(() => {
    const saveResolvedEnabled =
      (resolvedSomething &&
        !saved &&
        !isRestActive.resolve &&
        !isRestActive.download &&
        !isRestActive.downloadComplete &&
        !isRestActive.downloadFields &&
        !isRestActive.test &&
        !isRestActive.submit) ||
      isDirty;
    if (!saveResolvedEnabled || readonly) return null;
    return (
      <div className="absolute top-1 right-4">
        {isRestActive.saveResolved ? (
          <Loading style={`tiny-inline`} color="text-tray-600 mt-1" />
        ) : null}
      </div>
    );
  }, [
    isDirty,
    isRestActive.download,
    isRestActive.downloadComplete,
    isRestActive.downloadFields,
    isRestActive.resolve,
    isRestActive.saveResolved,
    isRestActive.submit,
    isRestActive.test,
    readonly,
    resolvedSomething,
    saved,
  ]);

  const [autoSaveHandler, setAutoSaveHandler] = useState<NodeJS.Timeout | null>(
    null,
  );

  // const trimAndTooltip = useCallback(
  //   ({
  //     len = 80,
  //     text,
  //   }: {
  //     len?: number;
  //     text: string;
  //   }): JSX.Element | string => {
  //     const fullLength = text ? text.length : 0;
  //     if (!text || fullLength <= len) {
  //       return text;
  //     }

  //     const trimmedText = text.substring(0, len) + '...';
  //     return (
  //       <div className="flex flex-row space-x-2">
  //         <div className="flex">{trimmedText}</div>
  //         <TrivialTooltip
  //           text={[
  //             text,
  //             '[Click the icon to the right to copy the full-text to the clipboard.]',
  //           ].join(' ')}
  //           type={`info`}
  //           iconColorClassName="text-black"
  //         />
  //         {copied ? (
  //           <CheckIcon className="h-6 w-6 text-green-500" title="Copied!" />
  //         ) : (
  //           <ClipboardDocumentListIcon
  //             className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-700"
  //             title="Copy full text to clipboard."
  //             onClick={() => {
  //               navigator.clipboard.writeText(text);
  //               setCopied(true);
  //               setTimeout(() => setCopied(false), 3000);
  //             }}
  //           />
  //         )}
  //       </div>
  //     );
  //   },
  //   [copied],
  // );

  const resolveCallback = ({
    resolveId,
  }: {
    resolveId: number | null;
    location: any;
  }) => {
    if (typeof resolveId !== 'number') return;
    if (!issues) return;
    if (!issues[resolveId]) return;
    const clone = ChiroUpJSON.clone(invoice);
    clone.statusData.items[0].issues.splice(resolveId, 1);
    setInvoiceData(() => clone);
    setTimeout(() => {
      setSaved(false);
    }, 100);

    if (autoSaveHandler) {
      clearTimeout(autoSaveHandler);
    }
    setAutoSaveHandler(
      setTimeout(() => {
        _save(null, clone);
        setAutoSaveHandler(null);
      }, 1250), // debouce 1.25 seconds
    );

    setResolvedSomething(true);
    setIsDirty(true);
  };

  const age = useMemo(() => {
    if (!invoice || !invoice.transactionDate) return null;
    const now = new Date().getTime();
    const delta = (now - (invoice?.transactionDate ?? 0)) / 1000 / 60 / 60 / 24;
    return Math.floor(delta);
  }, [invoice]);

  const transactionItemsTotal = useMemo(() => {
    const items = invoice?.transactionItems ?? [];
    let resp = createDecimal(0);
    if (!items.length) return resp.toDecimalPlaces(2).toNumber();
    resp = items.reduce((a: Decimal, c: uiPayorInvoiceLineItem) => {
      if (!c) return a;
      const amt = createDecimal(c?.amount ?? 0).times(c?.units ?? 0);
      a = a.plus(amt);
      return a;
    }, createDecimal(0));
    return resp.toDecimalPlaces(2).toNumber();
  }, [invoice?.transactionItems]);

  const claimInfo = useMemo(() => {
    return (invoice?.claimInfo ?? {}) as ClaimInfo;
  }, [invoice?.claimInfo]);

  const statusDataItem = useMemo(() => {
    if (invoice && invoice.id !== invoiceId) return null;
    return typeof invoice?.statusData === 'object'
      ? invoice?.statusData?.items?.[0]
      : null;
  }, [invoice, invoiceId]);

  const submitInvoice = useCallback(
    async (invoice: IntegrationInvoice, testOnly?: boolean) => {
      /**
       * Known state please...
       */
      testOnly = !!testOnly;
      const isNotTestOnly = !testOnly,
        statusWas = invoice.status;

      setSaved(false);
      let result = null;
      let res = {
        count: 0,
        issues: [] as BusinessRuleItemType[],
      };

      if (
        invoice.status === InvoiceStatusEnum.Submitted &&
        invoice.electronicBilling === false &&
        invoice.generation !== InvoiceGenerationEdiXref.Original
      ) {
        // console.log(invoice, metaContext);
      } else {
        res = businessRulesService.invoiceStatusTransition({
          invoice: { ...invoice, ...metaContext?.[invoice.id] },
          targetStatus: InvoiceStatusEnum.Submitted,
          // trace: true,
        });
      }

      if (res.count) {
        setSaved(true);
        const clone = ChiroUpJSON.clone(invoice);
        if (clone?.statusData?.items?.[0]?.issues) {
          clone.statusData.items[0].issues = res.issues;
        } else {
          clone.statusData = clone.statusData ?? {};
          clone.statusData.items = res.issues;
        }

        updateInvoiceCallback({
          invoice: clone,
          refetch: statusWas && clone.status && statusWas !== clone.status,
        });
        setInvoiceData(clone);
        return;
      }

      if (testOnly) {
        setIsRestActive((p) => ({ ...p, test: true }));
      } else {
        setIsRestActive((p) => ({ ...p, submit: true }));
      }

      try {
        result = (await patientBillingService.get837P({
          clinicId,
          payload: {
            context: {
              invoiceIds: [invoice.id],
              update: isNotTestOnly,
              targetStatus: InvoiceStatusEnum.Submitted,
              meta: { ...metaContext },
            },
          },
          options: patientBillingServiceOptions,
        })) as any;
        /**
         * Look for overall issues.
         */
        let allIssues = [];
        if (result?.meta?.issues?.length) {
          createToast({
            title: `Submit Invoice`,
            description: `${
              result?.meta?.issues?.length
                ? result?.meta?.issues
                    .map((i: BusinessRuleItemType) => i.text)
                    .join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
          allIssues = result?.meta?.issues ?? [];
        }

        /**
         * Invoice-specific issues.
         */
        if (result?.processed?.[invoice.id].issues?.length) {
          allIssues = allIssues.concat(result?.processed?.[invoice.id].issues);
        }

        if (allIssues.length === 0) {
          createToast({
            title: testOnly ? `Test` : `Submit`,
            description: `Invoice successfully processed.`,
            type: ToastTypes.Success,
            duration: 5000,
          });
          if (
            invoice.electronicBilling &&
            !testOnly &&
            !invoice?.integrationActive
          ) {
            trivialDownload({ content: result?.data ?? '' });
          }
        }

        setSaved(testOnly ? false : true);
      } catch (e: any) {
        console.error({ e });
        createToast({
          title: testOnly ? `Test` : `Submit`,
          description: `${e.message ?? e.response?.data?.me}`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }

      if (result?.invoice) {
        updateInvoiceCallback({
          invoice: result?.invoice,
          refetch: statusWas !== result.invoice.status,
        });
        setInvoiceData(result.invoice);
      }

      if (testOnly) {
        setIsRestActive((p) => ({ ...p, test: false }));
      } else {
        setIsRestActive((p) => ({ ...p, submit: false }));
      }
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      metaContext,
      updateInvoiceCallback,
      setInvoiceData,
      clinicId,
      patientBillingServiceOptions,
      createToast,
    ],
  );

  const downloadCMS1500 = useCallback(
    async (invoice: IntegrationInvoice, complete: boolean = true) => {
      if (isRestActive.download) return;
      setIsRestActive((p) => ({ ...p, download: true }));
      try {
        const res = (await patientBillingService.downloadCMS1500({
          clinicId,
          invoiceId: invoice.id,
          complete,
          options: patientBillingServiceOptions,
        })) as any;
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = (reader.result as string)
            ?.replace(/"/g, '')
            ?.replace(/^data:application\/pdf;base64,/, '');

          try {
            const binaryString = atob(base64String);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            trivialDownload({
              content: bytes,
              name: `CMS1500_${invoice.number}.pdf`,
              mimeType: 'application/pdf',
            });
          } catch (error) {
            console.error('Error decoding Base64:', error);
          }
        };
        // reader.readAsDataURL(res);
        reader[
          import.meta.env.VITE_ACTUAL_STAGE === 'local'
            ? 'readAsDataURL'
            : 'readAsText'
        ](res);
      } catch (e: any) {
        createToast({
          title: `Download`,
          description: `${e.message ?? e.response?.data?.message}`,
          type: ToastTypes.Fail,
          duration: 5000,
        });
        console.error({ e });
      }
      setIsRestActive((p) => ({ ...p, download: false }));
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      isRestActive.download,
      clinicId,
      patientBillingServiceOptions,
      createToast,
    ],
  );

  const resolve = useCallback(
    async (invoice: IntegrationInvoice) => {
      if (isRestActive.resolve || saved) return;
      const toastTitle = 'Resolve';
      setIsRestActive((p) => ({ ...p, resolve: true }));
      try {
        const result = await businessRulesService.resolveInvoiceIssues({
          clinicId,
          invoice, // The invoice should JUST have any unresolved issues or no issues.
          promote: true, // Move to unsubmitted if no errors.
          trace,
        });
        if (trace) clog({ resolveInvoiceIssues_result: { result } });

        /**
         * We get back and updated invoice with any potential issues that
         * were found.
         */
        const statusData = result.statusData as InvoiceStatusData,
          items = statusData?.items ?? [],
          latest = items[0] ?? null,
          issues = latest?.issues ?? [];

        if (issues.length) {
          createToast({
            title: toastTitle,
            description: `${
              issues?.length
                ? issues.map((i: BusinessRuleItemType) => i.text).join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
        } else {
          createToast({
            title: toastTitle,
            description: `Invoice successfully processed.`,
            type: ToastTypes.Success,
            duration: 5000,
          });
        }

        updateInvoiceCallback({ invoice: result });
        setInvoiceData(result);
        setSaved(false);
      } catch (e: any) {
        console.error({ e });
        createToast({
          title: `Save`,
          description: `${e.message ?? e.response?.data?.message}`.replace(
            /(^"|"$)/g,
            '',
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
      }
      setIsRestActive((p) => ({ ...p, resolve: false }));
      setIsDirty(true);
    },
    [
      clinicId,
      createToast,
      isRestActive.resolve,
      saved,
      setInvoiceData,
      trace,
      updateInvoiceCallback,
    ],
  );

  const test = useCallback(
    async ({
      invoice,
      targetStatus = InvoiceStatusEnum.Unsubmitted,
    }: {
      invoice: IntegrationInvoice;
      targetStatus?: InvoiceStatusEnum;
    }) => {
      if (isRestActive.test || saved) return;
      setIsRestActive((p) => ({ ...p, test: true }));
      let result: any | null = null;
      const statusWas = invoice.status;

      try {
        result = await patientBillingService.get837P({
          clinicId,
          payload: {
            context: {
              invoiceIds: [invoice.id],
              update: false,
              targetStatus,
              meta: { ...metaContext },
            },
          },
          options: patientBillingServiceOptions,
        });

        /**
         * Look for overall issues.
         */
        // let allIssues = [];
        if (result?.meta?.issues?.length) {
          createToast({
            title: `Submit Invoice`,
            description: `${
              result?.meta?.issues?.length
                ? result?.meta?.issues
                    .map((i: BusinessRuleItemType) => i.text)
                    .join(' ')
                : 'No issues.'
            }`,
            type: ToastTypes.Info,
            duration: 5000,
          });
          // allIssues = result?.meta?.issues ?? [];
        }

        /**
         * Invoice-specific issues.
         */
        // if (result?.processed?.[invoice.id].issues?.length) {
        //   allIssues = allIssues.concat(result?.processed?.[invoice.id].issues);
        // }

        if (trace) clog({ resolveInvoiceIssues_result: { result } });
        if (result?.invoice) {
          updateInvoiceCallback({
            invoice: result?.invoice,
            refetch: statusWas !== result.invoice.status,
          });
          setInvoiceData(result.invoice);
        }
        setSaved(true);
      } catch (e: any) {
        createToast({
          title: `${invoice.status} to ${targetStatus}`,
          description: `${e.message ?? e.response?.data?.message}`.replace(
            /(^"|"$)/g,
            '',
          ),
          type: ToastTypes.Fail,
          duration: 5000,
        });
        console.error({ e });
      }
      setIsRestActive((p) => ({ ...p, test: false }));
      setResolvedSomething(false);
      setIsDirty(false);
    },
    [
      clinicId,
      createToast,
      isRestActive.test,
      metaContext,
      patientBillingServiceOptions,
      saved,
      setInvoiceData,
      trace,
      updateInvoiceCallback,
    ],
  );

  const buttonsByInvoiceStatus = useCallback(
    (invoice: IntegrationInvoice) => {
      if (readonly) return null;
      const status = invoice?.status,
        statusData =
          typeof invoice?.statusData === 'string'
            ? ChiroUpJSON.parse(invoice?.statusData)
            : invoice?.statusData,
        items = statusData?.items ?? [],
        latest = items[0] ?? null,
        issues = latest?.issues ?? [],
        noIssues = latest ? latest?.issues?.length === 0 : true, // Issues must be explicitly set.
        updateStatusButton =
          validTransitions.length > 1 ? ( // Its current status and one other.
            <Button
              className={classNames(shrinkButtons ? 'h-10' : '')}
              text={shrinkButtons ? '' : 'Status'}
              onClick={() => {
                setUpdatingStatus(true);
              }}
              icon={<CogIcon />}
              tooltipIconClassName="text-gray-600 w-3 h-3 ml-2"
              color={ButtonColors.plainWithBorder}
              loading={isRestActive.updateStatus}
              disabled={updatingStatus}
              tooltip="Update the invoice status."
            />
          ) : null;
      switch (status) {
        case InvoiceStatusEnum.Pending:
        case InvoiceStatusEnum.Rejected:
          if (noIssues) {
            return (
              <div className="flex flex-row justify-end space-x-4">
                {updateStatusButton}
                <Button
                  text="Test"
                  onClick={() => {
                    test({ invoice });
                  }}
                  loading={isRestActive.test}
                  disabled={isRestActive.test || saved || isRestActive.submit}
                  tooltip={[
                    'This will run the business rules',
                    'and move the invoice to unsubmitted if there are no issues.',
                  ].join(' ')}
                  color={ButtonColors.primary}
                />
                <Button
                  text="Submit"
                  onClick={() => {
                    submitInvoice(invoice);
                  }}
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  loading={isRestActive.submit}
                  disabled={isRestActive.submit || saved || isRestActive.save}
                  tooltip={[
                    'This will run the business rules and submit the invoice if there',
                    'are no issues.',
                  ].join(' ')}
                  color={ButtonColors.primary}
                />
              </div>
            );
          }
          return (
            <div className="flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text={!hasIssues ? 'Resolve' : 'Save'}
                onClick={() => {
                  resolve(invoice);
                }}
                loading={isRestActive.resolve}
                disabled={
                  !resolvedSomething ||
                  isRestActive.resolve ||
                  saved ||
                  isRestActive.submit
                }
                tooltip={[
                  'This will resolve the issues and try to move the invoice to unsubmitted.',
                ].join(' ')}
                color={ButtonColors.primary}
              />
              <Button
                text="Submit"
                onClick={() => {
                  submitInvoice(invoice);
                }}
                icon={<CloudArrowUpIcon className="h-6 w-6" />}
                loading={isRestActive.submit}
                disabled={
                  issues.length !== 0 ||
                  isRestActive.submit ||
                  saved ||
                  isRestActive.resolve ||
                  isRestActive.test
                }
                tooltip={[
                  'This will run the business rules and submit the invoice if there',
                  'are no issues.',
                ].join(' ')}
                color={ButtonColors.primary}
              />
            </div>
          );
        case InvoiceStatusEnum.Unsubmitted: {
          return (
            <div className="w-full flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text="Test"
                onClick={() => {
                  submitInvoice(invoice, true);
                }}
                disabled={submitDisabled}
                loading={isRestActive.test}
                color={ButtonColors.primary}
              />
              <Button
                text={invoice.electronicBilling ? 'Submit' : 'Generate'}
                icon={<CloudArrowUpIcon className="h-6 w-6" />}
                onClick={() => {
                  submitInvoice(invoice);
                }}
                disabled={submitDisabled}
                loading={isRestActive.submit}
                color={ButtonColors.primary}
              />
            </div>
          );
        }
        case InvoiceStatusEnum.Received:
        case InvoiceStatusEnum.Reprocessed:
        case InvoiceStatusEnum.Partial: {
          return (
            <div className="flex flex-row justify-end space-x-4">
              {updateStatusButton}
              <Button
                text="ERA"
                tooltip="Manual ERA data entry."
                icon={<PencilIcon />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  navigate(`/billing/invoices/${invoice.id}`);
                }}
              />
            </div>
          );
        }
        case InvoiceStatusEnum.Submitted: {
          return (
            <>
              {updateStatusButton}
              <Button
                text="ERA"
                tooltip="Manual ERA data entry."
                icon={<PencilIcon />}
                onClick={(e) => {
                  e.currentTarget.blur();
                  navigate(`/billing/invoices/${invoice.id}`);
                }}
              />
              {invoice?.generation !== InvoiceGenerationEdiXref.Original &&
              invoice?.generation !== InvoiceGenerationEdiXref.Replacement ? (
                <Button
                  text="Submit"
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  onClick={() => {
                    submitInvoice(invoice);
                  }}
                  disabled={submitDisabled}
                  loading={isRestActive.submit}
                  color={ButtonColors.primary}
                />
              ) : invoice?.electronicBilling !== false ? (
                <Button
                  text="Download"
                  icon={<CloudArrowDownIcon className="h-6 w-6" />}
                  onClick={async () => {
                    if (!invoice) return;
                    if (!invoice.exchangeId) {
                      createToast({
                        title: `Download`,
                        description: `No exchangeId found.`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      setSaved(true);
                      return;
                    }
                    setIsRestActive((p) => ({ ...p, download: true }));
                    try {
                      const res = (await integrationService.download({
                        clinicId: invoice.clinicId,
                        exchangeId: invoice.exchangeId,
                      })) as any;
                      if (res && res.body && res.body.length > 0) {
                        trivialDownload({ content: res.body });
                      }
                    } catch (e: any) {
                      setSaved(true); // Yes, I know. We're not saving...so sue me.
                      createToast({
                        title: `Download`,
                        description: `${
                          e.message ?? e.response?.data?.message
                        }`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      console.error({ hereIsTheE: e });
                    }
                    setIsRestActive((p) => ({ ...p, download: false }));
                    // setIsDirty(true);
                  }}
                  disabled={isRestActive.download}
                  loading={isRestActive.download}
                  color={ButtonColors.primary}
                />
              ) : (
                <div
                  className={classNames(
                    shrinkButtons ? 'space-x-2' : 'space-x-3',
                  )}
                >
                  <Button
                    text="Complete"
                    icon={<CloudArrowDownIcon className="h-6 w-6" />}
                    tooltip="Download 1500 form with fields filled in."
                    onClick={async () => {
                      if (!invoice) return;
                      if (!invoice.exchangeId) {
                        createToast({
                          title: `Download`,
                          description: `No exchangeId found.`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        setSaved(true);
                        return;
                      }
                      setIsRestActive((p) => ({
                        ...p,
                        downloadComplete: true,
                      }));
                      try {
                        await downloadCMS1500(invoice);
                        // trivialDownload({ content: res });
                      } catch (e: any) {
                        setSaved(true); // Yes, I know. We're not saving...so sue me.
                        createToast({
                          title: `Download`,
                          description: `${
                            e.message ?? e.response?.data?.message
                          }`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        console.error({ hereIsTheE: e });
                      }
                      setIsRestActive((p) => ({
                        ...p,
                        downloadComplete: false,
                      }));
                      // setIsDirty(true);
                    }}
                    disabled={
                      isRestActive.downloadComplete ||
                      isRestActive.download ||
                      isRestActive.downloadFields
                    }
                    loading={isRestActive.downloadComplete}
                    color={ButtonColors.primary}
                  />
                  <Button
                    text="Fields"
                    icon={<CloudArrowDownIcon className="h-6 w-6" />}
                    tooltip="Download a blank page with the fields in the correct positions."
                    onClick={async () => {
                      if (!invoice) return;
                      if (!invoice.exchangeId) {
                        createToast({
                          title: `Download`,
                          description: `No exchangeId found.`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        setSaved(true);
                        return;
                      }
                      setIsRestActive((p) => ({ ...p, downloadFields: true }));
                      try {
                        await downloadCMS1500(invoice, false);
                        // trivialDownload({ content: res });
                      } catch (e: any) {
                        setSaved(true); // Yes, I know. We're not saving...so sue me.
                        createToast({
                          title: `Download`,
                          description: `${
                            e.message ?? e.response?.data?.message
                          }`,
                          type: ToastTypes.Fail,
                          duration: 5000,
                        });
                        console.error({ hereIsTheE: e });
                      }
                      setIsRestActive((p) => ({ ...p, downloadFields: false }));
                      // setIsDirty(true);
                    }}
                    disabled={
                      isRestActive.downloadComplete ||
                      isRestActive.download ||
                      isRestActive.downloadFields
                    }
                    loading={isRestActive.downloadFields}
                    color={ButtonColors.primary}
                  />
                </div>
              )}
            </>
          );
        }
        case InvoiceStatusEnum.Waiting:
          /**
           * An invoice without a status or a priority has not been looked
           * at by the business rules. Probably an initial invoice. This
           * should only happen when priority is not zero. The user has no
           * operations to perform if it is not the primary.
           */
          if (noStatus || invoice.priority === 0) {
            return (
              <>
                {updateStatusButton}
                <Button
                  text="Test"
                  onClick={() => {
                    test({
                      invoice,
                      targetStatus: InvoiceStatusEnum.Unsubmitted,
                    });
                  }}
                  loading={isRestActive.test}
                  icon={<CloudArrowUpIcon className="h-6 w-6" />}
                  disabled={isRestActive.test || saved || isRestActive.submit}
                  color={ButtonColors.primary}
                  tooltip="This will run the business rules and move the invoice to unsubmitted if there are no issues."
                />
              </>
            );
          }
          return null;
        default:
          if (trace) clog(`No button for status=${status}.`);
          return updateStatusButton;
      }
    },
    [
      hasIssues,
      isRestActive.resolve,
      isRestActive.submit,
      isRestActive.test,
      noStatus,
      readonly,
      submitDisabled,
      validTransitions,
      createToast,
      downloadCMS1500,
      isRestActive.download,
      isRestActive.downloadComplete,
      isRestActive.downloadFields,
      isRestActive.save,
      isRestActive.updateStatus,
      navigate,
      resolve,
      resolvedSomething,
      saved,
      shrinkButtons,
      submitInvoice,
      test,
      trace,
      updatingStatus,
    ],
  );

  const adjustClaim = useCallback(
    async (e: any) => {
      if (isRestActive.any) return;
      if (adjustBilledServices === false) {
        setIsRestActive((p) => ({ ...p, any: true }));
        try {
          const res = await integrationService.invoiceById({
            clinicId,
            id: invoiceId,
            use: 'adjust',
          });
          setAdjustPayload(res);
          setAdjustBilledServices(!adjustBilledServices);
        } catch (e: any) {
          console.error({ e });
        } finally {
          setIsRestActive((p) => ({ ...p, any: false }));
        }
      } else {
        setAdjustBilledServices(!adjustBilledServices);
        setAdjustmentActions([]);
      }
    },
    [adjustBilledServices, clinicId, invoiceId, isRestActive.any],
  );

  const submitAdjustments = useCallback(async () => {
    const res = await adjust({
      clinicId,
      id: invoiceId,
      actions: adjustmentActions,
    });
    if (res && !res?.issues?.length) {
      setAdjustmentActions([]);
      setAdjustBilledServices(false);
      updateInvoiceCallback?.({ invoice: res?.invoice });
    }
  }, [adjust, adjustmentActions, clinicId, invoiceId, updateInvoiceCallback]);

  const actionCallback = useCallback(
    (args: AdjustmentActionType & { singleton?: boolean }) => {
      const { action, type, row, pk, singleton = false } = args,
        parentPk = [action, type, pk].join('.'),
        newActions: AdjustmentActionType[] = ChiroUpJSON.clone(
          adjustmentActions,
        ).filter(
          (o: AdjustmentActionType) =>
            !(o.action === 'rollback' && o.type === 'initial'),
        );
      let currentClickActions = [args];

      if (action === 'trash') {
        const [oldobj] = newActions.splice(Number(pk), 1),
          oldpk = [oldobj.action, oldobj.type, oldobj.pk].join('.');

        currentClickActions = [];

        const rollbackItems = newActions.filter(
            (a: any) => a.action === 'rollback' && a.type === 'payment',
          ),
          rollbackPaymensById = rollbackItems.reduce((acc: any, a: any) => {
            const id = a?.row?.patientTransactionId;
            if (!acc[id]) {
              acc[id] = [];
            }
            acc[id].push(a);
            return acc;
          }, {});

        for (const [id, itemArray] of Object.entries(rollbackPaymensById)) {
          const items = itemArray as unknown as PatientTransactionItemType[],
            sum = items
              .reduce((acc: any, a: any) => {
                const amount = createDecimal(a?.row?.amount ?? 0);
                return acc.plus(amount);
              }, createDecimal(0))
              .toNumber(),
            newkey = ['rollback', 'initial', id].join('.');
          if (newkey === oldpk) continue;
          if (sum > 0) {
            currentClickActions.push({
              action: 'rollback',
              type: 'initial',
              row: {
                items,
                total: sum,
              },
              pk: String(id),
            });
          }
        }
        // [if]   we're removing a child, then we delete the parent.
        // [else] we're removing a parent, then we delete the children.
        const final = singleton
          ? newActions
          : oldobj.parentPk
            ? newActions.filter((a) => {
                return oldobj.parentPk !== [a.action, a.type, a.pk].join('.');
              })
            : newActions.filter((a: AdjustmentActionType) => {
                return a.parentPk !== oldpk;
              });
        setAdjustmentActions(() => {
          return [...final, ...currentClickActions];
        });
        return;
      }
      if (actionsByKey[parentPk]) {
        return;
      }
      if (action === 'remove-item' && type === 'transaction') {
        // Nothing here. We were putting in user messages, no longer.
      } else if (action === 'era-to-cre') {
        // nothing's happening here. we were putting in user messages, but no more.
      } else if (action === 'unpost') {
        const remitAmount = createDecimal(row.remitAmount),
          payment = adjustPayments.find(
            (p: any) => String(p.referenceNumber) === String(row.eraId),
          ),
          paymentLine = payment?.items?.find(
            (pti: PatientTransactionItemType) => {
              const amount = createDecimal(pti.amount ?? 0);
              return remitAmount.equals(amount) && pti?.type === 'Debit';
            },
          );
        if (paymentLine) {
          const key = [
            'rollback',
            'payment',
            payment.referenceNumber,
            paymentLine.id,
          ].join('.');
          if (!actionsByKey[key] && remitAmount.greaterThan(0)) {
            currentClickActions.push({
              action: 'rollback',
              type: 'payment',
              row: { ...paymentLine, eraId: payment.referenceNumber },
              pk: [payment.referenceNumber, paymentLine.id].join('.'),
            });
          }
        }
      } else if (action === 'post') {
        const codesInServices = row?.serviceItems?.reduce(
            (acc: any, itm: any) => {
              acc[itm.adjudicatedServiceCode] = true;
              return acc;
            },
            {},
          ),
          codesInServicesLength = Object.keys(codesInServices ?? {}).length,
          unpostItems =
            actionContext?.unpostByCode[row?.adjudicatedServiceCode];
        if (!unpostItems?.length) {
          // Find the PLI that has the same service

          if (codesInServicesLength) {
            const found: STRING_BOOLEAN_HASH = {};
            Object.keys(actionContext?.unpostByCode ?? {}).forEach((code) => {
              if (codesInServices[code]) {
                found[code] = true;
              }
            });
          }
        } else if (codesInServicesLength) {
          const found: STRING_BOOLEAN_HASH = {};
          Object.keys(actionContext?.unpostByCode ?? {}).forEach((code) => {
            if (codesInServices[code]) {
              found[code] = true;
            }
          });
        }

        currentClickActions.unshift({
          ...args,
          action: 'apply',
          type: 'pli',
          parentPk,
        });
      }
      // Put in an action for rolling back the initial payment
      // for the amount of any items.
      const rollbackItems = newActions.filter(
          (a) => a.action === 'rollback' && a.type === 'payment',
        ),
        rollbackPaymensById = rollbackItems.reduce((acc: any, a: any) => {
          const id = a?.row?.patientTransactionId;
          if (!acc[id]) {
            acc[id] = [];
          }
          acc[id].push(a);
          return acc;
        }, {});

      for (const [id, itemArray] of Object.entries(rollbackPaymensById)) {
        const items = itemArray as unknown as PatientTransactionItemType[],
          sum = items
            .reduce((acc: any, a: any) => {
              const amount = createDecimal(a?.row?.amount ?? 0);
              return acc.plus(amount);
            }, createDecimal(0))
            .toNumber();
        if (sum > 0) {
          currentClickActions.push({
            action: 'rollback',
            type: 'initial',
            row: {
              items,
              total: sum,
            },
            pk: String(id),
          });
        }
      }

      setAdjustmentActions(() => [...newActions, ...currentClickActions]);
    },
    [
      actionContext?.unpostByCode,
      actionsByKey,
      adjustPayments,
      adjustmentActions,
    ],
  );

  const showStaticBilledServices = useMemo(() => {
    console.log({ invoice });
    return (
      invoice?.payorLineItems &&
      invoice?.payorLineItems?.length !== 0 &&
      invoice.id === invoiceId
    );
  }, [invoice.id, invoice?.payorLineItems, invoiceId]);

  if (state === OpenClosedStates.Closed) return null;
  if (!invoice) {
    if (trace) clog('The invoice is empty.');
    // return null;
  }

  const moreDetailClassName = 'text-xs text-gray-600';

  if (view !== 'legacy' && isFetching && !restFailed) {
    return (
      <Modal isOpen={true} close={close} isFullScreen={true}>
        <div className="flex flex-row justify-center space-x-4 h-9">
          <Loading flag={true} style={`standard-gray`} />
        </div>
      </Modal>
    );
  }
  if (view !== 'legacy') {
    return (
      <Modal isOpen={true} close={close} isFullScreen={true}>
        <div className="flex flex-row space-x-4 h-9 grow">
          <div className="grow flex flex-row space-x-4 pt-2 whitespace-nowrap">
            {invoice?.id === invoiceId ? (
              <>
                <div className="font-semibold text-lg whitespace-nowrap overflow-hidden">
                  {invoice?.uiLegalName ?? <cite>- Payor not Available -</cite>}
                </div>
                <Loading
                  flag={toggleRestActive || (isFetching && !restFailed)}
                  style={`tiny-inline-gray`}
                />
              </>
            ) : (
              <div className="italic text-sm">
                {restFailed ? 'Error' : 'Loading...'}
              </div>
            )}

            <div className="flex flex-row text-sm">
              <div className="h-6 flex items-center justify-center text-center px-3 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                {invoice?.status}
              </div>
              <TrivialTooltip text={InvoiceStatusTips[invoice.status]} />
            </div>
          </div>

          {invoice.id === invoiceId ? (
            <>
              {updatingStatus && (
                <Modal isOpen={true} close={close}>
                  <div className="w-full flex flex-row space-x-4 align-bottom">
                    <Select
                      label="New Status"
                      name="newStatus"
                      value={newStatus}
                      options={validTransitions}
                      onChange={(val: any) => {
                        if (!val) return;
                        setNewStatus(val);
                      }}
                      limit={1}
                      className={`w-full`}
                    />
                    <IconButton
                      className="mt-3 h-5 w-5 hover:text-primary-500 text-gray-600 pt-0.5"
                      icon={<XCircleIcon className="h-6 w-6" />}
                      tooltip="Cancel"
                      onClick={() => {
                        setUpdatingStatus(false);
                      }}
                      disabled={isRestActive.updateStatus}
                    />
                    <IconButton
                      onClick={async () => {
                        if (!newStatus) return;
                        setSaved(false);
                        setUpdatingStatus(false);
                        setIsRestActive((p) => ({
                          ...p,
                          updateStatus: true,
                        }));
                        const clone = ChiroUpJSON.clone(invoice);
                        clone.status = newStatus;
                        const title = `Update Status`;

                        try {
                          const results =
                              await patientBillingService.setInvoiceStatus({
                                clinicId,
                                invoiceId: invoice.id,
                                status: newStatus,
                                persistIssues: true,
                                options: patientBillingServiceOptions,
                              }),
                            result =
                              results &&
                              results?.invoices &&
                              results?.invoices?.[0],
                            saveIssues = results?.issues ?? [],
                            saveNotes = results?.notes ?? [];

                          setSaved(true);
                          if (saveIssues && saveIssues.length) {
                            createToast({
                              title,
                              description: `${
                                saveIssues?.length
                                  ? saveIssues
                                      .map((i: BusinessRuleItemType) => i.text)
                                      .join(' ')
                                  : 'No issues.'
                              }`,
                              type: ToastTypes.Info,
                              duration: 5000,
                            });
                          } else {
                            setInvoiceData(result);
                            updateInvoiceCallback({
                              invoice: result,
                              refetch: true,
                            });
                          }
                          if (saveNotes && saveNotes.length) {
                            createToast({
                              title,
                              description: `${
                                saveIssues?.length
                                  ? saveIssues
                                      .map((i: BusinessRuleItemType) => i.text)
                                      .join(' ')
                                  : 'No notes.'
                              }`,
                              type: ToastTypes.Info,
                              duration: 5000,
                            });
                          }
                        } catch (e: any) {
                          createToast({
                            title,
                            description: `${
                              e.message ?? e.response?.data?.message
                            }`,
                            type: ToastTypes.Fail,
                            duration: 5000,
                          });
                          console.error({ e });
                        }
                        setSaved(false);
                        setIsRestActive((p) => ({
                          ...p,
                          updateStatus: false,
                        }));
                      }}
                      disabled={
                        isRestActive.updateStatus ||
                        newStatus === null ||
                        newStatus === invoice.status
                      }
                      loading={isRestActive.updateStatus}
                      className="mt-3 h-5 w-5 hover:text-primary-500 text-gray-600"
                      icon={icons.saveDisk}
                      tooltip="Save"
                    />
                  </div>
                </Modal>
              )}
              <div className="flex items-center">
                <IconButton
                  className={`w-6 h-6 ${noteColor}`}
                  icon={<ClipboardDocumentListIcon />}
                  tooltip="Notes"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenInvoiceNotes(invoiceId);
                  }}
                />
                {showInvoiceNotesModal && (
                  <InvoiceNotesModal
                    invoice={invoice}
                    state={
                      showInvoiceNotesModal
                        ? OpenClosedStates.Open
                        : OpenClosedStates.Closed
                    }
                    onClose={() => {
                      setShowInvoiceNotesModal(false);
                    }}
                  />
                )}
              </div>
              {buttonsByInvoiceStatus(invoice)}
            </>
          ) : null}
          <div className="">
            <IconButton
              className="mt-0.5 h-7 w-7 text-gray-400 dark:text-darkGray-400 hover:text-gray-500 dark:hover:text-darkGray-300"
              icon={<XMarkIcon />}
              tooltip="Close"
              onClick={() => {
                componentClose(isDirty);
                if (isDirty) {
                  updateInvoiceCallback({ invoice: null, refetch: isDirty });
                }
                setTimeout(() => {
                  setSaved(false);
                  setResolvedSomething(false);
                  setRestFailed(false);
                }, 750);
              }}
              disabled={isFetching}
            />
          </div>
        </div>

        <>
          <section className="flex flex-col space-y-6 mt-2">
            {issues?.length ? (
              <fieldset className="border border-gray-400 rounded-md pb-3">
                <legend className="font-semibold px-2 mx-6">Issues</legend>
                <div>
                  {issues.length
                    ? issues.map((item, i) => {
                        return (
                          <div
                            key={`brs-issues-${i}`}
                            className={classNames(
                              'px-4 pb-1 flex flex-row space-x-2',
                              i === 0 ? 'pt-1' : '',
                            )}
                          >
                            <div>&bull;</div>
                            <BusinessRuleSolution
                              result={item}
                              workTextErrors={true}
                              resolveId={i}
                              resolveCallback={resolveCallback}
                              fixAppliedCallback={fixAppliedCallback}
                              trace={trace}
                              isRestActive={anyRestActive}
                              data={{
                                billingKey: invoice.billingKey,
                                clinicId,
                              }}
                            />
                          </div>
                        );
                      })
                    : null}
                </div>
              </fieldset>
            ) : null}
            {notes?.length ? (
              <fieldset
                className={classNames('border border-gray-600 pb-4 pt-1')}
              >
                <legend className="font-semibold px-2 mx-6">Notes</legend>
                <div>
                  {notes.map((item, i) => {
                    return (
                      <div
                        key={`brs-notes-${i}`}
                        className={classNames(
                          'px-4 pb-1 flex flex-row space-x-2',
                          i === 0 ? 'pt-1' : '',
                        )}
                      >
                        {' '}
                        <div>&bull;</div>
                        <BusinessRuleSolution
                          result={item}
                          workTextErrors={true}
                          resolveId={i}
                          trace={trace}
                          isaNote={true}
                          fixAppliedCallback={fixAppliedCallback}
                        />
                      </div>
                    );
                  })}
                </div>
              </fieldset>
            ) : null}
            <div
              className={classNames(
                'flex flex-row justify-between px-4',
                !notes?.length && !issues?.length ? 'mt-4' : '',
              )}
            >
              {Object.keys(metaComponents).map((key) => (
                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>{key}</div>
                  <div className={ClassNames.kv.v}>{metaComponents[key]}</div>
                </div>
              ))}
            </div>
          </section>
          <section
            className={classNames(
              'mt-4 mb-4 py-3 dark:text-darkGray-50 tracking-wider whitespace-nowrap bg-gray-100 text-gray-900 border border-gray-300 rounded-md uppercase flex-row justify-between',
              ClassNames.sectionClassName,
            )}
          >
            <div className="flex flex-row space-x-2">
              <div>Date of Service </div>
              <div
                className={ClassNames.iblock.link}
                title="Branches to the purchase in a new window."
              >
                <Link
                  to={`/consolidated/transaction/${invoice?.billingKey}`}
                  target="_blank"
                >
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                </Link>
              </div>
            </div>
            <div>
              {ChiroUpDayJsCommon.getMmDdYyyy(invoice?.transactionDate)}
            </div>
          </section>
          <div className="flex flex-row space-x-4 px-4">
            <div className={ClassNames.iblock.container}>
              <div className={ClassNames.iblock.heading}>
                <div className={ClassNames.iblock.title}>Insurance</div>
                <div
                  className={ClassNames.iblock.link}
                  title="Branches to the patient's insurance in another tab."
                >
                  <Link
                    to={`/patients/${invoice?.patientId}/insurance`}
                    target="_blank"
                  >
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                  </Link>
                </div>
              </div>
              <div className={ClassNames.iblock.content}>
                <div className="flex flex-row space-x-2">
                  <div className={ClassNames.iblock.singleton}>
                    {invoice.legalName ?? <cite>- Payor not Available -</cite>}
                  </div>
                  <div className="mt-1 h-4 flex items-center justify-center text-center px-3 font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                    {PayorPriorityDisplay[invoice.priority ?? 0]}
                  </div>
                </div>
                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>Payor ID</div>
                  <div className={ClassNames.kv.v}>{invoice?.asciiPayorId}</div>
                </div>

                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>Primary Insured</div>
                  <div className={ClassNames.kv.v}>
                    {invoice?.primaryInsured?.type ===
                    PrimaryInsuredTypes.Patient
                      ? 'Patient'
                      : primaryInsuredNameDisplay(invoice?.primaryInsured)}
                  </div>
                </div>
                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>ID</div>
                  <div className={ClassNames.kv.v}>
                    {invoice?.primaryInsured?.memberId ?? <cite>-na-</cite>}
                  </div>
                </div>
              </div>
            </div>
            <div className={ClassNames.iblock.container}>
              <div className={ClassNames.iblock.heading}>
                <div className={ClassNames.iblock.title}>Patient</div>
                <div
                  className={ClassNames.iblock.link}
                  title="Branches to the patient in a new tab."
                >
                  <Link to={`/patients/${invoice?.patientId}`} target="_blank">
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                  </Link>
                </div>
              </div>
              <div className={ClassNames.iblock.content}>
                <div className={ClassNames.iblock.singleton}>
                  {invoice?.patient?.displayName ?? <cite>-na-</cite>}
                </div>
                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>DOB</div>
                  <div className={ClassNames.kv.v}>
                    {invoice?.patient?.dateOfBirth
                      ? ChiroUpDayJsCommon.display.date(
                          invoice.patient.dateOfBirth,
                        ) +
                        ' age ' +
                        calculatePatientAge({
                          dob: invoice.patient.dateOfBirth,
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className={ClassNames.iblock.container}>
              <div className={ClassNames.iblock.heading}>
                <div className={ClassNames.iblock.title}>Rendering</div>
                <div
                  className={ClassNames.iblock.link}
                  title="Branches to the clinic's user settings in a new window."
                >
                  <Link to={`/settings/clinic/users`} target="_blank">
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                  </Link>
                </div>
              </div>
              <div className={ClassNames.iblock.content}>
                <div className={ClassNames.iblock.singleton}>
                  {invoice?.provider?.name}
                </div>
              </div>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>NPI</div>
                <div className={ClassNames.kv.v}>
                  {invoice?.provider?.npi ?? <cite>-na-</cite>}
                </div>
              </div>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Taxonomy</div>
                <div className={ClassNames.kv.v}>
                  {invoice?.provider?.taxonomyCode ?? <cite>-na-</cite>}
                </div>
              </div>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Place of Service</div>
                <div className={ClassNames.kv.v}>
                  {invoice?.pos ?? <cite>-na-</cite>}
                </div>
              </div>
            </div>
            <div className={ClassNames.iblock.container}>
              <div className={ClassNames.iblock.heading}>
                <div className={ClassNames.iblock.title}>Billing</div>
                <div
                  className={ClassNames.iblock.link}
                  title="Branches to the clinic's billing settings in a new window."
                >
                  <Link to={`/settings/clinic/billing`} target="_blank">
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                  </Link>
                </div>
              </div>
              <div className={ClassNames.iblock.content}>
                <div className={ClassNames.iblock.singleton}>
                  {invoice?.billingProfile?.name}
                </div>
                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>NPI</div>
                  <div className={ClassNames.kv.v}>
                    {invoice?.billingProfile?.npi ?? <cite>-na-</cite>}
                  </div>
                </div>
                <div className={ClassNames.kv.block}>
                  <div className={ClassNames.kv.k}>Tax ID</div>
                  <div className={ClassNames.kv.v}>
                    {invoice?.billingProfile?.taxId ?? <cite>-na-</cite>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section>
            {showStaticBilledServices ? (
              <div className="mt-4">
                <HashCardDisplay
                  // header={<div className="bg-transparent"></div>}
                  square={true}
                  border={''}
                  hashCardDisplayColors={['bg-transparent', 'bg-gray-300']}
                  headerComponentRight={
                    adjustBilledServices ? null : isaInvoiceAdjustableStatus(
                        invoice,
                      ) ? (
                      <div className="w-full flex justify-end">
                        <div
                          className="text-gray-500 hover:text-primary-600 cursor-pointer"
                          onClick={(e: any) => {
                            e?.stopPropagation();
                            e?.preventDefault();
                            adjustClaim(e);
                          }}
                        >
                          <CogIcon className="w-7 h-7" />
                        </div>
                      </div>
                    ) : null
                  }
                  components={{
                    ...invoice?.payorLineItems?.reduce(
                      (acc, pli, idx) => {
                        const mods = [];
                        let mod =
                          pli.submittedModifier1 || pli.adjudicatedModifier1;
                        if (mod) mods.push(mod);
                        mod =
                          pli.submittedModifier2 || pli.adjudicatedModifier2;
                        if (mod) mods.push(mod);
                        mod =
                          pli.submittedModifier3 || pli.adjudicatedModifier3;
                        if (mod) mods.push(mod);
                        mod =
                          pli.submittedModifier4 || pli.adjudicatedModifier4;
                        if (mod) mods.push(mod);
                        const modifiers = mods.join(', ');

                        acc[`${pli.payorId}.${pli.controlNumber}`] = (
                          <div
                            className={classNames(
                              ['bg-gray-50', 'bg-gray-200'][idx % 2],
                              'grid grid-cols-6 text-base',
                            )}
                          >
                            <div className="px-4 p-2 flex flex-row space-x-2">
                              <div className="font-extrabold">
                                {pli.adjudicatedProcedureCode ||
                                  pli.submittedProcedureCode}
                              </div>
                              <TrivialTooltip
                                type="info"
                                text={pli.controlNumber}
                                tipClassName="w-64"
                              />
                            </div>
                            <div className="py-2 pl-2 col-span-2">
                              {
                                originalDiagnosesByCode[
                                  pli?.submittedProcedureCode ?? '_'
                                ]
                              }
                            </div>
                            <div className="py-2">{modifiers}</div>
                            <div className="px-4 p-2 flex justify-end">
                              {pli.submittedUnits}
                            </div>
                            <div className="px-4 p-2 flex justify-end">
                              <Currency value={pli.billedAmount} />
                            </div>

                            {pli.remitDate && (
                              <div className="ml-4 col-span-6 mb-2 px-4 border-dotted border-t-2 border-gray-400 w-full">
                                <div className="flex flex-row space-x-2 mt-2 h-6">
                                  <div className="after:content-[':']">
                                    Payment
                                  </div>
                                  <div className="flex font-bold">
                                    <Currency value={pli.remitAmount} />
                                  </div>
                                  <div className="flex whitespace-nowrap">
                                    {createUTCDayjs(pli.remitDate).format(
                                      ChiroUpDayJsCommon.format.date,
                                    )}
                                  </div>
                                </div>
                                {pli?.adjustments?.length ? (
                                  <div className="after:content-[':']">
                                    Adjustments
                                  </div>
                                ) : null}
                                <div className="ml-4">
                                  {pli.adjustments?.map(
                                    (adj: any, index: number) => (
                                      <div
                                        key={`pli-${pli.id}-adj-${adj.groupCode}-${index}`}
                                      >
                                        <div
                                          key={adj.groupCode}
                                          className="flex flex-row"
                                        >
                                          <div className="flex flex-col italic">
                                            <div>
                                              &bull;&nbsp;{adj.groupDescription}
                                            </div>
                                          </div>
                                        </div>

                                        {adj.items.map(
                                          (item: any, itemIndex: number) => (
                                            <div key={`adj-${itemIndex}`}>
                                              <div
                                                key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}`}
                                                className="ml-4 flex flex-row space-x-2 w-full mb-2"
                                              >
                                                <div className="flex">
                                                  &#8226;&nbsp;
                                                  <TrimAndTooltip
                                                    text={item.description}
                                                  />
                                                </div>
                                                <div className="font-bold flex">
                                                  {formatCurrency(item.amount)}
                                                </div>
                                              </div>

                                              <div className="w-full text-xs">
                                                {item.remarks &&
                                                  item.remarks.length > 0 && (
                                                    <div className="flex flex-row">
                                                      <div className="flex flex-col">
                                                        <div className="text-gray-500">
                                                          {item.remarks.map(
                                                            (
                                                              remark: any,
                                                              remarkIndex: number,
                                                            ) => (
                                                              <div
                                                                key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}-remark-${remark.code}-${remarkIndex}`}
                                                                className="flex flex-row mt-2 text-sm"
                                                              >
                                                                <div className="flex flex-col">
                                                                  <div className="text-gray-500">
                                                                    {
                                                                      remark.description
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ),
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </div>
                                          ),
                                        )}
                                      </div>
                                    ),
                                  )}
                                </div>
                                {!pli.adjustments ||
                                  (pli.adjustments?.length === 0 && (
                                    <div className="flex w-full">
                                      <div className="flex justify-between text-gray-500 w-full">
                                        <div>No adjustments were made.</div>
                                      </div>
                                    </div>
                                  ))}
                                {(pli?.remarks?.length ?? 0) !== 0 && (
                                  <>
                                    <div className="mt-6">
                                      Additional Remarks:
                                    </div>
                                    <ol className="list-decimal list-outside pl-4 mb-4">
                                      {pli.remarks?.map(
                                        (remark: any, remarkIndex: number) => (
                                          <li
                                            key={`pli-${pli.id}-remark-${remark.code}-${remarkIndex}`}
                                            className="text-gray-500 ml-4"
                                          >
                                            {remark.description}
                                          </li>
                                        ),
                                      )}
                                    </ol>
                                  </>
                                )}

                                {(pli?.internalNotes?.length ?? 0) > 0 && (
                                  <div className="mt-6">Internal Notes</div>
                                )}
                                {pli.internalNotes?.map(
                                  (note: any, noteIndex: number) => (
                                    <div
                                      key={`pli-${pli.id}-remark-${note.createdAt}-${noteIndex}`}
                                      className="flex flex-row mt-2 text-sm"
                                    >
                                      <div className="flex flex-col">
                                        <div className="text-gray-500">
                                          {note.message}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            )}
                          </div>
                        );
                        return acc;
                      },
                      {
                        header: (
                          <div
                            className={classNames(
                              ClassNames.sectionClassName,
                              'grid grid-cols-6 bg-gray-100 text-gray-900 border border-gray-300 rounded-md uppercase',
                            )}
                          >
                            <div className="py-2">Code</div>
                            <div className="py-2 col-span-2">Diagnoses</div>
                            <div className="py-2">Modifiers</div>
                            <div className="py-2 flex justify-end">Units</div>
                            <div className="py-2 flex justify-end">Billed</div>
                          </div>
                        ),
                      } as STRING_ANY_HASH,
                    ),
                    Balance: (
                      <section className="bg-gray-100 text-gray-900 border border-gray-300 rounded-md uppercase h-10 py-2">
                        <div className={ClassNames.kv.blockRow}>
                          <div
                            className={classNames(
                              ClassNames.kv.block,
                              'text-primary-500',
                            )}
                          >
                            <div className={`font-semibold`}>Total paid</div>
                            <div className={`font-semibold`}>
                              <Currency value={remitAmountsTotal} />
                            </div>
                          </div>
                          <div className={ClassNames.kv.block}>
                            <div className={`font-semibold`}>Total billed</div>
                            <div className={`font-semibold`}>
                              <Currency value={invoice?.billedAmount} />
                            </div>
                          </div>
                        </div>
                      </section>
                    ),
                  }}
                  containerClassName="w-full"
                  noHover={true}
                  skipNullValues={true}
                  addClassName={''}
                />
              </div>
            ) : (
              <>
                <section>
                  <DatabaseTable
                    rows={invoice?.transactionItems ?? []}
                    instanceTitle="Invoice Transaction Items"
                    clickable={false}
                    parentClassName="mt-4"
                    options={{
                      sectionClassName: '',
                      outerClassName: 'overflow-x-auto relative',
                      evenClassName: 'bg-transparent',
                      oddClassName: 'bg-gray-200',
                      defaultThClassName:
                        'px-4 py-3 text-left text-base font-medium text-gray-500 dark:text-darkGray-50 tracking-wider whitespace-nowrap',
                      defaultTdClassName: 'px-4 py-2 text-left',
                      noRowsFoundComponent: (
                        <div className="m-4 mb-6 p-4 border rounded-md bg-white flex justify-center">
                          No billable services found for this invoice.
                        </div>
                      ),
                      roundedHeader: true,
                      thBorder: 'border-gray-300',
                    }}
                    cellClassName={`text-base font-medium text-gray-900`}
                    schema={{
                      code: {
                        label: 'Code',
                        thClassName: ClassNames.thClassName,
                        td: (row: any) => {
                          return (
                            <div className="font-extrabold">{row?.code}</div>
                          );
                        },
                      },
                      diagnoses: {
                        label: 'Diagnoses',
                        thClassName: ClassNames.thClassName,
                        td: (row: uiPayorInvoiceLineItem) => {
                          return (row?.diags ?? []).join(', ');
                        },
                      },
                      modifiers: {
                        label: 'Modifiers',
                        thClassName: ClassNames.thClassName,
                        td: (row: uiPayorInvoiceLineItem) => {
                          const mods: string[] = [];
                          if (row?.modifier1) mods.push(row.modifier1);
                          if (row?.modifier2) mods.push(row.modifier2);
                          if (row?.modifier3) mods.push(row.modifier3);
                          if (row?.modifier4) mods.push(row.modifier4);
                          return mods.join(', ');
                        },
                      },
                      amount: {
                        label: 'Amount',
                        thClassName: ClassNames.thClassName + ' text-right',
                        tdClassName: 'text-right',
                        td: (row: uiPayorInvoiceLineItem) => {
                          return row?.amount ?? 1;
                        },
                      },
                      units: {
                        label: 'Units',
                        tdClassName: 'text-right',
                        thClassName: ClassNames.thClassName + ' text-right',
                        td: (row: uiPayorInvoiceLineItem) => {
                          return row?.units ?? 1;
                        },
                      },
                      total: {
                        label: 'Billable',
                        tdClassName: 'text-right',
                        thClassName: ClassNames.thClassName + ' text-right',
                        td: (row: uiPayorInvoiceLineItem) => {
                          const amount = createDecimal(row?.amount ?? 0).times(
                            row?.units ?? 1,
                          );
                          return amount.toNumber().toFixed(2);
                        },
                      },
                    }}
                    count={invoice?.transactionItems?.length ?? 0}
                  />
                </section>
                <section
                  className={classNames(
                    'h-12 -mt-1 py-4 dark:text-darkGray-50 tracking-wider whitespace-nowrap bg-gray-100 text-gray-900 border border-gray-300 rounded-md uppercase flex flex-row justify-between',
                    ClassNames.sectionClassName,
                  )}
                >
                  <div
                    className="flex flex-row space-x-4"
                    title={`${
                      invoice.electronicBilling
                        ? 'Electronic-billing automation.'
                        : 'Paper-billing automation.'
                    }`}
                  >
                    <div>Electronic Billing</div>
                    <Toggle
                      value={!!invoice.electronicBilling}
                      disabled={disableToggle || anyRestActive}
                      onChange={async (_: any) => {
                        await toggleElectronicBilling();
                        setIsDirty(true);
                      }}
                    />
                    {invoice.electronicBilling ? null : (
                      <div className="w-full flex justify-end relative -mt-1">
                        <div className="px-4 pt-1 rounded-lg bg-yellow-300 text-[10px] leading-[10px] font-light text-gray-600 ml-2 whitespace-nowrap h-5">
                          Paper Billing
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-row space-x-4">
                    <div className="after:content-[':']">Total Billable</div>
                    <div>
                      <Currency value={transactionItemsTotal} />
                    </div>
                  </div>
                </section>
              </>
            )}
          </section>

          <section
            className={classNames(
              'cursor-pointer h-12 mt-6 py-3 dark:text-darkGray-50 tracking-wider whitespace-nowrap bg-gray-100 text-gray-900 border border-gray-300 rounded-md uppercase text-right flex flex-row justify-between',
              ClassNames.sectionClassName,
            )}
            onClick={(e: any) => {
              e?.stopPropagation?.();
              e?.preventDefault?.();
              setExpandedClaimInfo(!expandedClaimInfo);
            }}
          >
            <div className="flex flex-row space-x-2">
              <div>Additional Claim Info</div>
              <div
                className={classNames(ClassNames.iblock.link)}
                title="Branches to the encounter in a new window."
                onClick={(e: any) => {
                  e?.stopPropagation?.();
                }}
              >
                <Link
                  to={`/patients/${invoice?.patientId}/encounters/${invoice?.billingKey}`}
                  target="_blank"
                >
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                </Link>
              </div>
            </div>
            <div className="-mt-1 w-8 h-8">
              {expandedClaimInfo ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </section>
          {expandedClaimInfo ? (
            <section className="mt-4">
              <div className="sm:grid sm:grid-cols-2 odd:bg-transparent even:bg-gray-300 sm:gap-x-2 sm:gap-y-0 gap-y-2">
                <ClaimInfoForm
                  value={{ encounterInfo: { claimInfo } }}
                  disabled={true}
                  grid={{
                    pair: 'px-2 text-base w-full flex flex-row space-x-2',
                    label: `flex after:content-[':']`,
                    value: `flex font-semibold grow`,
                  }}
                  dontShowFalse
                />
              </div>
            </section>
          ) : null}
          <section>
            <div
              className={classNames(
                'mb-3 mt-6 py-3 dark:text-darkGray-50 tracking-wider whitespace-nowrap bg-gray-100 text-gray-900 border border-gray-300 rounded-md uppercase text-sm',
                ClassNames.sectionClassName,
              )}
            >
              <div>Invoice Tracking Details</div>
            </div>
            <div className={ClassNames.kv.blockRow}>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Invoice number</div>
                <div className={ClassNames.kv.v}>{invoice?.number}</div>
              </div>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Control number</div>
                <div className={ClassNames.kv.v}>{invoice?.controlNumber}</div>
              </div>
            </div>
            <div className={ClassNames.kv.blockRow}>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Last updated</div>
                <div className={ClassNames.kv.v}>
                  <TimestampDisplay
                    ts={
                      statusDataItem?.ts ??
                      invoice?.touched ??
                      invoice?.createdAt
                    }
                    itemTz={invoice?.tz}
                    userTz={userTz}
                  />
                </div>
              </div>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Updated by</div>
                <div
                  className={classNames(
                    ClassNames.kv.v,
                    'whitespace-nowrap overflow-hidden',
                  )}
                >
                  {lastStatusUpdate?.userName ?? '- n.a. -'}
                </div>
              </div>
            </div>
            <div className={ClassNames.kv.blockRow}>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Previous status</div>
                <div className={ClassNames.kv.v}>
                  {lastStatusUpdate?.previous ?? '- n.a. -'}
                </div>
              </div>
              <div className={ClassNames.kv.block}>
                <div className={ClassNames.kv.k}>Final status</div>
                <div className={ClassNames.kv.v}>
                  {lastStatusUpdate?.final ?? '- n.a. -'}
                </div>
              </div>
            </div>
            {invoice?.interchangeControlNumber || eraIds.length > 1 ? (
              <div className={ClassNames.kv.blockRow}>
                {invoice.interchangeControlNumber ? (
                  <div className={ClassNames.kv.block}>
                    <div className={ClassNames.kv.k}>EDI batch</div>
                    <div
                      className={ClassNames.kv.v}
                      title="Opens the exchange where this claim was submitted in a new tab."
                    >
                      <Link
                        to={`/billing/exchanges?id=${invoice.exchangeId}`}
                        target="_blank"
                        className="flex flex-row space-x-0.5"
                      >
                        <div>{invoice.interchangeControlNumber}</div>
                        <div className="cursor-pointer pt-0.5">
                          <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {eraIds.length ? (
                  <div className={ClassNames.kv.block}>
                    <div className={ClassNames.kv.k}>ERA</div>
                    {eraIds.map((id) => {
                      return (
                        <div
                          className={ClassNames.kv.v}
                          title="Opens the ERA for this claim in a new tab."
                        >
                          <Link
                            to={`/billing/eras/${id}`}
                            target="_blank"
                            className="flex flex-row space-x-0.5"
                          >
                            <div>{id}</div>
                            <div className="cursor-pointer pt-0.5">
                              <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            ) : null}
          </section>
        </>
        <section>
          {adjustBilledServices ? (
            <HashCardDisplay
              header={<div>Adjust Billed Services</div>}
              headerClassName="overflow-hidden border-b-2 border-transparent flex flex-row bg-gray-100 text-gray-900 border border-gray-300 rounded-md px-4 py-2 font-semibold uppercase"
              headerComponentRight={
                <div
                  className="text-gray-900 hover:font-bold cursor-pointer"
                  onClick={(e: any) => {
                    e?.stopPropagation();
                    e?.preventDefault();
                    adjustClaim(e);
                  }}
                >
                  <XMarkIcon className="w-5 h-5" />
                </div>
              }
              square={true}
              containerClassName="w-full mt-8"
              noHover={true}
              skipNullValues={true}
              addClassName={'shadow-lg'}
              components={{
                debug: trace ? (
                  <pre>
                    {ChiroUpJSON.pretty({
                      actionsByKey,
                      // postByCode: actionContext?.postByCode,
                      unpostByCode: actionContext?.unpostByCode,
                      // adjustmentActions,
                    })}
                  </pre>
                ) : null,
                h2: (
                  <div className={ClassNames.adjustmentSeps}>
                    Current Services
                  </div>
                ),
                current: (
                  <CurrentCard
                    rows={invoice.payorLineItems ?? []}
                    callback={actionCallback}
                    actionsByKey={actionsByKey}
                    invoice={invoice}
                    context={actionContext}
                  />
                ),
                items: (
                  <TransactionItemsCard
                    context={actionContext}
                    actionsByKey={actionsByKey}
                    callback={actionCallback}
                  />
                ),
                h2_1: (
                  <div
                    className={classNames(
                      ClassNames.adjustmentSeps,
                      'border-t border-b border-gray-400',
                    )}
                  >
                    Insurance
                  </div>
                ),
                insurance: (
                  <div className="px-4 mt-4">
                    <table className="w-full mb-4">
                      <thead>
                        <tr className="text-sx text-gray-400">
                          <th className={ClassNames.table.iFirstTh}>Name</th>
                          <th className={ClassNames.table.th}>Code</th>
                          <th className={ClassNames.table.th}>Invoice</th>
                          <th className={ClassNames.table.th}>ERA</th>
                          <th className={ClassNames.table.th}>Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(actionContext?.insuranceById ?? {}).map(
                          (item: any, idx: number) => {
                            return (
                              <tr key={`insurance-${idx}`}>
                                <td
                                  className={classNames(
                                    ClassNames.table.firstCell,
                                  )}
                                >
                                  {item.name}
                                </td>
                                <td className={ClassNames.table.cell}>
                                  {item?.code}
                                </td>
                                <td
                                  className={classNames(
                                    'w-16',
                                    ClassNames.table.cell,
                                  )}
                                >
                                  <DisplayBoolean value={item?.invoice} />
                                </td>
                                <td
                                  className={classNames(
                                    'w-16',
                                    ClassNames.table.cell,
                                  )}
                                >
                                  <DisplayBoolean value={item?.cre} />
                                </td>
                                <td
                                  className={classNames(
                                    'w-16',
                                    ClassNames.table.cell,
                                  )}
                                >
                                  <DisplayBoolean value={item?.payment} />
                                </td>
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </table>
                  </div>
                ),
                h2_2: (
                  <div
                    className={classNames(
                      ClassNames.adjustmentSeps,
                      'border-t border-gray-400',
                    )}
                  >
                    ERA Claim Response{adjustResponses?.length === 1 ? '' : 's'}{' '}
                    <span className={ClassNames.subHeadingNote}>
                      (Most-recent First)
                    </span>
                  </div>
                ),
                responses: adjustResponses.map((resp: any, i: number) => {
                  return (
                    <ClaimResponseEraCard
                      key={`resp-${i}`}
                      row={resp}
                      // payorLineItems={invoice?.payorLineItems ?? []}
                      color={HashCardDisplayColors[i % 2]}
                      ord={i}
                      callback={actionCallback}
                      actionsByKey={actionsByKey}
                    />
                  );
                }),
                sep: (
                  <div
                    className={classNames(
                      ClassNames.adjustmentSeps,
                      'border-t border-gray-400 rounded-none',
                    )}
                  >
                    Posted Payment{adjustPayments?.length === 1 ? '' : 's'}
                  </div>
                ),
                payments: adjustPayments.length ? (
                  adjustPayments.map((pay: any, i: number) => {
                    return (
                      <PaymentCard
                        key={`pay-${i}`}
                        row={pay}
                        color={HashCardDisplayColors[i % 2]}
                        callback={actionCallback}
                        actionsByKey={actionsByKey}
                      />
                    );
                  })
                ) : (
                  <div
                    className={classNames(
                      ClassNames.noPayments,
                      'pt-4 border-t',
                    )}
                  >
                    No posted payor payments.
                  </div>
                ),
                eras: (
                  <EraCard
                    context={actionContext}
                    actionsByKey={actionsByKey}
                    callback={actionCallback}
                  />
                ),
                sep2: (
                  <>
                    <div
                      className={classNames(
                        ClassNames.adjustmentSeps,
                        'border-t border-gray-400 rounded-none',
                        adjustmentActions?.length < 2 ? 'border-b mb-3' : '',
                      )}
                    >
                      Actions{' '}
                    </div>
                    {adjustmentActions?.length > 1 ? (
                      <div
                        className={classNames(
                          ClassNames.subHeadingNoteSep,
                          'border-t border-gray-400 rounded-none pt-4 pb-2',
                        )}
                      >
                        Order of operations is significant!
                      </div>
                    ) : null}
                  </>
                ),
                actions: (
                  <div className="">
                    {adjustmentActions?.length ? (
                      adjustmentActions.map((action: any, i: number) => {
                        return (
                          <ActionCard
                            key={`action-${i}`}
                            row={action}
                            ord={i}
                            color={HashCardDisplayColors[i % 2]}
                            callback={actionCallback}
                          />
                        );
                      })
                    ) : (
                      <div className={classNames(ClassNames.noPayments)}>
                        No actions specified.
                      </div>
                    )}
                  </div>
                ),
                submit: adjustmentActions?.length ? (
                  <div className="flex justify-end mt-4 pr-2 pb-4">
                    <Button
                      className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded"
                      onClick={() => {
                        submitAdjustments();
                      }}
                      disabled={toggleRestActive}
                      loading={toggleRestActive}
                      text="Commit"
                    />
                  </div>
                ) : null,
              }}
            />
          ) : null}
          {eraHistory && Object.keys(eraHistory).length > 1 && (
            <EraHistory
              history={eraHistory}
              itemTz={invoice.tz}
              userTz={userTz}
              originalDiagnosesByCode={originalDiagnosesByCode}
            />
          )}
        </section>
        <MakeBrowserWait isWaiting={anyRestActive} />
      </Modal>
    );
  }

  /**
   * TODO: None of the following is being used. The default view is 'new'.
   * Feel free to remove in a bit. [Brian 2025-04-03]
   */
  return (
    <Modal
      isOpen={state === OpenClosedStates.Open ? true : false}
      omitClasses="sm:max-w-lg"
      addClasses="sm:max-w-xl"
      className="inline-block align-bottom bg-white dark:bg-darkGray-900 rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-lg"
    >
      {/* <pre>{ChiroUpJSON.pretty(validTransitions)}</pre> */}
      <div className="flex flex-row w-full mb-4 rounded-lg overflow-hidden rounded-b-none">
        <div className="grow bg-primary-500 text-white font-bold flex flex-row space-x-4 justify-center text-lg pt-6 p-4">
          {invoice.id === invoiceId ? (
            <>
              <div>
                {invoice.uiLegalName ?? <cite>- Payor not Available -</cite>}
              </div>
              <Loading
                flag={toggleRestActive || (isFetching && !restFailed)}
                style={`tiny-inline-white`}
              />
            </>
          ) : (
            <div className="italic text-sm">
              {restFailed ? 'Error' : 'Loading...'}
            </div>
          )}
        </div>

        <div className="bg-primary-500 text-white p-4">
          <button
            onClick={() => {
              componentClose(isDirty);
              if (isDirty) {
                updateInvoiceCallback({ invoice: null, refetch: isDirty });
              }
              setTimeout(() => {
                setSaved(false);
                setResolvedSomething(false);
                setRestFailed(false);
              }, 750);
            }}
          >
            <XCircleIcon className={ClassNames.td.xySize} />
          </button>
        </div>
      </div>
      <div className="px-6">
        {invoice.id === invoiceId ? (
          <HashCardDisplay
            header={<div>Invoice Detail</div>}
            headerComponentRight={
              <div className="flex flex-row space-x-4">
                <div className="text-center w-2/3 py-1 px-3 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                  {PayorPriorityDisplay[invoice.priority ?? 0]}
                </div>
                <div className="flex flex-row">
                  <div className="whitespace-nowrap text-center py-1 px-4 text-xs font-medium rounded-full bg-primary-50 text-primary-700 ring-1 ring-inset ring-primary-600/50">
                    {invoice.status}
                  </div>
                  <TrivialTooltip text={InvoiceStatusTips[invoice.status]} />
                </div>
              </div>
            }
            containerClassName="w-full mt-8"
            noHover={true}
            skipNullValues={true}
            grid={{ cols: 3, labelSpan: 1, valueSpan: 2 }}
            addClassName={'shadow-lg'}
            hash={{
              Patient: (
                <div className="flex flex-col w-full">
                  <div className="flex">
                    {invoice?.patient?.displayName ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames('flex', moreDetailClassName)}>
                    {invoice?.patient?.dateOfBirth
                      ? '(' +
                        ChiroUpDayJsCommon.display.date(
                          invoice.patient.dateOfBirth,
                        ) +
                        ' age ' +
                        calculatePatientAge({
                          dob: invoice.patient.dateOfBirth,
                        }) +
                        ')'
                      : null}
                  </div>
                </div>
              ),
              'Primary Insured': (
                <div className="flex flex-col">
                  <div>
                    {primaryInsuredTypeDisplay(invoice?.primaryInsured?.type)}
                  </div>
                  {invoice?.primaryInsured?.type ===
                  PrimaryInsuredTypes.Patient ? null : (
                    <div className={classNames('flex', moreDetailClassName)}>
                      {primaryInsuredNameDisplay(invoice?.primaryInsured)}
                    </div>
                  )}
                  <div className={classNames('flex', moreDetailClassName)}>
                    Member Id:{' '}
                    {invoice?.primaryInsured?.memberId ?? <cite>-na-</cite>}
                  </div>
                </div>
              ),
              'Transaction Date':
                rawInvoice?.billingKey && rawInvoice?.patientId ? (
                  <Link
                    to={`/consolidated/transaction/${rawInvoice?.billingKey}`}
                    className="text-primary-600 hover:text-primary-700"
                  >
                    <div className="flex flex-row space-x-2">
                      <div>
                        <TimestampDisplay
                          ts={invoice.transactionDate}
                          itemTz={invoice.tz}
                          userTz={userTz}
                          asDateOnly={true}
                        />
                      </div>
                      {age ? (
                        <div className="flex flex-row">
                          ({age})
                          <TrivialTooltip text="(nnn) - The number of days since the transaction date." />
                        </div>
                      ) : null}
                    </div>
                  </Link>
                ) : (
                  <div className="flex flex-row space-x-2">
                    <div>
                      <TimestampDisplay
                        ts={invoice.transactionDate}
                        itemTz={invoice.tz}
                        userTz={userTz}
                      />
                    </div>
                    {age ? (
                      <div className="flex flex-row">
                        ({age})
                        <TrivialTooltip text="(nnn) - The number of days since the transaction date." />
                      </div>
                    ) : null}
                  </div>
                ),
              Number: (
                <div>
                  #{invoice.number}
                  <TrivialTooltip
                    text={`Control #${invoice.controlNumber}`}
                    type="info"
                  />
                </div>
              ),
              'EDI Batch': invoice.interchangeControlNumber ? (
                <div
                  className="flex flex-row hover:text-primary-500"
                  title="Opens the exchange where this claim was submitted in a new tab."
                >
                  <Link
                    to={`/billing/exchanges?id=${invoice.exchangeId}`}
                    target="_blank"
                    className="flex flex-row space-x-0.5"
                  >
                    <div>{invoice.interchangeControlNumber}</div>
                    <div className="cursor-pointer pt-0.5">
                      <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                    </div>
                  </Link>
                </div>
              ) : null,
              [`ERA${(invoice?.eraIds ?? []).length > 1 ? 's' : ''}`]: (
                invoice?.eraIds ?? []
              ).length ? (
                <div className="flex flex-row space-x-3">
                  {invoice?.eraIds?.map((eraId, i) => (
                    <Link
                      key={`era-${i}`}
                      to={`/billing/eras/${eraId}`}
                      target="_blank"
                      className="flex flex-row space-x-0.5 hover:text-primary-500 "
                      title={`Opens ERA ${eraId} in a new tab.`}
                    >
                      <div>{eraId}</div>
                      <div className="cursor-pointer pt-0.5">
                        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                      </div>
                    </Link>
                  ))}
                </div>
              ) : null,
              Created: (
                <TimestampDisplay
                  ts={invoice.createdAt}
                  itemTz={invoice?.tz}
                  userTz={userTz}
                />
              ),
              Updated: invoice?.statusAt ? (
                <div>
                  {/* <pre>
                    {invoice?.statusAt} <br />
                    {new Date(invoice?.statusAt).toISOString()}
                    <br />
                    {new Date(invoice?.statusAt).toString()}
                  </pre> */}
                  <TimestampDisplay
                    ts={invoice.statusAt}
                    itemTz={invoice?.tz}
                    userTz={userTz}
                    errorValue={null}
                  />
                </div>
              ) : null,
              'Billed Amount':
                invoice?.payorLineItems && invoice.id === invoiceId
                  ? null
                  : invoice.billedAmount,
              Balance:
                invoice?.payorLineItems && invoice.id === invoiceId
                  ? null
                  : invoice.balance,
              // This looks screwy, but it puts in things that must be filled in
              // depending on the invoice state and the age of the transaction.
              ...metaByInvoiceStatus({
                invoice,
                readonly,
                callback: (args: any) => {
                  updateInvoiceCallback(args);
                  const invoice = args?.invoice || null;
                  if (invoice) setInvoiceData(invoice);
                },
              }),
              'Electronic Billing': (
                <div
                  className="flex flex-row space-x-4"
                  title={`${
                    invoice.electronicBilling
                      ? 'Electronic-billing automation.'
                      : 'Paper-billing automation.'
                  }`}
                >
                  <Toggle
                    value={!!invoice.electronicBilling}
                    disabled={disableToggle || anyRestActive}
                    onChange={async (_: any) => {
                      await toggleElectronicBilling();
                      setIsDirty(true);
                    }}
                  />
                  {invoice.electronicBilling ? null : (
                    <div className="w-full flex justify-end">
                      <div className="-mt-1 rounded-lg bg-yellow-300 pt-1 px-2 text-[10px] leading-[10px] font-light text-gray-600 ml-2 whitespace-nowrap h-5">
                        Paper Billing
                      </div>
                    </div>
                  )}
                </div>
              ),
              'Billing Profile': (
                <div className="flex flex-col w-full">
                  <div className="">
                    {invoice?.billingProfile?.name ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames(moreDetailClassName)}>
                    {invoice?.billingProfile?.npi
                      ? `(NPI #: ${invoice.billingProfile.npi})`
                      : null}
                  </div>
                </div>
              ),
              Provider: (
                <div className="flex flex-col w-full">
                  <div className="">
                    {invoice?.provider?.name ?? <cite>-na-</cite>}
                  </div>
                  <div className={classNames(moreDetailClassName)}>
                    {invoice?.provider?.npi
                      ? `(NPI #: ${invoice.provider.npi})`
                      : null}
                  </div>
                </div>
              ),
              'Place of Service': invoice?.pos ? invoice.pos : null,
            }}
            tips={{
              Generation: [
                'Replacement: Required when the payor paid',
                'incorrectly or an element of data on the claim was either not previously',
                'sent or needs to be corrected, for example rendering provider, units,',
                'amounts, or billing codes.',
                'Void: Required to eliminate the previously submitted claim when a claim',
                'is submitted in error or should not have been submitted. The entire claim',
                'must match the original with the exception of the claim frequency code,',
                'condition code, payer assigned claim number, and the patient control number.',
                'Voiding claims result in the recoupment of any payments made by the Payor',
                'and should only be used when all other measures have been taken.',
              ].join(' '),
            }}
          />
        ) : (
          <>
            <Loading style={`standard-gray`} flag={isFetching && !restFailed} />
            {restFailed ? (
              <div className="pb-4 italic">The invoice failed to load.</div>
            ) : null}
          </>
        )}
        {statusDataItem ? (
          <div className="relative">
            <HashCardDisplay
              containerClassName="w-full mt-8"
              header={`Last Status Update`}
              noHover={true}
              addClassName={'shadow-lg'}
              headerComponentRight={saveActiveIndicator}
              grid={{ cols: 3, labelSpan: 1, valueSpan: 2 }}
              hash={{
                Timestamp: (
                  <TimestampDisplay
                    ts={statusDataItem.ts}
                    itemTz={invoice?.tz}
                    userTz={userTz}
                  />
                ),
                Previous: statusDataItem.previous ?? <cite>-na-</cite>,
                Final: statusDataItem.final ?? <cite>-na-</cite>,
                Issues: issues.length ? issues : null, // Null omits these.
                Notes: notes.length ? notes : null, // Null omits the line.
              }}
              components={{
                Issues: (
                  <div>
                    {issues.length
                      ? issues.map((item, i) => {
                          return (
                            <div
                              key={`brs-${i}`}
                              className={classNames(
                                'px-4 pb-1',
                                HashCardDisplayColors[i % 2],
                                i === 0 ? 'pt-1' : '',
                              )}
                            >
                              <BusinessRuleSolution
                                result={item}
                                workTextErrors={true}
                                resolveId={i}
                                resolveCallback={resolveCallback}
                                fixAppliedCallback={fixAppliedCallback}
                                trace={trace}
                                isRestActive={anyRestActive}
                                data={{
                                  billingKey: invoice.billingKey,
                                  clinicId,
                                }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ),
                Notes: (
                  <div>
                    {notes.length
                      ? notes.map((item, i) => {
                          return (
                            <div
                              key={`brs-${i}`}
                              className={classNames(
                                'px-4 pb-1',
                                HashCardDisplayColors[i % 2],
                                i === 0 ? 'pt-1' : '',
                              )}
                            >
                              <BusinessRuleSolution
                                result={item}
                                workTextErrors={true}
                                resolveId={i}
                                trace={trace}
                                isaNote={true}
                                fixAppliedCallback={fixAppliedCallback}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                ),
              }}
            />
          </div>
        ) : null}
        {showStaticBilledServices ? (
          <div className="mt-8">
            <HashCardDisplay
              header={<div>Billed Services</div>}
              headerComponentRight={
                adjustBilledServices ? null : isaInvoiceAdjustableStatus(
                    invoice,
                  ) ? (
                  <div
                    className="text-primary-500 hover:text-primary-600 cursor-pointer"
                    onClick={(e: any) => {
                      e?.stopPropagation();
                      e?.preventDefault();
                      adjustClaim(e);
                    }}
                  >
                    <CogIcon className="w-5 h-5" />
                  </div>
                ) : null
              }
              components={{
                ...invoice?.payorLineItems?.reduce((acc, pli, idx) => {
                  acc[`${pli.payorId}.${pli.controlNumber}`] = (
                    <div
                      className={classNames(
                        HashCardDisplayColors[idx % 2],
                        'grid grid-cols-4',
                      )}
                    >
                      <div className="px-4 pt-2">
                        <span className=" text-gray-400">Service</span>
                        <TrivialTooltip
                          type="info"
                          text={`(${pli.status})${
                            pli.description ? ' - ' + pli.description : ''
                          }`}
                        />
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Units
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Billed
                      </div>
                      <div className="px-4 pt-2 opacity-50 flex justify-end">
                        Balance
                      </div>
                      <div className="px-4 pb-2">
                        {pli.adjudicatedProcedureCode ||
                          pli.submittedProcedureCode}
                        <TrivialTooltip
                          type="info"
                          text={pli.controlNumber}
                          tipClassName="w-64"
                        />
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        {pli.submittedUnits}
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        <Currency value={pli.billedAmount} />
                      </div>
                      <div className="px-4 pb-2 flex justify-end">
                        <Currency
                          value={
                            (pli.billedAmount ?? 0) -
                            (pli.remitAmount ?? 0) -
                            (pli.adjustmentTotal ?? 0)
                          }
                        />
                      </div>
                      {pli.remitDate && (
                        <div className="border-t border-gray-900/5 px-6 py-6 col-span-4">
                          <div className="underline">Payment</div>

                          <div className="flex w-full mb-5">
                            <div className="flex justify-between text-gray-500 text-sm w-full">
                              <div className="flex flex-row space-x-2">
                                <TimestampDisplay
                                  ts={pli.remitDate}
                                  itemTz={invoice?.tz}
                                  format={ChiroUpDayJsCommon.format.date}
                                  userTz={userTz}
                                />{' '}
                                {pli.postedDate ? (
                                  <>
                                    <span>posted</span>
                                    <TimestampDisplay
                                      ts={pli.postedDate}
                                      itemTz={invoice?.tz}
                                      userTz={userTz}
                                      format={ChiroUpDayJsCommon.format.date}
                                    />
                                  </>
                                ) : null}
                              </div>
                              <div>{formatCurrency(pli.remitAmount)}</div>
                            </div>
                          </div>

                          <div className="underline">Adjustments</div>
                          {pli.adjustments?.map((adj: any, index: number) => (
                            <div
                              key={`pli-${pli.id}-adj-${adj.groupCode}-${index}`}
                            >
                              <div
                                key={adj.groupCode}
                                className="flex flex-row text-sm"
                              >
                                <div className="flex flex-col">
                                  <div>{adj.groupDescription}</div>
                                </div>
                              </div>

                              {adj.items.map((item: any, itemIndex: number) => (
                                <div key={`adj-${itemIndex}`}>
                                  <div className="flex w-full"></div>
                                  <div
                                    key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}`}
                                    className="flex justify-between text-gray-500 text-sm w-full mb-2"
                                  >
                                    <div>{item.description}</div>
                                    <div className="self-end">
                                      {formatCurrency(item.amount)}
                                    </div>
                                  </div>

                                  <div className="w-full text-xs">
                                    {item.remarks &&
                                      item.remarks.length > 0 && (
                                        <div className="flex flex-row">
                                          <div className="flex flex-col">
                                            <div className="text-gray-500">
                                              {item.remarks.map(
                                                (
                                                  remark: any,
                                                  remarkIndex: number,
                                                ) => (
                                                  <div
                                                    key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}-remark-${remark.code}-${remarkIndex}`}
                                                    className="flex flex-row mt-2 text-sm"
                                                  >
                                                    <div className="flex flex-col">
                                                      <div className="text-gray-500">
                                                        {remark.description}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ),
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                          {!pli.adjustments ||
                            (pli.adjustments?.length === 0 && (
                              <div className="flex w-full">
                                <div className="flex justify-between text-gray-500 text-sm w-full">
                                  <div>No adjustments were made.</div>
                                </div>
                              </div>
                            ))}
                          {(pli?.remarks?.length ?? 0) !== 0 && (
                            <div className="mt-6">Additional Remarks</div>
                          )}
                          {pli.remarks?.map(
                            (remark: any, remarkIndex: number) => (
                              <div
                                key={`pli-${pli.id}-remark-${remark.code}-${remarkIndex}`}
                                className="flex flex-row mt-2 text-sm"
                              >
                                <div className="flex flex-col">
                                  <div className="text-gray-500">
                                    {remark.description}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}

                          {(pli?.internalNotes?.length ?? 0) > 0 && (
                            <div className="mt-6">Internal Notes</div>
                          )}
                          {pli.internalNotes?.map(
                            (note: any, noteIndex: number) => (
                              <div
                                key={`pli-${pli.id}-remark-${note.createdAt}-${noteIndex}`}
                                className="flex flex-row mt-2 text-sm"
                              >
                                <div className="flex flex-col">
                                  <div className="text-gray-500">
                                    {note.message}
                                  </div>
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      )}
                    </div>
                  );
                  return acc;
                }, {} as STRING_ANY_HASH),
                Balance: (
                  <div
                    className={classNames(
                      'bg-gray-100 border-t-2 border-gray-200',
                      'grid grid-cols-4',
                    )}
                  >
                    <div>&nbsp;</div>
                    <div className="px-4 py-2 flex justify-end">&nbsp;</div>
                    <div className="px-4 py-2 flex justify-end">
                      <Currency value={invoice.billedAmount} />
                    </div>
                    <div className="px-4 py-2 flex justify-end">
                      <Currency value={invoice.balance} />
                    </div>
                  </div>
                ),
              }}
              containerClassName="w-full mt-8"
              noHover={true}
              skipNullValues={true}
              addClassName={'shadow-lg'}
            />
          </div>
        ) : null}
        {adjustBilledServices ? (
          <HashCardDisplay
            header={<div>Adjust Billed Services</div>}
            headerClassName="overflow-hidden border-b-2 border-transparent flex flex-row bg-primary-500 text-white px-4 py-2 font-semibold"
            headerComponentRight={
              <div
                className="text-white hover:text-black cursor-pointer"
                onClick={(e: any) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  adjustClaim(e);
                }}
              >
                <XMarkIcon className="w-5 h-5" />
              </div>
            }
            containerClassName="w-full mt-8"
            noHover={true}
            skipNullValues={true}
            addClassName={'shadow-lg'}
            components={{
              debug: trace ? (
                <pre>
                  {ChiroUpJSON.pretty({
                    actionsByKey,
                    // postByCode: actionContext?.postByCode,
                    unpostByCode: actionContext?.unpostByCode,
                    // adjustmentActions,
                  })}
                </pre>
              ) : null,
              h2: (
                <div className={ClassNames.adjustmentSeps}>
                  Current Services
                </div>
              ),
              current: (
                <CurrentCard
                  rows={invoice.payorLineItems ?? []}
                  callback={actionCallback}
                  actionsByKey={actionsByKey}
                  invoice={invoice}
                  context={actionContext}
                />
              ),
              items: (
                <TransactionItemsCard
                  context={actionContext}
                  actionsByKey={actionsByKey}
                  callback={actionCallback}
                />
              ),
              h2_1: (
                <div
                  className={classNames(
                    ClassNames.adjustmentSeps,
                    'border-t border-b border-gray-400',
                  )}
                >
                  Insurance
                </div>
              ),
              insurance: (
                <div className="px-4 mt-4">
                  <table className="w-full mb-4">
                    <thead>
                      <tr className="text-sx text-gray-400">
                        <th className={ClassNames.table.iFirstTh}>Name</th>
                        <th className={ClassNames.table.th}>Code</th>
                        <th className={ClassNames.table.th}>Invoice</th>
                        <th className={ClassNames.table.th}>ERA</th>
                        <th className={ClassNames.table.th}>Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(actionContext?.insuranceById ?? {}).map(
                        (item: any, idx: number) => {
                          return (
                            <tr key={`insurance-${idx}`}>
                              <td
                                className={classNames(
                                  ClassNames.table.firstCell,
                                )}
                              >
                                {item.name}
                              </td>
                              <td className={ClassNames.table.cell}>
                                {item?.code}
                              </td>
                              <td
                                className={classNames(
                                  'w-16',
                                  ClassNames.table.cell,
                                )}
                              >
                                <DisplayBoolean value={item?.invoice} />
                              </td>
                              <td
                                className={classNames(
                                  'w-16',
                                  ClassNames.table.cell,
                                )}
                              >
                                <DisplayBoolean value={item?.cre} />
                              </td>
                              <td
                                className={classNames(
                                  'w-16',
                                  ClassNames.table.cell,
                                )}
                              >
                                <DisplayBoolean value={item?.payment} />
                              </td>
                            </tr>
                          );
                        },
                      )}
                    </tbody>
                  </table>
                </div>
              ),
              h2_2: (
                <div
                  className={classNames(
                    ClassNames.adjustmentSeps,
                    'border-t border-gray-400',
                  )}
                >
                  ERA Claim Response{adjustResponses?.length === 1 ? '' : 's'}{' '}
                  <span className={ClassNames.subHeadingNote}>
                    (Most-recent First)
                  </span>
                </div>
              ),
              responses: adjustResponses.map((resp: any, i: number) => {
                return (
                  <ClaimResponseEraCard
                    key={`resp-${i}`}
                    row={resp}
                    // payorLineItems={invoice?.payorLineItems ?? []}
                    color={HashCardDisplayColors[i % 2]}
                    ord={i}
                    callback={actionCallback}
                    actionsByKey={actionsByKey}
                  />
                );
              }),
              sep: (
                <div
                  className={classNames(
                    ClassNames.adjustmentSeps,
                    'border-t border-gray-400 rounded-none',
                  )}
                >
                  Posted Payment{adjustPayments?.length === 1 ? '' : 's'}
                </div>
              ),
              payments: adjustPayments.length ? (
                adjustPayments.map((pay: any, i: number) => {
                  return (
                    <PaymentCard
                      key={`pay-${i}`}
                      row={pay}
                      color={HashCardDisplayColors[i % 2]}
                      callback={actionCallback}
                      actionsByKey={actionsByKey}
                    />
                  );
                })
              ) : (
                <div
                  className={classNames(ClassNames.noPayments, 'pt-4 border-t')}
                >
                  No posted payor payments.
                </div>
              ),
              eras: (
                <EraCard
                  context={actionContext}
                  actionsByKey={actionsByKey}
                  callback={actionCallback}
                />
              ),
              sep2: (
                <>
                  <div
                    className={classNames(
                      ClassNames.adjustmentSeps,
                      'border-t border-gray-400 rounded-none',
                      adjustmentActions?.length < 2 ? 'border-b mb-3' : '',
                    )}
                  >
                    Actions{' '}
                  </div>
                  {adjustmentActions?.length > 1 ? (
                    <div
                      className={classNames(
                        ClassNames.subHeadingNoteSep,
                        'border-t border-gray-400 rounded-none pt-4 pb-2',
                      )}
                    >
                      Order of operations is significant!
                    </div>
                  ) : null}
                </>
              ),
              actions: (
                <div className="">
                  {adjustmentActions?.length ? (
                    adjustmentActions.map((action: any, i: number) => {
                      return (
                        <ActionCard
                          key={`action-${i}`}
                          row={action}
                          ord={i}
                          color={HashCardDisplayColors[i % 2]}
                          callback={actionCallback}
                        />
                      );
                    })
                  ) : (
                    <div className={classNames(ClassNames.noPayments)}>
                      No actions specified.
                    </div>
                  )}
                </div>
              ),
              submit: adjustmentActions?.length ? (
                <div className="flex justify-end mt-4 pr-2 pb-4">
                  <Button
                    className="bg-primary-500 hover:bg-primary-600 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                      submitAdjustments();
                    }}
                    disabled={toggleRestActive}
                    loading={toggleRestActive}
                    text="Commit"
                  />
                </div>
              ) : null,
            }}
          />
        ) : null}
        {eraHistory && Object.keys(eraHistory).length > 1 && (
          <EraHistory
            history={eraHistory}
            itemTz={invoice.tz}
            userTz={userTz}
          />
        )}
      </div>
      {invoice.id === invoiceId ? (
        <>
          {updatingStatus && (
            <Modal isOpen={true} close={close}>
              <div className="border border-red-500">
                <Select
                  label="New Status"
                  name="newStatus"
                  value={newStatus}
                  options={validTransitions}
                  onChange={(val: any) => {
                    if (!val) return;
                    setNewStatus(val);
                  }}
                  limit={1}
                  className={`w-full`}
                />

                <IconButton
                  className="h-5 w-5 hover:text-primary-500 text-gray-600 pt-0.5"
                  icon={<XCircleIcon className="h-6 w-6" />}
                  tooltip="Cancel"
                  onClick={() => {
                    setUpdatingStatus(false);
                  }}
                  disabled={isRestActive.updateStatus}
                />
                <IconButton
                  onClick={async () => {
                    if (!newStatus) return;
                    setSaved(false);
                    setUpdatingStatus(false);
                    setIsRestActive((p) => ({ ...p, updateStatus: true }));
                    const clone = ChiroUpJSON.clone(invoice);
                    clone.status = newStatus;
                    const title = `Update Status`;

                    try {
                      const results =
                          await patientBillingService.setInvoiceStatus({
                            clinicId,
                            invoiceId: invoice.id,
                            status: newStatus,
                            persistIssues: true,
                            options: patientBillingServiceOptions,
                          }),
                        result =
                          results &&
                          results?.invoices &&
                          results?.invoices?.[0],
                        saveIssues = results?.issues ?? [],
                        saveNotes = results?.notes ?? [];

                      setSaved(true);
                      if (saveIssues && saveIssues.length) {
                        createToast({
                          title,
                          description: `${
                            saveIssues?.length
                              ? saveIssues
                                  .map((i: BusinessRuleItemType) => i.text)
                                  .join(' ')
                              : 'No issues.'
                          }`,
                          type: ToastTypes.Info,
                          duration: 5000,
                        });
                      } else {
                        setInvoiceData(result);
                        updateInvoiceCallback({
                          invoice: result,
                          refetch: true,
                        });
                      }
                      if (saveNotes && saveNotes.length) {
                        createToast({
                          title,
                          description: `${
                            saveIssues?.length
                              ? saveIssues
                                  .map((i: BusinessRuleItemType) => i.text)
                                  .join(' ')
                              : 'No notes.'
                          }`,
                          type: ToastTypes.Info,
                          duration: 5000,
                        });
                      }
                    } catch (e: any) {
                      createToast({
                        title,
                        description: `${
                          e.message ?? e.response?.data?.message
                        }`,
                        type: ToastTypes.Fail,
                        duration: 5000,
                      });
                      console.error({ e });
                    }
                    setSaved(false);
                    setIsRestActive((p) => ({ ...p, updateStatus: false }));
                  }}
                  disabled={
                    isRestActive.updateStatus ||
                    newStatus === null ||
                    newStatus === invoice.status
                  }
                  loading={isRestActive.updateStatus}
                  className="h-5 w-5 hover:text-primary-500 text-gray-600"
                  icon={icons.saveDisk}
                  tooltip="Save"
                />
              </div>
            </Modal>
          )}
          <div className="flex grow p-6 justify-between">
            <div className="flex items-center">
              <IconButton
                className={`w-9 h-9 ${noteColor}`}
                icon={<ClipboardDocumentListIcon />}
                tooltip="Notes"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleOpenInvoiceNotes(invoiceId);
                }}
              />
              {showInvoiceNotesModal && (
                <InvoiceNotesModal
                  invoice={invoice}
                  state={
                    showInvoiceNotesModal
                      ? OpenClosedStates.Open
                      : OpenClosedStates.Closed
                  }
                  onClose={() => {
                    setShowInvoiceNotesModal(false);
                    refetchData();
                  }}
                />
              )}
            </div>
            <div
              className={
                shrinkButtons
                  ? 'flex items-center space-x-2'
                  : 'flex items-center space-x-4'
              }
            >
              {buttonsByInvoiceStatus(invoice)}
            </div>
          </div>
        </>
      ) : null}
      {trace ? (
        <div>
          <pre>
            {ChiroUpJSON.pretty({
              hasIssues,
              saved,
              latestInvoiceMessages,
              invoiceId,
              eraHistory,
            })}
          </pre>
        </div>
      ) : null}
      <MakeBrowserWait isWaiting={anyRestActive} />
    </Modal>
  );
};

type AdjustmentEraTypeHash = {
  [key: string]: AdjustmentEraType;
};

type AdjustmentEraType = { id: number; services: ClaimServiceItemType[] };

type ClaimResponseEraCardProps = {
  row: ClaimResponseEraType & {
    payorName: string;
    payorCode: string;
    adjustmentAmount: number;
    remitDate: number;
  };
  color?: string;
  ord: number;
  callback: (args: AdjustmentActionType) => void;
  actionsByKey: STRING_NUMBER_HASH;
  context?: STRING_ANY_HASH;
};

const ClaimResponseEraCard: React.FC<ClaimResponseEraCardProps> = ({
  row,
  color = 'bg-white',
  ord = 0,
  callback,
  actionsByKey,
}) => {
  const services = useMemo(() => {
    if (row && row.serviceItems) {
      return row.serviceItems.map((item: any) => {
        return {
          ...item,
          remitAmount: Number(item?.remitAmount ?? 0),
          adjustmentTotal: item?.adjustments
            ?.reduce((topAcc: Decimal, adj: any, index: number) => {
              const sum = adj.items.reduce((acc: Decimal, item: any) => {
                return acc.add(createDecimal(item.amount ?? 0));
              }, createDecimal(0));
              return topAcc.add(sum);
            }, createDecimal(0))
            .toNumber(),
        };
      });
    }
  }, [row]);

  const numbersFromServices = useMemo(() => {
    const response = {
      billed: createDecimal(0),
      payment: createDecimal(0),
      adjustments: createDecimal(0),
      balance: createDecimal(0),
    };
    if (services?.length) {
      services.forEach((item: any) => {
        response.billed = response.billed.add(
          createDecimal(item.billedAmount ?? 0),
        );
        response.payment = response.payment.add(
          createDecimal(item.remitAmount ?? 0),
        );
        response.adjustments = response.adjustments.add(
          createDecimal(item.adjustmentTotal ?? 0),
        );
        response.balance = response.balance.add(
          createDecimal(item.billedAmount ?? 0)
            .sub(createDecimal(item.remitAmount ?? 0))
            .sub(createDecimal(item.adjustmentTotal ?? 0)),
        );
      });
    }
    return {
      billed: response.billed.toNumber(),
      payment: response.payment.toNumber(),
      adjustments: response.adjustments.toNumber(),
      balance: response.balance.toNumber(),
    };
  }, [services]);

  const grandAdjustmentTotal = useMemo(() => {
    if (services) {
      return services
        .reduce((topAcc: Decimal, item: any) => {
          return topAcc.add(createDecimal(item.adjustmentTotal));
        }, createDecimal(0))
        .toNumber();
    }
    return 0;
  }, [services]);

  const myApplyAdjKey = useMemo(() => {
    return ['apply-adj', 'cre', row.eraId, row.id].join('.');
  }, [row.eraId, row.id]);

  const myApplyAdjSupported =
    SupportedInvoiceActions[['apply-adj', 'cre'].join('.')] ?? false;

  const noServicesMessage = useMemo(() => {
    if (row?.fullClaimResponse) {
      const resp = row.fullClaimResponse || {};
      return (
        <div>
          <div className="grid grid-cols-4 w-full pb-4">
            <div
              className={classNames(
                ClassNames.noServicesFound,
                'col-span-4 py-4',
              )}
            >
              No services were found.
            </div>
            <div className={ClassNames.cre.firstIdentLabel}>Control Number</div>
            <div className={ClassNames.cre.identLabel}>Charge</div>
            <div className={ClassNames.cre.identLabel}>Payment</div>
            <div className={ClassNames.cre.lastLabel}>Claim Received</div>
            <div className={ClassNames.cre.firstIdentValue}>
              {resp?.patientControlNumber ?? '-na-'}
            </div>
            <div className={ClassNames.cre.identValue}>
              <Currency
                value={resp?.totalCharge ?? 0}
                negativeClassName="text-red-500"
              />
            </div>
            <div className={ClassNames.cre.identValue}>
              <Currency
                value={resp?.paymentAmount ?? 0}
                negativeClassName="text-red-500"
              />
            </div>
            <div className={ClassNames.cre.identValue}>
              {resp?.claimReceivedDate ?? '-na-'}
            </div>
            <div
              className={classNames(
                ClassNames.cre.firstIdentLabel,
                'col-span-2 mt-2',
              )}
            >
              Status
            </div>
            <div
              className={classNames(
                ClassNames.cre.lastLabel,
                'col-span-2 mt-2',
              )}
            >
              Payer Control Number
            </div>
            <div className="col-span-2">
              {resp?.statusCode ?? '-na-'} - {resp?.statusDescription}
            </div>
            <div className={classNames(ClassNames.cre.lastValue, 'col-span-2')}>
              {resp?.payerClaimControlNumber ?? '-na-'}
            </div>
          </div>
        </div>
      );
    }
    return <div className="text-gray-400 italic">No services were found.</div>;
  }, [row.fullClaimResponse]);

  const statusToPostKey = ['status-to-post', 'cre', row?.id].join('.');
  const statusToPostSupported = SupportedInvoiceActions['status-to-post.cre'];

  const myAction: AdjustmentActionType = {
      action: 'post',
      type: 'cre',
      row,
      pk: [row?.eraId || 'current', row.id].join('.'),
    },
    myKey = [myAction.action, myAction.type, myAction.pk].join('.'),
    myPrefix = [myAction.action, myAction.type].join('.'),
    myActionSupported = SupportedInvoiceActions[myPrefix];
  // const statusKey = ['set-post-status', 'cre', row.eraId, row.id].join('.');

  return (
    <div className="">
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full ${color}`}
        bodyClassName="text-gray-800 overflow-hidden border-t border-gray-400 rounded-none"
        components={{
          eraId: (
            <div className="grid grid-cols-4 w-full px-4 pb-2 mt-2">
              <div className={ClassNames.cre.firstIdentLabel}>
                ERA Line Item No.
              </div>
              <div className={ClassNames.cre.identLabel}>ERA</div>
              <div className={ClassNames.cre.identLabel}>Control Number</div>
              <div className={ClassNames.cre.lastLabel}>Payor</div>
              <div className={ClassNames.cre.firstIdentValue}>{row.id}</div>
              <div className={ClassNames.cre.identValue}>{row.eraId}</div>
              <div className={ClassNames.cre.identValue}>
                {row.controlNumber}
              </div>
              <div
                className={classNames(
                  ClassNames.cre.lastValue,
                  'flex-row space-x-1',
                )}
              >
                <TrivialTooltip text={row.payorName} type={`info`} />
                <div>{row.payorCode}</div>
              </div>
            </div>
          ),
          numbers: (
            <div className="grid grid-cols-4 w-full px-4">
              <div className={ClassNames.cre.firstNumberLabel}>Billed</div>
              <div className={ClassNames.cre.numbersLabel}>Payment</div>
              <div className={ClassNames.cre.numbersLabel}>Adjustments</div>
              <div className={ClassNames.cre.numbersLabel}>Balance</div>
              <div className={ClassNames.cre.firstNumberValue}>
                <Currency
                  value={numbersFromServices.billed}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={numbersFromServices.payment}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={grandAdjustmentTotal}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={numbersFromServices.balance}
                  negativeClassName="text-red-500"
                />
              </div>
            </div>
          ),
          last: (
            <div className="grid grid-cols-3 w-full px-4 pt-2">
              <div className={ClassNames.cre.firstLastLabel}>Status</div>
              <div className={classNames(ClassNames.cre.lastLabel)}>
                Remit Date
              </div>
              <div
                className={classNames(
                  'flex-row space-x-1',
                  ClassNames.cre.lastLabel,
                )}
              >
                <TrivialTooltip
                  text={dayjs(row?.postedAt).format(
                    ChiroUpDayJsCommon.format.isoAt,
                  )}
                />
                <div>Posted</div>
              </div>
              <div
                className={classNames(
                  ClassNames.cre.firstLastValue,
                  'flex flex-row space-x-2',
                )}
              >
                <div>{row.status}</div>
                {actionsByKey[myKey] ? (
                  <div
                    className={classNames(ClassNames.circle, 'cursor-pointer')}
                    onClick={() => {
                      callback({
                        action: 'trash',
                        type: 'item',
                        pk: (ord - 1).toString(),
                        row,
                      });
                    }}
                  >
                    {actionsByKey[myKey]}
                  </div>
                ) : row.status !== 'Posted' && services?.length ? (
                  myActionSupported ? (
                    <IconButton
                      className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                      icon={<DocumentPlusIcon className="w-5 h-5" />}
                      tooltip={`Post this ERA line item to the claim ${myAction.pk}.`}
                      tooltipClassName="w-64"
                      onClick={() => {
                        callback({
                          ...myAction,
                          row,
                          pk: [myAction.pk].join('.'),
                        });
                      }}
                    />
                  ) : null
                ) : null}
                {(row?.status === ClaimResponseEraStatus.Posted ||
                  row?.status === ClaimResponseEraStatus.Applied) && (
                  <div>
                    {actionsByKey[myApplyAdjKey] ? (
                      <div
                        className={classNames(
                          ClassNames.circle,
                          'cursor-pointer',
                        )}
                        onClick={() => {
                          callback({
                            action: 'trash',
                            type: 'item',
                            pk: (ord - 1).toString(),
                            row,
                          });
                        }}
                      >
                        {actionsByKey[myApplyAdjKey]}
                      </div>
                    ) : myApplyAdjSupported ? (
                      <IconButton
                        className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                        icon={<ArrowUturnUpIcon className="w-5 h-5" />}
                        tooltip="Re-apply adjudication info to claim."
                        onClick={() => {
                          callback({
                            action: 'apply-adj',
                            type: 'cre',
                            pk: [row.eraId, row.id].join('.'),
                            row,
                          });
                        }}
                      />
                    ) : null}
                  </div>
                )}
                {row?.status !== ClaimResponseEraStatus.Posted &&
                services?.length &&
                SupportedInvoiceActions[['status-to-post', 'cre'].join('.')] ? (
                  <div>
                    {/* ..... */}
                    <div>
                      {actionsByKey[statusToPostKey] ? (
                        <div
                          className={classNames(
                            ClassNames.circle,
                            'cursor-pointer',
                          )}
                          onClick={() => {
                            callback({
                              action: 'trash',
                              type: 'item',
                              pk: (ord - 1).toString(),
                              row,
                            });
                          }}
                        >
                          {actionsByKey[statusToPostKey]}
                        </div>
                      ) : statusToPostSupported ? (
                        <IconButton
                          className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                          icon={<ArrowDownOnSquareIcon className="w-5 h-5" />}
                          tooltip="Set the status to Posted without adjusting anything else."
                          tooltipClassName="w-64"
                          onClick={() => {
                            callback({
                              action: 'status-to-post',
                              type: 'cre',
                              row,
                              pk: String(row.id),
                            });
                          }}
                        />
                      ) : null}
                    </div>
                    {/* ..... */}
                  </div>
                ) : null}
              </div>
              <div className={ClassNames.cre.lastValue}>
                <TimestampDisplay
                  ts={row.remitDate}
                  format={ChiroUpDayJsCommon.format.isoDate}
                />
              </div>
              <div className={ClassNames.cre.lastValue}>
                <TimestampDisplay
                  ts={row.postedAt}
                  format={ChiroUpDayJsCommon.format.isoDate}
                  // format={ChiroUpDayJsCommon.format.isoAt}
                />
              </div>
            </div>
          ),
          services: (
            <div className="px-4">
              {services?.length ? (
                <table className="w-full my-4">
                  <thead>
                    <tr className="text-sx text-gray-400">
                      <th className={ClassNames.table.firstTh}>Service</th>
                      <th className={ClassNames.table.th}>Units</th>
                      <th className={ClassNames.table.th}>Billed</th>
                      <th className={ClassNames.table.th}>Paid</th>
                      <th className={ClassNames.table.th}>Adj's</th>
                      <th className={classNames(ClassNames.table.th)}>
                        Balance
                      </th>
                      {row?.status === 'Posted' ? null : <th>&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {services?.map(
                      (item: ClaimServiceItemType, idx: number) => {
                        const adjTotal = item?.adjustments?.reduce(
                          (topAcc: Decimal, adj: any) => {
                            const sum = adj.items.reduce(
                              (acc: Decimal, item: any) => {
                                return acc.add(createDecimal(item.amount ?? 0));
                              },
                              createDecimal(0),
                            );
                            return topAcc.add(sum);
                          },
                          createDecimal(0),
                        );
                        const rowKey = [myKey, idx].join('.');
                        return (
                          <tr key={`cre-service-${ord}-${idx}`}>
                            <td
                              className={classNames(ClassNames.table.firstCell)}
                            >
                              {item.adjudicatedServiceCode}
                            </td>
                            <td className={ClassNames.table.cell}>
                              {item.adjudicatedServiceUnits}
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.billedAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.remitAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={adjTotal?.toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={classNames(ClassNames.table.cell)}>
                              <Currency
                                value={createDecimal(item.billedAmount)
                                  .minus(item.remitAmount ?? 0)
                                  .minus(adjTotal ?? 0)
                                  .toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            {row?.status === 'Posted' ? null : (
                              <td className={ClassNames.callback}>
                                <div className="flex justify-end">
                                  {actionsByKey[rowKey] ? (
                                    <div
                                      className={classNames(
                                        ClassNames.circle,
                                        'cursor-pointer',
                                      )}
                                      onClick={() => {
                                        callback({
                                          action: 'trash',
                                          type: 'item',
                                          pk: (ord - 1).toString(),
                                          row,
                                        });
                                      }}
                                    >
                                      {actionsByKey[rowKey]}
                                    </div>
                                  ) : actionsByKey[myKey] ? (
                                    <div
                                      className="cursor-not-allowed text-black w-5"
                                      title="This line-item will be applied when the claim is posted."
                                    >
                                      <CheckIcon className="w-5 h-5" />
                                    </div>
                                  ) : myActionSupported ? (
                                    <IconButton
                                      className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                                      icon={
                                        <DocumentPlusIcon className="w-5 h-5" />
                                      }
                                      tooltip="Post this ERA line item to the claim."
                                      onClick={() => {
                                        callback({
                                          ...myAction,
                                          row: {
                                            ...item,
                                            status: row?.status,
                                            eraId: row?.eraId,
                                            id: row?.id,
                                          },
                                          pk: [myAction.pk, String(idx)].join(
                                            '.',
                                          ),
                                        });
                                      }}
                                    />
                                  ) : null}
                                </div>
                              </td>
                            )}
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              ) : (
                noServicesMessage
              )}
            </div>
          ),
        }}
      />
      {/* <pre className={color}>{ChiroUpJSON.pretty(row)}</pre> */}
    </div>
  );
};

type PaymentCardProps = {
  row: PatientTransaction & {
    payorName: string;
    payorCode: string;
    debitSum: number;
    creditSum: number;
  };
  color?: string;
  callback: (args: AdjustmentActionType) => void;
  actionsByKey: STRING_NUMBER_HASH;
};

const PaymentCard: React.FC<PaymentCardProps> = ({
  row,
  color = 'bg-white',
  callback,
  actionsByKey,
}) => {
  const summaryItems = useMemo(() => {
    if (row?.items) {
      return row.items.map((item) => {
        return {
          ...item,
          isAmountNumber: item?.amount !== undefined && item?.amount !== null,
        };
      });
    }
    return [];
  }, [row]);
  const myAction: AdjustmentActionType = {
      action: 'rollback',
      type: 'payment',
      row,
      pk: String(row.referenceNumber),
    },
    myKey = [myAction.action, myAction.type, row.referenceNumber].join('.'),
    myPrefix = [myAction.action, myAction.type].join('.'),
    myActionSupported = SupportedInvoiceActions[myPrefix];

  const myInitialPayment = useMemo(() => {
    return summaryItems.find(
      (item) =>
        item?.type === TransactionItemTypeEnum.Credit &&
        (item?.description ?? '')
          .toLocaleLowerCase()
          .indexOf('initial payment') === 0,
    )?.id;
  }, [summaryItems]);

  const myInitialPaymentKey = useMemo(() => {
    return ['rollback', 'initial', row?.id].join('.');
  }, [row?.id]);

  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full ${color}`}
        bodyClassName="text-gray-800 overflow-hidden border-t border-gray-400 rounded-none"
        components={{
          // debug: <pre>{ChiroUpJSON.pretty(myInitialPayment)}</pre>,
          eraId: (
            <div className="grid grid-cols-4 w-full px-4 pb-2 mt-2">
              <div className={ClassNames.cre.firstIdentLabel}>Date</div>
              <div className={ClassNames.cre.identLabel}>ERA</div>
              <div
                className={classNames(
                  ClassNames.cre.identLabel,
                  'flex-row space-x-1',
                )}
              >
                <TrivialTooltip
                  text={
                    ((PAYMENT_TYPE_DISPLAY as STRING_STRING_HASH)[
                      row.subtype ?? ''
                    ] ?? '- unknown subtype -') as string
                  }
                  type={`info`}
                />
                <div>Type</div>
              </div>
              <div className={ClassNames.cre.lastLabel}>Payor</div>
              <div className={ClassNames.cre.firstIdentValue}>
                <TimestampDisplay
                  ts={row.transactionDate}
                  format={ChiroUpDayJsCommon.format.isoDate}
                />
              </div>
              <div className={ClassNames.cre.identValue}>
                {row.referenceNumber ?? '-'}
              </div>
              <div className={ClassNames.cre.identValue}>{row.subtype}</div>
              <div
                className={classNames(
                  ClassNames.cre.lastValue,
                  'flex-row space-x-1',
                )}
              >
                <TrivialTooltip text={row.payorName} type={`info`} />
                <div>
                  {row.payorCode ??
                    (row?.referenceNumber &&
                    PayorPaymentTypesHash[row?.subtype ?? '']
                      ? '-'
                      : 'Patient')}
                </div>
              </div>
            </div>
          ),
          numbers: (
            <div className="grid grid-cols-3 w-full px-4">
              <div className={ClassNames.cre.firstNumberLabel}>Debits</div>
              <div className={ClassNames.cre.numbersLabel}>Credits</div>
              <div className={ClassNames.cre.numbersLabel}>Balance</div>
              <div className={ClassNames.cre.firstNumberValue}>
                <Currency
                  value={row.debitSum}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={row.creditSum}
                  negativeClassName="text-red-500"
                />
              </div>
              <div className={ClassNames.cre.numbersValue}>
                <Currency
                  value={row.creditSum - row.debitSum}
                  negativeClassName="text-red-500"
                />
              </div>
            </div>
          ),
          summary: (
            <div className="px-4">
              <table className="w-full my-4">
                <thead>
                  <tr className="text-sx text-gray-400">
                    <th className={classNames(ClassNames.table.firstTh)}>
                      Units
                    </th>
                    <th className={ClassNames.table.leftTh}>Description</th>
                    <th>&nbsp;</th>
                    <th className={ClassNames.table.th}>Amount</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryItems?.map((item, idx: number) => {
                    const rowKey = [myKey, item?.id].join('.');
                    let myIndicator: any = null;
                    if (item?.id === myInitialPayment) {
                      myIndicator = actionsByKey[myInitialPaymentKey] ? (
                        <div
                          className={classNames(
                            ClassNames.circle,
                            'cursor-not-allowed',
                          )}
                        >
                          {actionsByKey[myInitialPaymentKey]}
                        </div>
                      ) : null;
                    }
                    let description = (item?.description ?? '-').replace(
                      /\.$/,
                      '',
                    );
                    if (description.length > 43) {
                      description = description.substring(0, 40) + '...';
                    }
                    return (
                      <tr key={`payment-item-${idx}`}>
                        <td
                          className={classNames(
                            ClassNames.table.leftCell,
                            'pr-2',
                          )}
                        >
                          {item.units}
                        </td>
                        <td className={classNames(ClassNames.table.leftCell)}>
                          {description}
                        </td>
                        <td>
                          {item?.type === TransactionItemTypeEnum.Credit
                            ? '+'
                            : '-'}
                        </td>
                        <td className={classNames(ClassNames.table.cell)}>
                          <Currency
                            value={item.amount}
                            negativeClassName="text-red-500"
                          />
                        </td>
                        <td className={ClassNames.callback}>
                          <div className="flex justify-end">
                            {/* {rowKey} */}
                            {actionsByKey[rowKey] ? (
                              <div
                                className={ClassNames.circle}
                                onClick={() => {
                                  const ord = actionsByKey[rowKey] - 1;
                                  callback({
                                    action: 'trash',
                                    type: 'item',
                                    pk: ord.toString(),
                                    row: {},
                                  });
                                }}
                              >
                                {actionsByKey[rowKey]}
                              </div>
                            ) : myIndicator ? (
                              myIndicator
                            ) : item?.isAmountNumber ? (
                              myActionSupported ? (
                                <IconButton
                                  className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                                  icon={
                                    <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                                  }
                                  tooltip="Rollback this payment."
                                  onClick={() => {
                                    callback({
                                      ...myAction,
                                      row: {
                                        ...item,
                                        eraId: row.referenceNumber,
                                      },
                                      pk: [row.referenceNumber, item.id].join(
                                        '.',
                                      ),
                                    });
                                  }}
                                />
                              ) : null
                            ) : (
                              <TrivialTooltip
                                text="The database field value is not a number, so zero is displayed."
                                type="info"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ),
        }}
      />
      {/* <pre>{ChiroUpJSON.pretty(row)}</pre> */}
    </div>
  );
};

type EraHistoryProps = {
  history: STRING_ANY_HASH | null;
  containerClassName?: string;
  itemTz?: string;
  userTz?: string;
  originalDiagnosesByCode?: STRING_STRING_HASH;
};
export const EraHistory: React.FC<EraHistoryProps> = ({
  containerClassName = '',
  history,
  itemTz = ChiroUpDayJsCommon.defaultTimezone,
  userTz = ChiroUpDayJsCommon.defaultTimezone,
  originalDiagnosesByCode = {},
}) => {
  // Order is descending. The ones on the top will be
  // the most recent (primary key is always increasing).
  // If there ends up to be ANY that don't have an ERA
  // id [which should not happen, BTW], they will be
  // at the bottom.
  const eraOrder = useMemo(() => {
    if (!history) return [];
    const res = Object.keys(history).sort((a, b) => {
      return Number(a) > Number(b) ? -1 : 1;
    });
    return res;
  }, [history]);

  if (!history) return null;

  return (
    <div className={containerClassName}>
      {eraOrder.map((key) => {
        const plis = history[key] as PayorLineItemType[],
          eraId = plis?.[0]?.eraId; // All PLIs will have the same ERA id.
        if (!plis || plis.length === 0) return null;

        const remitAmountsTotal = plis
          .reduce((acc: Decimal, pli) => {
            const amt = createDecimal(pli?.remitAmount ?? 0);
            acc = acc.plus(amt);
            return acc;
          }, createDecimal(0))
          .toNumber();

        const billedAmountsTotal = plis
          .reduce((acc: Decimal, pli) => {
            const amt = createDecimal(pli?.billedAmount ?? 0);
            acc = acc.plus(amt);
            return acc;
          }, createDecimal(0))
          .toNumber();

        return (
          <div key={key} className="border-b border-gray-200">
            <HashCardDisplay
              square={true}
              header={
                <Link
                  to={`/billing/eras/${eraId}`}
                  target="_blank"
                  title="Opens this ERA in a new tab."
                  className="flex flex-row space-x-2 hover:text-primary-500"
                >
                  <div>ERA #{key}</div>
                  {isNaN(Number(key)) ? null : (
                    <div className="cursor-pointer pt-0.5">
                      <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                    </div>
                  )}
                </Link>
              }
              headerComponentRight={
                <div className="flex flex-row space-x-2">
                  <div>Reprocessed</div>{' '}
                  <TimestampDisplay
                    ts={(plis?.[0] as any).ts}
                    format={ChiroUpDayJsCommon.format.isoAt}
                  />
                </div>
              }
              headerClassName="overflow-hidden border-gray-400 flex flex-row bg-gray-100 text-gray-900 border border-gray-300 rounded-md px-4 py-2 font-semibold"
              bodyClassName="bg-gray-50 text-gray-800 overflow-hidden"
              components={{
                ...plis.reduce(
                  (acc, pli, idx) => {
                    const mods = [];
                    let mod =
                      pli.submittedModifier1 || pli.adjudicatedModifier1;
                    if (mod) mods.push(mod);
                    mod = pli.submittedModifier2 || pli.adjudicatedModifier2;
                    if (mod) mods.push(mod);
                    mod = pli.submittedModifier3 || pli.adjudicatedModifier3;
                    if (mod) mods.push(mod);
                    mod = pli.submittedModifier4 || pli.adjudicatedModifier4;
                    if (mod) mods.push(mod);
                    const modifiers = mods.join(', ');

                    acc[`${pli.payorId}.${pli.controlNumber}`] = (
                      <div
                        className={classNames(
                          ['bg-gray-50', 'bg-gray-200'][idx % 2],
                          'grid grid-cols-6 text-base',
                        )}
                      >
                        <div className="px-4 p-2 font-extrabold">
                          {pli.adjudicatedProcedureCode ||
                            pli.submittedProcedureCode}
                          <TrivialTooltip
                            type="info"
                            text={pli.controlNumber}
                            tipClassName="w-64"
                          />
                        </div>
                        <div className="col-span-2 py-2 pl-2">
                          {
                            originalDiagnosesByCode[
                              pli?.submittedProcedureCode ?? '_'
                            ]
                          }
                        </div>
                        <div className="py-2">{modifiers}</div>
                        <div className="px-4 p-2 flex justify-end">
                          {pli.submittedUnits}
                        </div>
                        <div className="px-4 p-2 flex justify-end">
                          <Currency value={pli.billedAmount} />
                        </div>

                        {pli.remitDate && (
                          <div className="ml-4 col-span-6 mb-2 px-4 border-dotted border-t-2 border-gray-400 w-full">
                            <div className="flex flex-row space-x-2 mt-2 h-6">
                              <div className="after:content-[':']">Payment</div>
                              <div className="flex font-bold">
                                <Currency value={pli.remitAmount} />
                              </div>
                              <div className="flex whitespace-nowrap">
                                (
                                <TimestampDisplay
                                  ts={pli.remitDate}
                                  itemTz={itemTz}
                                  format={ChiroUpDayJsCommon.format.date}
                                  userTz={userTz}
                                />
                                )
                              </div>
                            </div>
                            {pli?.adjustments?.length ? (
                              <div className="after:content-[':']">
                                Adjustments
                              </div>
                            ) : null}
                            <div className="ml-4">
                              {pli.adjustments?.map(
                                (adj: any, index: number) => (
                                  <div
                                    key={`pli-${pli.id}-adj-${adj.groupCode}-${index}`}
                                  >
                                    <div
                                      key={adj.groupCode}
                                      className="flex flex-row text-sm"
                                    >
                                      <div className="flex flex-col italic">
                                        <div>
                                          &bull;&nbsp;{adj.groupDescription}
                                        </div>
                                      </div>
                                    </div>

                                    {adj.items.map(
                                      (item: any, itemIndex: number) => (
                                        <div key={`adj-${itemIndex}`}>
                                          <div
                                            key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}`}
                                            className="ml-4 flex flex-row space-x-2 w-full mb-2"
                                          >
                                            <div className="flex">
                                              &#8226;&nbsp;
                                              <TrimAndTooltip
                                                text={item.description}
                                              />
                                            </div>
                                            <div className="flex font-bold">
                                              {formatCurrency(item.amount)}
                                            </div>
                                          </div>

                                          <div className="w-full text-xs">
                                            {item.remarks &&
                                              item.remarks.length > 0 && (
                                                <div className="flex flex-row">
                                                  <div className="flex flex-col">
                                                    <div className="text-gray-500">
                                                      {item.remarks.map(
                                                        (
                                                          remark: any,
                                                          remarkIndex: number,
                                                        ) => (
                                                          <div
                                                            key={`pli-${pli.id}-adj-${adj.groupCode}-item-${itemIndex}-remark-${remark.code}-${remarkIndex}`}
                                                            className="flex flex-row mt-2"
                                                          >
                                                            <div className="flex flex-col">
                                                              <div className="text-gray-500">
                                                                {
                                                                  remark.description
                                                                }
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ),
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      ),
                                    )}
                                  </div>
                                ),
                              )}
                            </div>
                            {!pli.adjustments ||
                              (pli.adjustments?.length === 0 && (
                                <div className="flex w-full">
                                  <div className="flex justify-between text-gray-500 w-full">
                                    <div>No adjustments were made.</div>
                                  </div>
                                </div>
                              ))}
                            {(pli?.remarks?.length ?? 0) !== 0 && (
                              <>
                                <div className="mt-6">Additional Remarks:</div>
                                <ol className="list-decimal list-outside pl-4 mb-4">
                                  {pli.remarks?.map(
                                    (remark: any, remarkIndex: number) => (
                                      <li
                                        key={`pli-${pli.id}-remark-${remark.code}-${remarkIndex}`}
                                        className="text-gray-500 ml-4"
                                      >
                                        {remark.description}
                                      </li>
                                    ),
                                  )}
                                </ol>
                              </>
                            )}

                            {(pli?.internalNotes?.length ?? 0) > 0 && (
                              <div className="mt-6">Internal Notes</div>
                            )}
                            {pli.internalNotes?.map(
                              (note: any, noteIndex: number) => (
                                <div
                                  key={`pli-${pli.id}-remark-${note.createdAt}-${noteIndex}`}
                                  className="flex flex-row mt-2"
                                >
                                  <div className="flex flex-col">
                                    <div className="text-gray-500">
                                      {note.message}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                          </div>
                        )}
                      </div>
                    );
                    return acc;
                  },
                  {
                    header: (
                      <div
                        className={classNames(
                          ClassNames.sectionClassName,
                          'grid grid-cols-6 bg-gray-300 text-black uppercase',
                        )}
                      >
                        <div className="py-2">Code</div>
                        <div className="py-2 col-span-2">
                          Diagnoses{' '}
                          <span className="normal-case">
                            <TrivialTooltip text="These are the current diagnoses. The submitted diagnoses are not available." />
                          </span>
                        </div>
                        <div className="py-2">Modifiers</div>
                        <div className="py-2 flex justify-end">Units</div>
                        <div className="py-2 flex justify-end">Billed</div>
                      </div>
                    ),
                  } as STRING_ANY_HASH,
                ),
                Balance: (
                  <section className="bg-gray-100 text-gray-900 uppercase h-10 py-2">
                    <div className={ClassNames.kv.blockRow}>
                      <div
                        className={classNames(
                          ClassNames.kv.block,
                          'text-primary-500',
                        )}
                      >
                        <div className={`font-semibold`}>Total paid</div>
                        <div className={`font-semibold`}>
                          <Currency value={remitAmountsTotal} />
                        </div>
                      </div>
                      <div className={ClassNames.kv.block}>
                        <div className={`font-semibold`}>Total billed</div>
                        <div className={`font-semibold`}>
                          <Currency value={billedAmountsTotal} />
                        </div>
                      </div>
                    </div>
                  </section>
                ),
              }}
              containerClassName="w-full mt-8"
              noHover={true}
              skipNullValues={true}
            />
          </div>
        );
      })}
    </div>
  );
};

type AdjustBilledServicesProps = {
  hash?: STRING_ANY_HASH | null | undefined;
  valueClassNames?: STRING_STRING_HASH | null | undefined;
  labelClassNames?: STRING_STRING_HASH | null | undefined;
  header?: React.ReactNode | null;
  containerClassName?: string;
  trace?: boolean;
  headerComponentRight?: React.ReactNode;
  disabled?: boolean;
  skipNullValues?: boolean;
  components?: { [key: string]: React.ReactNode };
  tips?: { [key: string]: React.ReactNode };
  noHover?: boolean;
  addClassName?: string;
  grid?: { cols: number; labelSpan: number; valueSpan: number };
  square?: boolean;
  border?: string;
  topBorder?: string;
  headerClassName?: string;
  bodyClassName?: string;
  hashCardDisplayColors?: string[];
};

export const AdjustBilledServices: React.FC<AdjustBilledServicesProps> = ({
  hash,
  header = null,
  valueClassNames = {},
  labelClassNames = {},
  containerClassName = 'w-full',
  trace = false,
  headerComponentRight = null,
  disabled = false,
  skipNullValues = false,
  components = {},
  tips = {},
  noHover = false,
  addClassName = '',
  grid = { cols: 2, labelSpan: 1, valueSpan: 1 },
  square = false,
  border = 'border-gray-400',
  topBorder = '',
  headerClassName = 'overflow-hidden border-b-2 flex flex-row bg-gray-100 text-gray-800 px-4 py-2 font-bold',
  bodyClassName = 'bg-white text-gray-800 overflow-hidden',
  hashCardDisplayColors,
}) => {
  hash = hash || components;
  const keyPrefix = useId();
  const hashKeys = useMemo(() => (hash ? Object.keys(hash) : []), [hash]);

  /**
   * What the heck is this? For aesthetic reason, we want the bottom of the
   * LAST row in the card to be rounded. The problem is, if you don't show
   * a value due to the skipNullValues flag, then the detection of when the
   * last row is being rendered is off. So, we create a delta of null values
   * to make sure we round the correct bottom...so to speak.
   */
  const keyDelta = useMemo(() => {
    let res = 0;
    if (hash && skipNullValues) {
      res = Object.keys(hash).reduce((acc, key) => {
        if (!hasProperty(components, key)) return acc;
        return acc + 1;
      }, 0);
    }
    return -1 * res;
  }, [hash, skipNullValues, components]);

  const colors = useMemo(() => {
    if (hashCardDisplayColors) return hashCardDisplayColors;
    return HashCardDisplayColors;
  }, [hashCardDisplayColors]);

  if (!hash) return null;

  if (!hash) return null;

  let visibleLines = 0;

  return (
    <div className={containerClassName}>
      <div
        className={classNames(
          'flex flex-col text-sm border ',
          border,
          topBorder,
          square ? '' : 'rounded-md',
          disabled
            ? ''
            : noHover
              ? ''
              : 'hover:ring-2 hover:ring-primary-500 shadow-xl hover:shadow-sm',
          addClassName,
        )}
      >
        {header ? (
          <div
            className={classNames(
              square ? '' : 'rounded-md rounded-b-none',
              headerClassName,
            )}
          >
            <div className="flex grow">{header}</div>
            {headerComponentRight}
          </div>
        ) : null}
        <div className={classNames(square ? '' : 'rounded-md', bodyClassName)}>
          {Object.entries(hash).map(([key, value], idx) => {
            if (value === null && skipNullValues) {
              return <div key={`${keyPrefix}-${idx}`}></div>;
            }
            /**
             * It is possible for a component value to be null, so this allows
             * for that use case.
             */
            if (hasProperty(components, key)) {
              return <div key={`${keyPrefix}-${idx}`}>{components[key]}</div>;
            }
            visibleLines++;
            const tip = tips[key] ?? null;
            return (
              <div
                className={classNames(
                  `grid grid-cols-${grid.cols}`,
                  colors[visibleLines % 2],
                  idx === hashKeys.length - 1 + keyDelta
                    ? square
                      ? ''
                      : 'rounded-b-md'
                    : '',
                )}
                key={`${keyPrefix}-${idx}`}
              >
                <div
                  className={classNames(
                    labelClassNames?.[key] ??
                      labelClassNames?._ ??
                      'px-4 py-1 text-sm flex flex-row',
                    grid.labelSpan !== 1 ? `col-span-${grid.labelSpan}` : '',
                  )}
                >
                  <div className="italic">{key}</div>
                  <div>
                    {tip ? (
                      typeof tip === 'string' ? (
                        <TrivialTooltip
                          text={tip}
                          tipClassName="font-normal w-72"
                        />
                      ) : (
                        <TrivialTooltip component={tip} />
                      )
                    ) : null}
                  </div>
                </div>
                <div
                  className={
                    valueClassNames?.[key] ??
                    valueClassNames?._default ??
                    `px-4 py-1 text-sm col-span-${grid.valueSpan}`
                  }
                >
                  {typeof value === 'object'
                    ? Array.isArray(value)
                      ? value.map((v, key) => (
                          <div key={`${keyPrefix}-${key}`}>{v}</div>
                        ))
                      : React.isValidElement(value)
                        ? value
                        : ChiroUpJSON.stringify(value)
                    : typeof value === 'boolean'
                      ? value
                        ? 'true'
                        : 'false'
                      : value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {trace ? (
        <pre>{ChiroUpJSON.pretty({ hash, header, keyDelta })}</pre>
      ) : null}
    </div>
  );
};

const ActionDescriptions: STRING_ANY_HASH = {
  'apply-adj': (obj: AdjustmentActionType) => {
    return (
      [
        `Apply adjudication info from ERA #${obj?.row?.eraId}`,
        `to the claim item #${obj?.row?.id}`,
      ].join(' ') + '.'
    );
  },
  'status-to-post': (obj: AdjustmentActionType) => {
    return (
      [
        `Set the status of claim item #${obj?.pk} on ERA #${obj?.row?.eraId}`,
        `to "Posted" without performing other actions on the response`,
      ].join(' ') + '.'
    );
  },
  'remove-item': (obj: AdjustmentActionType) => {
    const amount = createDecimal(obj?.row?.amount ?? 0)
        .times(obj?.row?.units ?? 1)
        .toNumber()
        .toFixed(2),
      units = obj?.row?.units ?? 1;
    return (
      [
        `Remove ${(obj?.row?.type ?? '- bad -').toLowerCase()} item #${
          (obj?.row?.ordinalId ?? 0) + 1
        }`,
        `"${obj?.row?.description}"`,
        `for`,
        ChiroUpBaseCommon.format.asMoney(Number(amount) ?? 0),
        `(${units} unit${
          units === 1 ? '' : 's'
        } @ ${ChiroUpBaseCommon.format.asMoney(
          Number(obj?.row?.amount ?? 0),
        )})`.replace(/ /g, '\u00A0'),
      ].join(' ') + '.'
    );
  },
  'era-to-cre': (obj: AdjustmentActionType) => {
    return (
      [
        `Apply line-item #${(obj?.row?.ordinalId ?? 0) + 1} for service`,
        obj?.row?.adjudicatedServiceCode,
        `from ERA #${obj?.row?.eraId}`,
        `to the claim id #${obj?.row?.claimResponseEraId}`,
      ].join(' ') + '.'
    );
  },
  'set-post-status': (obj: AdjustmentActionType) => {
    return (
      [
        `Set the status of ERA #${obj?.row?.eraId}`,
        `response #${obj?.row?.id} to posted`,
      ].join(' ') + '.'
    );
  },
  user: (obj: AdjustmentActionType) => {
    if (obj.type === 'payment') {
      return (
        [
          `Before submitting, double-check if any payment line items`,
          `from ERA #${obj?.row?.eraId}`,
          `for`,
          ChiroUpBaseCommon.format.asMoney(obj?.row?.amount ?? 0),
          `need to be rolled back. Add them as actions if necessary`,
        ].join(' ') + '.'
      );
    } else if (obj.type === 'multi-post') {
      return [obj?.row?.message].join(' ') + '.';
    } else if (obj.type === 'era-to-cre') {
      return (
        [
          `Please verify that the service ${obj?.row?.adjudicatedServiceCode}`,
          `should be applied to item #${obj?.row?.claimResponseEraId} from`,
          `ERA #${obj?.row?.eraId}`,
          'multiple times',
        ].join(' ') + '.'
      );
    } else if (obj.type === 'remove-item') {
      return (
        [
          `Note: Removing ${(
            obj?.row?.type ?? '- bad -'
          ).toLowerCase()} item #${(obj?.row?.ordinalId ?? 0) + 1}`,
          `"${obj?.row?.description}"`,
          `for ${ChiroUpBaseCommon.format.asMoney(obj?.row?.total ?? 0)}`,
          'DOES NOT affect any payments. It just removes the line items',
          'from this transaction/purchase.',
          'Use with extreme caution',
        ].join(' ') + '.'
      );
    } else if (obj.type === 'reconsider-reverse') {
      const code = obj?.row?.adjudicatedServiceCode,
        eraId = obj?.row?.eraId;
      return (
        [
          `The action reversing the posting of ${code} may not be necessary.`,
          `Posting ERA #${eraId} will replace`,
        ].join(' ') + '.'
      );
    } else if (obj.type === 'apply-era-to-pli') {
      const itemId = obj?.row?.id;
      return (
        [
          `If there is a history below for applying line-item #${itemId}`,
          'or it is the current line items,',
          'do not perform the "apply" action below as it has already been done.',
          'Applying it again will result in additional history entries',
        ].join(' ') + '.'
      );
    }
    return ChiroUpJSON.pretty(obj);
  },
  unpost: (obj: AdjustmentActionType) => {
    const billedAmount = ChiroUpBaseCommon.format.asMoney(
        obj?.row?.billedAmount ?? 0,
      ),
      remitAmount = ChiroUpBaseCommon.format.asMoney(
        obj?.row?.remitAmount ?? 0,
      ),
      adjustmentTotal = ChiroUpBaseCommon.format.asMoney(
        obj?.row?.adjustmentTotal ?? 0,
      ),
      eraId = obj?.row?.eraId;
    return (
      [
        `Reverse posting`,
        `of ${remitAmount}`,
        `(${billedAmount} - ${adjustmentTotal})`,
        eraId ? `for ERA #${obj?.row?.eraId}` : 'for',
        `code ${obj?.row?.adjudicatedProcedureCode}`.replace(/ /g, '\u00A0'),
      ].join(' ') + '.'
    );
  },
  post: (obj: AdjustmentActionType) => {
    let [, ord] = (obj?.pk ?? '').split('.');
    let code = obj?.row?.adjudicatedServiceCode;

    if (!code) {
      code = `all services`;
      ord = String(Number(ord) - 1);
    } else {
      code = `service ${code}`;
    }

    return (
      [
        'Post',
        `ERA #${obj?.row?.eraId}`,
        'line-item',
        `#${Number(ord) + 1}`,
        'for',
        code,
      ].join(' ') + '.'
    );
  },
  apply: (obj: AdjustmentActionType) => {
    let [, ord] = (obj?.pk ?? '').split('.');
    let code = obj?.row?.adjudicatedServiceCode;

    if (!code) {
      code = `all services`;
      ord = String(Number(ord) - 1);
    } else {
      code = `service ${code}`;
    }

    return (
      [
        'Apply',
        `ERA #${obj?.row?.eraId}`,
        'line-item',
        `#${Number(ord) + 1}`,
        'for',
        code,
      ].join(' ') + '.'
    );
  },
  rollback: (obj: AdjustmentActionType) => {
    if (obj?.type === 'initial') {
      return (
        [
          `Rollback initial payment amount by`,
          `${ChiroUpBaseCommon.format.asMoney(obj?.row?.total ?? 0)}`,
        ].join(' ') + '.'
      );
    }
    return (
      [
        `Reverse the ${(obj?.row?.type ?? '').toLocaleLowerCase()}`,
        obj?.row?.eraId
          ? `payment from ERA #${obj?.row?.eraId}`
          : `patient payment`,
        'for',
        ChiroUpBaseCommon.format.asMoney(obj?.row?.amount ?? 0),
      ].join(' ') + '.'
    );
  },
};

type ActionCardProps = {
  row: any;
  ord: number;
  color?: string;
  callback: (args: AdjustmentActionType) => void;
  iconClasses?: string;
  userIconClasses?: string;
};

const ActionCard: React.FC<ActionCardProps> = ({
  row,
  color = 'bg-white',
  ord = 0,
  callback,
  iconClasses = 'w-5 h-5 mr-0.5',
  userIconClasses = 'w-6 h-6',
}) => {
  const icon = useMemo(() => {
    if (row.action === 'user') {
      return <CheckCircleIcon className={userIconClasses} />;
    }
    return <TrashIcon className={iconClasses} />;
  }, [iconClasses, row.action, userIconClasses]);

  const fn = useMemo(() => {
    return (
      ActionDescriptions[row.action] ||
      ((row: any) => {
        return ChiroUpJSON.pretty(row);
      })
    );
  }, [row]);

  const hasViableAction = useMemo(() => {
    return !(row.action === 'rollback' && row.type === 'initial');
  }, [row]);

  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden rounded-none"
        components={{
          action: (
            <div className="flex flex-row space-x-2 px-4 py-2 w-full">
              <div className={ClassNames.circle}>{ord + 1}</div>
              <div>{fn(row)}</div>
              {hasViableAction ? (
                <div
                  className={classNames(ClassNames.trash, 'cursor-pointer')}
                  onClick={() => {
                    callback({
                      action: 'trash',
                      type: 'item',
                      pk: ord.toString(),
                      row,
                      singleton: true,
                    } as any);
                  }}
                >
                  {icon}
                </div>
              ) : null}
            </div>
          ),
          // type: <div>{row.type}</div>,
          // description: <div>{ActionDescriptions[row.action](row)}</div>,
          // row: <pre>{ChiroUpJSON.pretty(row)}</pre>,
        }}
      />
    </div>
  );
};

type CurrentCardProps = {
  rows: PayorLineItemType[];
  invoice: IntegrationInvoice;
  actionsByKey: STRING_NUMBER_HASH;
  callback: (args: AdjustmentActionType) => void;
  context: STRING_ANY_HASH;
};

const CurrentCard: React.FC<CurrentCardProps> = ({
  rows,
  invoice,
  actionsByKey,
  callback,
  context,
}) => {
  const eraId = useMemo(() => {
    return rows?.[0]?.eraId;
  }, [rows]);

  const myAction: AdjustmentActionType = useMemo(() => {
    const myActionPrefix = {
      action: 'unpost',
      type: 'item',
    };

    return {
      ...myActionPrefix,
      pk: String(eraId ?? 'current'),
      row: {},
    };
  }, [eraId]);

  const myActionSupported = useMemo(() => {
    return (
      SupportedInvoiceActions[[myAction.action, myAction.type].join('.')] ??
      false
    );
  }, [myAction]);

  const myKey = useMemo(() => {
    return [myAction.action, myAction.type, myAction.pk].join('.');
  }, [myAction]);

  const isNotAdjudicated = useMemo(() => {
    return (rows?.[0]?.adjudicatedProcedureCode ?? ' ') === ' ';
  }, [rows]);

  const handledService = useMemo(() => {
    const handled: STRING_BOOLEAN_HASH = {},
      items = context?.adjustmentActions ?? [];
    items.forEach((item: AdjustmentActionType, idx: number) => {
      if (item && item?.row && item?.row?.serviceItems) {
        item.row.serviceItems.forEach((svc: any) => {
          const code = svc?.adjudicatedServiceCode ?? svc?.submittedServiceCode,
            svcKey = ['post', 'cre', code].join('.');
          handled[svcKey] = true;
        });
      } else if (item && item?.row && item?.row?.adjudicatedServiceCode) {
        const code = item?.row?.adjudicatedServiceCode,
          svcKey = ['post', 'cre', code].join('.');
        handled[svcKey] = true;
      }
    });
    return handled;
  }, [context]);

  return (
    <div>
      <HashCardDisplay
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden border-t border-gray-400 rounded-none"
        components={{
          services: (
            <div className="px-4">
              <table className="w-full my-4">
                <thead>
                  <tr className="text-sx text-gray-400">
                    <th className={ClassNames.table.firstTh}>Service</th>
                    <th className={ClassNames.table.th}>Units</th>
                    <th className={ClassNames.table.th}>Billed</th>
                    <th className={ClassNames.table.th}>Paid</th>
                    <th className={ClassNames.table.th}>Adj's</th>
                    <th className={classNames(ClassNames.table.th)}>Balance</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((item: PayorLineItemType, idx: number) => {
                    const adjTotal = item?.adjustments?.reduce(
                      (topAcc: Decimal, adj: any) => {
                        const sum = adj.items.reduce(
                          (acc: Decimal, item: any) => {
                            return acc.add(createDecimal(item.amount ?? 0));
                          },
                          createDecimal(0),
                        );
                        return topAcc.add(sum);
                      },
                      createDecimal(0),
                    );
                    const rowKey = [myKey, item.id].join('.');
                    const serviceKey = [
                      'post',
                      'cre',
                      item.adjudicatedProcedureCode ??
                        item?.submittedProcedureCode,
                    ].join('.');

                    return (
                      <tr key={`pli-${idx}`}>
                        <td className={classNames(ClassNames.table.firstCell)}>
                          {item.adjudicatedProcedureCode ??
                            item?.submittedProcedureCode}
                          {/* {serviceKey} */}
                        </td>
                        <td className={ClassNames.table.cell}>
                          {item.adjudicatedUnits ?? item?.submittedUnits}
                        </td>
                        <td className={ClassNames.table.cell}>
                          <Currency value={item.billedAmount} />
                        </td>
                        <td className={ClassNames.table.cell}>
                          <Currency value={item.remitAmount} />
                        </td>
                        <td className={ClassNames.table.cell}>
                          <Currency value={adjTotal?.toNumber()} />
                        </td>
                        <td className={classNames(ClassNames.table.cell)}>
                          <Currency
                            value={createDecimal(item.billedAmount ?? 0)
                              .minus(item.remitAmount ?? 0)
                              .minus(adjTotal ?? 0)
                              .toNumber()}
                          />
                        </td>
                        {handledService[serviceKey] ? (
                          <td
                            className={classNames(
                              ClassNames.td.xySize,
                              'text-right',
                            )}
                          >
                            <IconButton
                              className="flex justify-end h-5 w-5 text-black"
                              icon={<CheckIcon className="w-5 h-5" />}
                              tooltip="The adjudication of this service will be replaced by the selected actions."
                              tooltipClassName="w-64 text-left"
                              disabled={true}
                            />
                          </td>
                        ) : (
                          <td className={ClassNames.callback}>
                            <div className="flex justify-end">
                              {actionsByKey[rowKey] ? (
                                <div
                                  className={ClassNames.circle}
                                  onClick={() => {
                                    const ord = actionsByKey[rowKey] - 1;
                                    callback({
                                      action: 'trash',
                                      type: 'item',
                                      pk: ord.toString(),
                                      row: {},
                                    });
                                  }}
                                >
                                  {actionsByKey[rowKey]}
                                </div>
                              ) : isNotAdjudicated ? (
                                <TrivialTooltip
                                  text="This service has not been adjudicated."
                                  type="info"
                                />
                              ) : myActionSupported ? (
                                <IconButton
                                  className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                                  icon={
                                    <DocumentMinusIcon className="w-5 h-5" />
                                  }
                                  tooltip="Unpost this ERA line item."
                                  onClick={() => {
                                    callback({
                                      ...myAction,
                                      row: { ...item, eraId, pk: rowKey },
                                      pk: [
                                        item.eraId ?? 'current',
                                        item.id,
                                      ].join('.'),
                                    });
                                  }}
                                />
                              ) : null}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ),
        }}
      />
    </div>
  );
};
type DisplayBooleanProps = {
  value: boolean;
};

const DisplayBoolean: React.FC<DisplayBooleanProps> = ({ value }) => {
  return (
    <div className="flex justify-end">
      {value ? <CheckIcon className="h-5 w-54 text-black" /> : null}
    </div>
  );
};

type EraCardProps = {
  context: any;
  actionsByKey: STRING_NUMBER_HASH;
  callback: (args: AdjustmentActionType) => void;
};

const EraCard: React.FC<EraCardProps> = ({
  context,
  actionsByKey,
  callback,
}) => {
  const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null);
  const myAction: AdjustmentActionType = {
    action: 'era-to-cre',
    type: 'era',
    pk: 'era',
    row: {},
  };
  const myKey = [myAction.action, myAction.type].join('.');
  const myKeyActionSupported = SupportedInvoiceActions[myKey];

  const showEras = useMemo(() => {
    const keys = Object.keys(context?.responsesWithoutServices ?? {});
    if (keys.length) {
      return keys.map((key) => {
        return context?.erasById?.[key] || {};
      });
    }
    return [];
  }, [context]);

  const claimIds = useMemo(() => {
    return ['-1'].concat(Object.keys(context?.creIdsWithoutServices ?? {}));
  }, [context]);

  if (!showEras.length) return null;
  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden border-gray-400 rounded-none"
        components={{
          sep: (
            <div
              className={classNames(
                ClassNames.adjustmentSeps,
                'border-t border-gray-400 rounded-none',
              )}
            >
              ERA{showEras?.length === 1 ? '' : 's'} for Claim Response
              {context.countOfResponsesWithoutServices === 1 ? '' : 's'} Without
              Services
            </div>
          ),
          // debug: <div>{ChiroUpJSON.pretty(showEras)}</div>,
          eras: showEras.map((era: AdjustmentEraType, idx: number) => {
            return (
              <div key={`era-${idx}`} className="px-4 border-t pt-4">
                <div className="grid grid-cols-6">
                  <div className={ClassNames.cre.firstIdentLabel}>ERA #</div>
                  <div
                    className={classNames(
                      ClassNames.cre.identLabel,
                      'col-span-5',
                    )}
                  >
                    Apply to Claim
                  </div>
                  <div>{era.id}</div>
                  <div
                    className={classNames(
                      ClassNames.cre.lastValue,
                      'col-span-5 flex flex-row space-x-2',
                    )}
                  >
                    {claimIds.length > 0
                      ? claimIds.map((id, idx) => {
                          if (id === '-1') {
                            return selectedClaimId === null ? null : (
                              <div
                                key={`era-card-id-${idx + 1}`}
                                onClick={() => {
                                  setSelectedClaimId(null);
                                }}
                              >
                                <ArrowPathIcon className="h-4 w-4 text-primary-400 hover:text-primary-600 cursor-pointer" />
                              </div>
                            );
                          }
                          return (
                            <div
                              key={`era-card-id-${idx + 1}`}
                              className={classNames(
                                selectedClaimId && id !== selectedClaimId
                                  ? 'text-gray-400 cursor-not-allowed'
                                  : 'text-primary-400',
                                selectedClaimId === id
                                  ? 'font-bold text-primary-600'
                                  : 'cursor-pointer',
                              )}
                              onClick={() => {
                                if (selectedClaimId) return;
                                setSelectedClaimId(id);
                              }}
                            >
                              {id}
                            </div>
                          );
                        })
                      : 'none'}
                  </div>
                </div>
                <table className="w-full my-4">
                  <thead>
                    <tr className="text-sx text-gray-400">
                      <th className={ClassNames.table.firstTh}>Service</th>
                      <th className={ClassNames.table.th}>Billed</th>
                      <th className={ClassNames.table.th}>Paid</th>
                      <th className={ClassNames.table.th}>Adj's</th>
                      <th className={classNames(ClassNames.table.th)}>
                        Balance
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {era?.services?.map(
                      (item: ClaimServiceItemType, ord: number) => {
                        const adjTotal = item?.adjustments?.reduce(
                          (topAcc: Decimal, adj: any) => {
                            const sum = adj.items.reduce(
                              (acc: Decimal, item: any) => {
                                return acc.add(createDecimal(item.amount ?? 0));
                              },
                              createDecimal(0),
                            );
                            return topAcc.add(sum);
                          },
                          createDecimal(0),
                        );
                        const rowKey = [
                          myKey,
                          era?.id,
                          selectedClaimId,
                          ord,
                        ].join('.');

                        return (
                          <tr key={`cre-service-${idx}-${ord}`}>
                            <td
                              className={classNames(ClassNames.table.firstCell)}
                            >
                              {item.adjudicatedServiceCode}
                            </td>

                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.billedAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={item.remitAmount}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.table.cell}>
                              <Currency
                                value={adjTotal?.toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={classNames(ClassNames.table.cell)}>
                              <Currency
                                value={createDecimal(item.billedAmount)
                                  .minus(item.remitAmount ?? 0)
                                  .minus(adjTotal ?? 0)
                                  .toNumber()}
                                negativeClassName="text-red-500"
                              />
                            </td>
                            <td className={ClassNames.callback}>
                              <div className="flex w-full justify-end">
                                {actionsByKey[rowKey] ? (
                                  <div className={ClassNames.circle}>
                                    {actionsByKey[rowKey]}
                                  </div>
                                ) : selectedClaimId ? (
                                  myKeyActionSupported ? (
                                    <IconButton
                                      className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                                      icon={
                                        <DocumentPlusIcon className="w-5 h-5" />
                                      }
                                      tooltip="Post this ERA line item to the claim."
                                      onClick={() => {
                                        callback({
                                          ...myAction,
                                          row: {
                                            ...item,
                                            claimResponseEraId: selectedClaimId,
                                            eraId: era?.id,
                                            ordinalId: ord,
                                          },
                                          pk: [
                                            era?.id,
                                            selectedClaimId,
                                            ord,
                                          ].join('.'),
                                        });
                                      }}
                                    />
                                  ) : null
                                ) : (
                                  <div className="w-5">&nbsp;</div>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              </div>
            );
          }),
        }}
      />
    </div>
  );
};

type TransactionItemsCardProps = {
  context: any;
  actionsByKey: STRING_NUMBER_HASH;
  callback: (args: AdjustmentActionType) => void;
};

const TransactionItemsCard: React.FC<TransactionItemsCardProps> = ({
  context,
  actionsByKey,
  callback,
}) => {
  const myAction: AdjustmentActionType = {
    action: 'remove-item',
    type: 'transaction',
    pk: '',
    row: {},
  };
  const myKey = [myAction.action, myAction.type].join('.');
  const myActionSupported = SupportedInvoiceActions[myKey];

  const showItems = useMemo(() => {
    return (context?.adjustItems ?? []).filter(
      (i: PatientTransactionItemType) => !isaServiceItem(i),
    );
  }, [context]);

  if (!showItems.length) return null;
  return (
    <div>
      <HashCardDisplay
        // hash={row}
        noHover={true}
        border=""
        containerClassName={`w-full`}
        bodyClassName="text-gray-800 overflow-hidden border-gray-400 rounded-none"
        components={{
          sep: (
            <div
              className={classNames(
                ClassNames.adjustmentSeps,
                'border-t border-gray-400 rounded-none',
              )}
            >
              Non-service Transaction Item{showItems?.length === 1 ? '' : 's'}
            </div>
          ),
          // debug: <div>{ChiroUpJSON.pretty(showItems)}</div>,
          services: (
            <div className="px-4 border-t border-gray-400">
              <table className="w-full my-4">
                <thead>
                  <tr className="text-sx text-gray-400">
                    <th className={ClassNames.table.firstTh}>&nbsp;</th>
                    <th className={ClassNames.table.firstTh}>Description</th>
                    <th className={ClassNames.table.th}>Units</th>
                    <th className={ClassNames.table.th}>Each</th>
                    <th className={ClassNames.table.th}>Amount</th>
                    <th className="w-2">&nbsp;</th>
                    {myActionSupported ? <th className="w-4">&nbsp;</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {showItems?.map(
                    (item: PatientTransactionItemType, idx: number) => {
                      const rowKey = [myKey, item?.id].join('.');
                      return (
                        <tr key={`cre-service-${idx}`}>
                          <td
                            className={classNames(ClassNames.table.firstCell)}
                          >
                            {item?.type === 'Debit'
                              ? '-'
                              : item?.type === 'Credit'
                                ? '+'
                                : '-'}
                          </td>
                          <td className={ClassNames.table.firstCell}>
                            {item.description ?? '-'}
                          </td>
                          <td className={ClassNames.table.cell}>
                            {item.units ?? '-'}
                          </td>
                          <td className={ClassNames.table.cell}>
                            <Currency
                              value={item?.amount ?? 0}
                              negativeClassName="text-red-500"
                            />
                          </td>
                          <td
                            className={classNames(
                              ClassNames.table.cell,
                              'text-right',
                            )}
                          >
                            <Currency
                              value={createDecimal(item?.amount ?? 0)
                                .times(item?.units ?? 1)
                                .toNumber()}
                              negativeClassName="text-red-500"
                            />
                          </td>
                          <td className="w-2">
                            <TrivialTooltip
                              text={`Posted ${dayjs(item?.createdAt).format(
                                ChiroUpDayJsCommon.format.isoAt,
                              )}`}
                              type="info"
                            />
                          </td>
                          {myActionSupported ? (
                            <td className={classNames(ClassNames.callback)}>
                              <div className="flex justify-end">
                                {actionsByKey[rowKey] ? (
                                  <div
                                    className={ClassNames.circle}
                                    onClick={() => {
                                      callback({
                                        action: 'trash',
                                        type: 'item',
                                        pk: String(actionsByKey[rowKey] - 1),
                                        row: item,
                                      });
                                    }}
                                  >
                                    {actionsByKey[rowKey]}
                                  </div>
                                ) : (
                                  <IconButton
                                    className="h-5 w-5 text-primary-400 hover:text-primary-600 cursor-pointer"
                                    icon={<TrashIcon className="w-5 h-5" />}
                                    tooltip="Delete this line item."
                                    onClick={() => {
                                      callback({
                                        ...myAction,
                                        row: {
                                          ...item,
                                          ordinalId: idx,
                                        },
                                        pk: String(item?.id),
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                          ) : null}
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default CommonInvoiceModal;
