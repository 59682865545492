/**
 * @license Chiroup
 *
 * The basic idea is that this looks at the insurances and the service
 * codes on the transaction and goes and looks up the modifiers if it
 * thinks it needs to. Otherwise, it should do nothing and just pass
 * the transaction back.
 *
 * Use cases...
 *    This only covers service codes for the primary insurance.
 *    If no primary is found, nothing is done.
 *
 * Possible new "bug" stories...
 *
 *    If the primary is changed from one insurance to another,
 *    this just leaves the modifiers alone.
 *
 *    If the array of insurances goes away, it DOES NOT reset
 *    the modifieres. The user could have modified them and
 *    we do not track state transitions.
 *
 *    How AT is added later on. Medicare was primary. Now it is not,
 *    the AT needs to be removed probably.
 *
 *    PROBABLY MORE!!!
 */
import { STRING_ANY_HASH } from '@chiroup/core/constants/globals';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { getServiceCodes } from '@chiroup/core/functions/getServiceCodes';
import { AppointmentInsuranceType } from '@chiroup/core/types/Appointment.type';
import {
  isaServiceItem,
  notaServiceItem,
  PatientTransaction,
  PatientTransactionItemType,
} from '@chiroup/core/types/PatientTransaction.type';
import { MagicAction, MagicActionType } from './commonMagic';
import { TransactionMagiceResponseType } from './transactionMagic';

export const fnTransactionPayorModifiers = async (
  options: any,
  payload: {
    original: PatientTransaction;
    current: PatientTransaction;
    previous: PatientTransaction;
  },
): Promise<TransactionMagiceResponseType> => {
  const actions: MagicActionType[] = [];
  const { current: transaction } = payload;
  const trace = options?.trace ?? false;

  let touched = false;

  if (trace) {
    console.log({ fnTransactionPayorModifiers: payload, options });
  }

  if (!transaction) {
    actions.push({
      message: `No actions possible without a transaction.`,
      type: MagicAction.Error,
    });
  }

  const original = payload.original;

  const originalPrimaryInsurance = (original?.insurances ?? []).find(
    (i: Partial<AppointmentInsuranceType>) => i?.billingPriority === 1,
  ) as AppointmentInsuranceType;

  let primaryInsurance = (transaction?.insurances ?? []).find(
    (i: Partial<AppointmentInsuranceType>) => i?.billingPriority === 1,
  ) as AppointmentInsuranceType;

  let nextTransaction = ChiroUpJSON.clone(transaction);

  if (!primaryInsurance && transaction?.insurances?.length === 1) {
    primaryInsurance = transaction?.insurances[0] as AppointmentInsuranceType;
    nextTransaction.insurances[0].billingPriority = 1;
  }

  if (trace) {
    console.log({ primaryInsurance });
  }

  if (!primaryInsurance) {
    console.log(`No primary insurance found.`);
    return {
      actions,
      transaction,
      touched,
    };
  }

  if (originalPrimaryInsurance?.insuranceID === primaryInsurance?.insuranceID) {
    console.log(`Primary insurance has not changed.`);
    return {
      actions,
      transaction,
      touched,
    };
  }

  if (actions.length) {
    return {
      actions,
      transaction,
      touched,
    };
  }

  const clinicId = transaction?.clinicId;

  /**
   * What codes do we need to get?
   */
  const usedCodes = (nextTransaction?.items ?? [])
    .map((i: PatientTransactionItemType) => i.code)
    .filter((i: string) => !!i)
    .sort();
  const previousCodes = (payload?.previous?.items ?? [])
    .map((i: PatientTransactionItemType) => (i.code ?? '') as string)
    .filter((i: string) => !!i)
    .sort();
  const usedPayorIds = (nextTransaction?.insurances ?? [])
    .map((i: AppointmentInsuranceType) => i.payorID)
    .filter((i: string) => !!i)
    .sort();
  const previousPayorIds = (payload?.previous?.insurances ?? [])
    .map((i: Partial<AppointmentInsuranceType>) => (i.payorID ?? '') as string)
    .filter((i: string) => !!i)
    .sort();

  let doSomethingFun =
    usedCodes.join('.') !== previousCodes.join('.') ||
    usedPayorIds.join('.') !== previousPayorIds.join('.');

  // Use case: If we don't or didn't have payors, we've got nothing to do.
  if (
    usedCodes.length &&
    usedPayorIds.length === 0 &&
    previousPayorIds.length === 0
  ) {
    doSomethingFun = false;
  }

  if (trace) {
    console.log({
      usedCodes,
      previousCodes,
      usedPayorIds,
      previousPayorIds,
      doSomethingFun,
    });
  }

  if (!doSomethingFun) {
    if (trace) console.log(`... not modifying anything ...`);
    return {
      actions,
      transaction,
      touched,
    };
  }

  const newServices = nextTransaction?.items?.filter(isaServiceItem) ?? [];

  try {
    const r = (await getServiceCodes({
      clinicId,
      codes: usedCodes,
      payors: true,
    })) as unknown as any;

    if (trace) {
      console.log({ codesFromDatabase: r });
    }

    // FYI: The REST response can be a single object (one service) OR an array of
    // objects.
    const rows =
      (r?.data && Array.isArray(r.data) ? r.data ?? [] : [r?.data]) ?? [];

    const dataByCode = rows.reduce((a: STRING_ANY_HASH, o: any) => {
      if (!o.code) return a;
      a[o.code] = o;
      return a;
    }, {} as STRING_ANY_HASH);
    if (trace) {
      console.log({ dataByCode });
    }

    for (let i: number = 0; i < newServices.length; i++) {
      const e = newServices[i];
      const codeToFind = e.code;
      if (!codeToFind || !dataByCode[codeToFind] || !e.insuranceBillable) {
        continue;
      }
      const primaryPayorData = dataByCode[codeToFind]?.payors?.find(
        (p: any) => p.payorIntPk === primaryInsurance?.payorID,
      );
      if (trace) {
        console.log({ codeToFind, primaryPayorData });
      }

      const oldModifiers = [
        e.modifier1,
        e.modifier2,
        e.modifier3,
        e.modifier4,
      ].filter((m) => !!m);

      if (!primaryPayorData) {
        continue;
      }

      const newModifiers = [
        primaryPayorData.modifier1,
        primaryPayorData.modifier2,
        primaryPayorData.modifier3,
        primaryPayorData.modifier4,
      ].filter((m) => !!m);

      if (oldModifiers.join('.') !== newModifiers.join('.')) {
        touched = true;
        // Modifiers updated for primary insurance Blue Cross Blue Shield and code 98940 to 25, WA.
        actions.push({
          message: `Modifiers for ${e.code} updated for primary insurance ${
            primaryPayorData.legalName
          } ${
            oldModifiers.length ? `from ${oldModifiers.join(', ')}` : ''
          } to ${newModifiers.join(', ')}.`,
          type: MagicAction.Updated,
        });

        e.modifier1 = primaryPayorData.modifier1;
        e.modifier2 = primaryPayorData.modifier2;
        e.modifier3 = primaryPayorData.modifier3;
        e.modifier4 = primaryPayorData.modifier4;
      }
    }

    nextTransaction = {
      ...nextTransaction,
      items: [
        ...(nextTransaction?.items?.filter(notaServiceItem) ?? []),
        ...newServices,
      ],
      services: newServices,
    };
  } catch (e) {
    console.error({ e });
    actions.push({
      message: `Error getting service code${
        usedCodes?.length === 1 ? '' : 's'
      }: ${usedCodes.join(', ')}.`,
      type: MagicAction.Error,
    });
  }

  if (trace) {
    console.log({ nextTransaction });
  }

  return {
    actions,
    transaction: nextTransaction,
    touched,
  };
};
