/**
 * Tips for those who might read documentation....
 *
 * 1) This is a mass of things calling other things across a lot
 *    of components. Some of the callbacks/functions that are
 *    passed call setLocalData which is in the main component.
 *    So, when a sub-component calls it, we often get a React
 *    error. The way we've gotten around that is to use a
 *    setTimeout() with a 0 delay. This puts the call at the
 *    end of the Javascript execution queue and allow React to
 *    shut the hell up.
 *
 * 2) A "reload" of the specific transactions displayed in a
 *    loop by this component, can be done by using the
 *    queryClient.setQueryData() function. The individual
 *    transaction components watch their data (useEffect) and
 *    when the data is at variance with the local row, the local
 *    row is updated from the queryClient data. It looks like
 *    magic so seemed worth a mention.
 */
import { FaceFrownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../../common/Modal';
import { useConsolidatedTransaction } from '../hooks/useConsolidatedTransaction';

import {
  AlertBlock,
  Button,
  ButtonColors,
  Checkbox,
  ConfirmModal,
  Loading,
  MakeBrowserWait,
  ManagedActionItems,
} from '@chiroup/components';
import {
  NUMBER_ANY_HASH,
  STRING_BOOLEAN_HASH,
  STRING_STRING_HASH,
} from '@chiroup/core/constants/globals';
import {
  ChiroUpDayJsCommon,
  ChiroUpTransactionCommon,
} from '@chiroup/core/constants/stringConstants';
import { ChiroUpJSON } from '@chiroup/core/functions/ChiroUpJSON';
import { createPatientName } from '@chiroup/core/functions/createPatientName';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import {
  ConsolidatedTransactionGetResponse,
  isaServiceItem,
  PatientOnTransactionType,
  PatientTransaction,
  PatientTransactionClass,
  ProviderOnTransactionType,
  TransactionPurchaseSubtypeEnum,
  TransactionTypeEnum,
} from '@chiroup/core/types/PatientTransaction.type';
import { UserRoles } from '@chiroup/core/types/User.type';
import { v4 } from 'uuid';
import { MeContext } from '../../../../../contexts/me.context';

import { NoteLocation } from '@chiroup/core/enums/NoteTypes.enum';
import { Insurance } from '@chiroup/core/types/PatientInsurance.type';
import classNames from 'classnames';
import {
  ManagedActionItem,
  ManagedActionItemsContext,
} from '../../../../../contexts/managedActionItems.context';
import { PatientContext } from '../../../../../contexts/patient.context';
import usePatientNotes from '../../../../../hooks/usePatientNotes';
import ButtonGroup, {
  ButtonGroupType,
} from '../../../../common/buttons/ButtonGroup';
import PatientAlertsContainer from '../../visits/notes/PatientAlertsContainer';
import {
  MergeTransactionsOptions,
  mergeTransactionsStandalone,
} from './functions/mergeTransactions.standalone';
import { LocationCard } from './LocationCard';
import Payment from './Payment';
import SingleTransaction from './SingleTransaction';
import { TabSummary } from './TabSummary';
import businessRulesService from '@chiroup/core/services/businessRules.service';
import {
  TransactionMagiceResponseType,
  TransactionMagicEvent,
} from '@chiroup/core/services/businessRules.service/transactionMagic';
import { MagicToastTitle } from '@chiroup/core/services/businessRules.service/commonMagic';
import {
  ToastContext,
  ToastTypes,
} from '../../../../../contexts/toast.context';

export const BASE_TAB = 'Detail';
export type Phase = { name: string; header: string; next: number };

export const phases: Phase[] = [
  {
    name: 'New',
    header: '',
    next: 2,
  },
  {
    name: 'Edit',
    header: '',
    next: 2,
  },
  {
    name: 'View',
    header: '',
    next: 1,
  },
];

export type InstanceComponents = {
  lockButton?: ReactNode;
  unMergeButton?: ReactNode;
};

export enum ActionsOnClick {
  Lock = 1,
  UnMerge = 2,
}

type LifeCycle = {
  isDirty?: () => boolean; // You tell me if it is dirty.
  isRestActive?: () => boolean; // You tell me if a REST call is active.
  save?: (cb?: () => void) => void; // You do the save and do what you like.
  cancel?: () => void; // The cancel is hit BEFORE ANYTHING else.
  close?: () => void; // Close after something.
};

export type EncounterContext = {
  assessmentCodes: any | null | undefined;
  clinicId: number;
  importDiagnosesAlways: boolean | null | undefined;
  horizontal?: boolean | null | undefined;
  omitTitle: boolean;
  persistDiagnosesList: boolean | null | undefined;
  patientId: string;
  plan: any | null | undefined;
  saveVisit: any | null | undefined;
  isSavingVisit?: boolean | null | undefined;
  setVisitForm: any | null | undefined;
  visitForm: any | null | undefined;
};

type Props = {
  encounterContext?: EncounterContext | null | undefined;
  initialContext?: PatientTransaction[] | null | undefined;
  id?: string | null;
  isOpen?: boolean;
  onCloseView?: ({
    data,
    dirty,
  }: {
    data?: PatientTransaction[] | null | undefined;
    dirty?: boolean;
  }) => void;
  mode?: 'modal' | 'route';
  setInitialContext?: React.Dispatch<
    React.SetStateAction<PatientTransaction[] | null | undefined>
  >;
  trace?: boolean;
  lifeCycle?: LifeCycle | null | undefined;
};

export type LockButtonStateType = {
  available: boolean;
  locked: boolean;
};

export enum RootCallbackEvent {
  UpdateSingleTransaction = 'update-single-transaction',
  UpdatePolicies = 'update-policies',
  AddMessageItem = 'add-message-item',
  SetActiveTabName = 'set-active-tab-name',
  PaymentOnSuccess = 'payment-on-success',
}

/**
 * Just to be clear
 * .... policies are Insurance[].
 * .... and transaction.insurances
 *      are AppointmentInsuranceType[].
 */
export type RootCallbackProps = {
  event: RootCallbackEvent;
  policies?: Insurance[];
  transaction?:
    | PatientTransaction
    | Partial<PatientTransaction>
    | null
    | undefined;
  item?: any;
  options?: any;
};

const ConsolidatedTransactionModal: React.FC<Props> = ({
  encounterContext,
  id,
  initialContext,
  isOpen = true,
  onCloseView = null,
  mode = 'modal',
  setInitialContext,
  trace = false,
  lifeCycle,
}) => {
  const { createToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { me, hasRole, selectedLocationFull } = useContext(MeContext);
  const { patient } = useContext(PatientContext);
  const [isMagicHappening, setIsMagicHappening] = useState<boolean>(false);
  const [saveAnywayModalOpen, setSaveAnywayModalOpen] =
    useState<boolean>(false);
  /**
   * u s e S t a t e
   */
  const [myActiveTabname, setMyActiveTabName] = useState<STRING_STRING_HASH>(
    {},
  );
  const [backgroundDirty, setBackgroundDirty] = useState<boolean | null>(false);
  const [lockedChanged, setLockedChanged] = useState<boolean | null>(false);
  const [mergeButtonTooltip, setMergeButtonTooltip] = useState<string>('');
  const [singleProviderOption, setSingleProviderOption] =
    useState<ProviderOnTransactionType | null>(null);
  const [countofProviders, setCountOfProviders] = useState<number>(0);
  const [confirmMergeOpen, setConfirmMergeOpen] = useState<boolean>(false);
  const [mergeOptions, setMergeOptions] = useState<MergeTransactionsOptions>({
    units: true,
    treatments: true,
    providers: {},
  });
  const [justMerged, setJustMerged] = useState<boolean>(false);
  const [localData, setLocalData] =
    useState<ConsolidatedTransactionGetResponse | null>(null);
  const [payAvailability, setPayAvailability] = useState<STRING_BOOLEAN_HASH>(
    {},
  );
  const [showPatientPaymentModal, setShowPatientPaymentModal] =
    useState<boolean>(false);
  const [currentPhase, setCurrentPhase] = useState<Phase>(phases[2]);
  // const [lifeCycleRestActive, setLifeCycleRestActive] = useState(false);

  // useEffect(() => {
  //   console.log('how many runs....');
  // }, []);

  const topOfPageRef = useRef<HTMLDivElement>(null);
  const [slotContext, setSlotContext] = useState<NUMBER_ANY_HASH>({
    [-1]: { ref: topOfPageRef, role: 'top' },
  });

  /**
   * u s e M e m o
   */
  const localItems = useMemo(() => {
    const lis = localData?.items ?? [];
    if (trace) console.log({ localItems: lis });
    return lis;
  }, [localData, trace]);

  const firstLocalItem = useMemo(() => {
    const flo = localItems?.[0] ?? {};
    if (trace) console.log({ firstLocalItem: flo });
    return flo;
  }, [localItems, trace]);

  const editingNewData = useMemo(() => {
    return firstLocalItem.billingKey && firstLocalItem.id === -1;
  }, [firstLocalItem.billingKey, firstLocalItem.id]);

  const isAdHoc = useMemo(() => {
      return firstLocalItem.type === TransactionTypeEnum.AdHoc;
    }, [firstLocalItem.type]),
    isNotAdHoc = useMemo(() => {
      return !isAdHoc;
    }, [isAdHoc]);

  const isEncounterMode = useMemo(() => {
      return !!encounterContext;
    }, [encounterContext]),
    isNotEncounterMode = useMemo(() => {
      return !isEncounterMode;
    }, [isEncounterMode]);

  const isEditingNew = useMemo(() => {
    if (trace) console.log({ ThisIsTheIsEditingNewId: id });
    if (id?.indexOf('new') === 0) return true;
    return false;
  }, [id, trace]);

  const isBillingStarted = useMemo(() => {
    return localItems.some((row) => row.isBillingStarted);
  }, [localItems]);

  const textPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-500' : 'text-primary-500';
    }, [isBillingStarted]),
    hoverTextPrimary = useMemo(() => {
      return isBillingStarted ? 'text-accent-600' : 'text-primary-600';
    }, [isBillingStarted]);

  const locations = useMemo(() => {
    return me?.selectedClinic?.locations ?? [];
  }, [me?.selectedClinic?.locations]);

  const userTz = useMemo(() => {
    return selectedLocationFull.timezone;
  }, [selectedLocationFull]);

  const itemId = useMemo(() => {
    let res: string | null | undefined = null;

    if (id) {
      if (!editingNewData) res = id;
      if (res?.indexOf('new') === 0) {
        res = null;
      }
    }
    if (mode === 'route' && location && location.pathname) {
      const pcs = location.pathname.split('/');
      res = pcs[pcs.length - 1];
    }
    return res;
  }, [editingNewData, id, location, mode]);

  /**
   * This _scrolls_ to the active transaction. That is,
   * the transaction who's key opened this modal.
   * The chosen one is also highlighted with a faded
   * primary color.
   */
  useEffect(() => {
    if (localItems?.length > 1) return; // No need to scroll if there is one.
    if (localItems?.[0]?.billingKey === itemId) return; // No need to scroll to the first one either.
    const uiActiveItem = localItems.find(
      (row: any) => row.billingKey === itemId,
    );
    if (uiActiveItem) {
      // console.log('....found element...');
      const element = document.getElementById(
        `slot-${uiActiveItem.billingKey}`,
      );
      if (element) {
        // console.log('....scrolling to element...');
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [itemId, localItems]);

  const childrenByType = useMemo(() => {
    return (
      localItems.reduce((acc: STRING_BOOLEAN_HASH, row: PatientTransaction) => {
        acc[String(row.type)] = true;
        return acc;
      }, {}) ?? {}
    );
  }, [localItems]);

  const mixedTypes = useMemo(() => {
    return Object.keys(childrenByType).length > 1;
  }, [childrenByType]);

  const providerOptions = useMemo(() => {
    if (!localItems.length) return {};
    const provs: { [key: string]: ProviderOnTransactionType } = {},
      skipProviderById: { [key: string]: boolean } = {};

    for (const row of localItems) {
      const primaryId = row.provider?.primaryId,
        pid = primaryId ?? row.provider?.id;

      if (pid && !provs[pid] && row.provider) {
        const nobj = { ...row.provider, id: pid };
        provs[pid] = nobj;
      }
      // Ignore providers with locked or merged transactions.
      if (row.merged || row?.items?.some((item) => item.locked)) {
        if (pid) {
          skipProviderById[pid] = true;
        }
      }
    }
    // REMOVE all the providers that are already merged...

    const deletedProvs: { [key: string]: ProviderOnTransactionType } = {};

    Object.keys(skipProviderById).forEach((key) => {
      deletedProvs[key] = ChiroUpJSON.clone(provs[key]);
      delete provs[key];
    });

    // REMOVE all the providers who have locked services.

    const keys = Object.keys(provs);
    if (keys.length === 1) {
      setSingleProviderOption(provs[keys[0]]);
    } else {
      setSingleProviderOption(null);
      if (keys.length === 0) {
        setMergeButtonTooltip('No available providers were found.');
      }
    }

    setMergeOptions((p) => {
      const n = ChiroUpJSON.clone(p);
      for (const prov of Object.values(provs ?? {})) {
        n.providers[prov.id] = true;
      }
      return n;
    });
    const len = Object.keys(provs ?? []).length;
    setCountOfProviders(len);

    if (countofProviders < 2) {
      return {};
    }

    return provs;
  }, [countofProviders, localItems]);

  const {
    data,
    failed,
    isError,
    isFetching,
    isSaving,
    onCloseCleanup,
    patient: consolidatedPatient,
    refetch,
    rollback: rollbackForCancel,
    save: saveConsolidatedTransaction,
    setDataManually,
    setTransactionManually,
    takeSnapshot,
  } = useConsolidatedTransaction({
    billingKey: itemId,
    trace,
  });

  const { data: memos, isFetching: isFetchingNotes } = usePatientNotes(
    consolidatedPatient?.ID ?? '-1',
    100,
    false,
    ['alert', 'notification'],
    [NoteLocation.Transaction],
  );

  const memoCount = useMemo(() => {
    const items = (memos?.pages?.[0].data ?? []).filter((i) =>
      (i.location ?? []).includes(NoteLocation.Transaction),
    );
    return items.length ?? 0;
  }, [memos]);

  const mergeContext = useMemo(() => {
    const notMerged: { [key: string]: PatientTransaction } = {},
      mergedInto: { [key: string]: PatientTransaction[] } = {};

    for (const row of localItems) {
      if (row.merged) {
        mergedInto[row.merged] = mergedInto[row.merged] ?? [];
        mergedInto[row.merged].push(row);
      } else {
        notMerged[row.billingKey] = row;
      }
    }

    const maybeMerge = mergeTransactionsStandalone({
      transactions: localItems,
      options: mergeOptions,
      // trace: true,
    });

    const isMerged = maybeMerge?.isMerged ?? false;
    if (maybeMerge?.messages?.length) {
      setMergeButtonTooltip(maybeMerge.messages.map((m) => m.text).join(' '));
    } else if (isMerged) {
      setMergeButtonTooltip(
        `Merging would have no affect on the transactions.`,
      );
    } else {
      setMergeButtonTooltip('');
    }

    return {
      not: notMerged,
      into: mergedInto,
      isMerged,
    };
  }, [localItems, mergeOptions]);

  /**
   * H o o k s
   */
  const {
    items: managedActionItems,
    add: addManagedActionItem,
    clear: clearManagedActionItems,
  } = useContext(ManagedActionItemsContext);

  const isAnyRestRunning = useMemo(() => {
    return isFetching || isSaving || isError || !!lifeCycle?.isRestActive?.();
  }, [isError, isFetching, isSaving, lifeCycle]);

  useEffect(() => {
    if (data) {
      // console.log({ data });
      setLocalData(() => ({ ...ChiroUpJSON.clone(data), _: Date.now() }));
      // console.log({ GroupDataFromHook: data });
    }
  }, [data]);

  /**
   * For new transactions, we need to set the UI data structures without
   * making a REST call as it isn't there yet!
   */
  useEffect(() => {
    if (
      initialContext &&
      Array.isArray(initialContext) &&
      initialContext.length &&
      typeof setInitialContext === 'function'
    ) {
      setLocalData((p) => {
        if (!p) return p;
        return { ...p, items: ChiroUpJSON.clone(initialContext) };
      });
      setInitialContext(null);
      setCurrentPhase(phases[1]);
    } else if (isEncounterMode && encounterContext) {
      setCurrentPhase(phases[1]);
    }
    if (trace) console.log({ InitialContext: initialContext });
  }, [
    encounterContext,
    initialContext,
    isEncounterMode,
    setInitialContext,
    trace,
  ]);

  const buttonGroupButtons = useMemo(() => {
    const { pathname, search } = location,
      onAppointmentScreen =
        pathname.indexOf('/schedule') === 0 && search.indexOf('?open=') === 0;
    const buttons: ButtonGroupType[] = [];
    if (isEncounterMode || !localItems.length) return buttons;

    const transaction = firstLocalItem,
      btn = {
        label: 'Appointment',
        to: `/schedule?open=${transaction.billingKey}`,
      } as ButtonGroupType;

    if (onAppointmentScreen) {
      delete btn.to;
      btn.onClick = () => {
        onCloseView?.({ data: data?.items, dirty: !!backgroundDirty });
      };
    }

    if (transaction.hasAppointment) {
      buttons.unshift(btn);
    }

    return buttons;
  }, [
    backgroundDirty,
    data,
    firstLocalItem,
    isEncounterMode,
    localItems.length,
    location,
    onCloseView,
  ]);

  const close = useCallback(() => {
    onCloseCleanup();
    setLocalData(null);
    setCurrentPhase(phases[2]);
    clearManagedActionItems();
    if (mode === 'route') {
      const { search } = location;
      const params = new URLSearchParams(search);
      const from = params.get('from');
      // if (from) {
      //   console.log(`Navigated from: ${from}`);
      // }
      // console.log({ windowHistoryLength: window.history.length });

      if (from) {
        navigate(from);
      } else if (window.history.length > 1) {
        navigate(-1);
      } else {
        navigate(`/patients/${patient.ID ?? consolidatedPatient?.ID}/billing`);
      }
      // navigate(-1);
      return;
    }
    onCloseView?.({ data: data?.items, dirty: !!backgroundDirty });
  }, [
    onCloseCleanup,
    clearManagedActionItems,
    mode,
    onCloseView,
    data?.items,
    backgroundDirty,
    location,
    navigate,
    patient.ID,
    consolidatedPatient?.ID,
  ]);

  /**
   * M e m o   A f t e r   H o o k s
   */
  const isBalanceTransfer = useMemo(() => {
    if (!localItems.length) return false;
    const rowThatIsBalanceTransfer = localItems.find(
      (row: PatientTransaction) => {
        return (
          row?.type === TransactionTypeEnum.AdHoc &&
          String(row?.subtype) ===
            TransactionPurchaseSubtypeEnum.BalanceTransfer
        );
      },
    );
    return !!rowThatIsBalanceTransfer;
  }, [localItems]);

  const isReadOnly = useMemo(() => {
    if (isBalanceTransfer) {
      return true;
    }
    return currentPhase.name !== 'Edit' && currentPhase.name !== 'New';
  }, [currentPhase.name, isBalanceTransfer]);

  const isNotReadOnly = useMemo(() => {
    return !isReadOnly;
  }, [isReadOnly]);

  const buttonColor = useMemo(() => {
    if (!localItems.length) return ButtonColors.primary;
    return firstLocalItem.isBillingStarted
      ? ButtonColors.accent
      : ButtonColors.primary;
  }, [firstLocalItem.isBillingStarted, localItems.length]);

  const trivialTooltip = useMemo(() => {
    if (!localItems.length) return { text: '' };
    return {
      text: firstLocalItem.isBillingStarted
        ? ChiroUpTransactionCommon.billingStarted
        : '',
    };
  }, [firstLocalItem.isBillingStarted, localItems.length]);

  const tabSummary = useMemo(() => {
    if (!localItems.length) return null;
    const workingTransaction = firstLocalItem as any;
    return (
      <TabSummary
        value={workingTransaction}
        omitClassName="rounded-lg hover:border-gray-400"
      />
    );
  }, [firstLocalItem, localItems.length]);

  const mainTitle = useMemo(() => {
    if (!firstLocalItem.patient) return undefined;
    const $i = firstLocalItem as any,
      resp = [
        ChiroUpDayJsCommon.display.datetimeWithTz(
          $i.transactionDate as number,
          $i.tz,
          userTz,
          ChiroUpDayJsCommon.format.date,
        ),
      ];
    return resp.join(' ');
  }, [firstLocalItem, userTz]);

  /**
   * F u n c t i o n s
   */

  /**
   * This will fire when each of the lower-down transactions update
   * their localRow from the data in the queryClient. Make sure you
   * _DO NOT_ set the query client data here somehow or you're got
   * yourself an old-fashioned infinite loop.
   *
   * I put it in here for a problem I solved another way. However,
   * it feels like this MAY be useful in the future, so I left it.
   */
  const synchronize = useCallback(
    ({ transaction }: { transaction?: PatientTransaction }) => {
      if (trace) {
        console.log({ synchronize: { transaction } });
      }
    },
    [trace],
  );

  const newTransaction = (opts: any, location?: ClinicLocation) => {
    const newx = typeof opts === 'string' && opts === 'ad-hoc';
    const idToUse = newx ? v4() : opts.id;
    let newObject: PatientTransaction | null = null;
    /**
     * Use cases:
     * 1) New transaction without an appointment (ad-hoc).
     * 2) New transaction with an appointment.
     */
    if (newx) {
      newObject = PatientTransactionClass.newRecord({
        billingKey: idToUse,
        patient: patient
          ? ({
              id: patient?.ID,
              fname: patient.fname,
              lname: patient.lname,
              phone: patient.phone,
              email: patient.email,
              displayName: createPatientName(patient),
              profilePhoto: patient.profilePhoto,
            } as PatientOnTransactionType)
          : undefined,
        clinicId: me.selectedClinic?.ID || -1,
        locationId: location?.ID || -1,
        tz: selectedLocationFull?.timezone,
        type: TransactionTypeEnum.AdHoc,
        created: {
          id: me?.ID || '',
          ts: Date.now(),
          displayName: me?.name || '',
        },
      }) as PatientTransaction;
      if (newObject) {
        setLocalData(
          (p) => {
            if (!p) {
              p = { items: [] as PatientTransaction[], policies: [], patient };
            }
            return {
              ...p,
              items: p?.items
                ? [...p.items, newObject as PatientTransaction]
                : [newObject as PatientTransaction],
            };
          },
          // [newObject]
        );
        setCurrentPhase(phases[1]);
      }
    } else {
      console.log('TODO: new transaction with appointment');
    }
  };

  const actionsOnClick = useCallback(
    (
      action: ActionsOnClick,
      transaction: PatientTransaction | null | undefined,
    ) => {
      if (!transaction) return;
      const newo = ChiroUpJSON.clone(
          localData,
        ) as ConsolidatedTransactionGetResponse,
        providerId = transaction.provider?.id as string;

      const passedItems = transaction?.items ?? [],
        passedServices = passedItems.filter((item) => isaServiceItem(item)),
        passedTransactionLocked = passedServices.some(
          (service) => service.locked,
        );

      newo.items = newo.items ?? [];

      if (action === ActionsOnClick.Lock) {
        setLockedChanged(!lockedChanged);
        for (const row of newo.items) {
          if (row?.provider?.id === providerId) {
            const items = row?.items ?? [],
              services = items.filter((item) => isaServiceItem(item)),
              isTransactionLocked = services.some((service) => service.locked);
            for (const item of items) {
              if (isaServiceItem(item)) item.locked = !isTransactionLocked;
            }
            row.services = services;
          }
        }
      } else if (action === ActionsOnClick.UnMerge) {
        if (passedTransactionLocked) {
          return;
        }
        for (const row of newo.items) {
          if (!row) continue;
          if (row.merged === transaction.billingKey) {
            row.merged = null;
          }
        }
      } else {
        alert(`Unknown action '${action}'!`);
        return;
      }

      setLocalData(newo);
    },
    [localData, lockedChanged],
  );

  const rootCallback = useCallback(
    async ({
      event,
      transaction,
      policies,
      item,
      options,
    }: RootCallbackProps) => {
      switch (event) {
        case RootCallbackEvent.SetActiveTabName:
          // console.log({ rootCallback: { event, item } });
          if (item && item?.billingKey && item?.tabname) {
            setMyActiveTabName((p) => ({
              ...p,
              [item.billingKey]: item.tabname,
            }));
          } else {
            console.log('No item passed to rootCallback for SetActiveTabName.');
          }
          break;
        case RootCallbackEvent.AddMessageItem:
          if (item) {
            addManagedActionItem(item as ManagedActionItem);
            topOfPageRef?.current?.scrollIntoView({ behavior: 'smooth' });
          } else {
            console.log('No item passed to rootCallback for AddMessageItem.');
          }
          break;
        case RootCallbackEvent.UpdateSingleTransaction:
          if (transaction) {
            setIsMagicHappening(true);
            if (options?.runMagic) {
              const clone = ChiroUpJSON.clone(
                transaction,
              ) as PatientTransaction;
              const previous = ChiroUpJSON.clone(
                localData?.items?.find(
                  (i) => i.billingKey === transaction?.billingKey,
                ),
              ) as PatientTransaction;
              const magicResponse =
                (await businessRulesService.transactionMagic({
                  transaction: clone,
                  previous,
                  event: TransactionMagicEvent.onFrontendChange,
                  options: {
                    findThePackageCredit: options?.findThePackageCredit,
                    trace: true,
                  },
                })) as TransactionMagiceResponseType;
              if (magicResponse?.actions?.length) {
                createToast({
                  title: MagicToastTitle,
                  description: (
                    <div>
                      {magicResponse?.actions.map((action, idx) =>
                        action.message ? (
                          <p key={idx}>{action.message}</p>
                        ) : null,
                      )}
                    </div>
                  ),
                  type: ToastTypes.Info,
                  duration: 5000,
                });
              }
              if (magicResponse?.touched && magicResponse?.transaction) {
                transaction = magicResponse?.transaction;
              }
            }
            setLocalData((p) => {
              if (!p) return p;
              const clone = ChiroUpJSON.clone(
                p,
              ) as ConsolidatedTransactionGetResponse;
              const newItems = clone.items.map((o) => {
                if (o.billingKey === transaction?.billingKey) {
                  return transaction;
                }
                return o;
              });
              return {
                ...clone,
                items: newItems,
                _: Date.now(),
              } as ConsolidatedTransactionGetResponse;
            });
          } else {
            console.log(
              'No transaction passed to rootCallback for UpdateSingleTransaction event.',
            );
          }
          setIsMagicHappening(false);
          break;
        case RootCallbackEvent.UpdatePolicies:
          if (policies && Array.isArray(policies)) {
            setLocalData((p) => {
              if (!p) return p;
              const newItems = p?.items.map((pItem) => {
                pItem.payors = [...(pItem?.payors || []), ...(item || [])];
              });
              return {
                ...p,
                policies,
                item: newItems,
              } as ConsolidatedTransactionGetResponse;
            });
          } else {
            console.log(
              `No policies passed for${RootCallbackEvent.UpdatePolicies}.`,
            );
          }
          break;
        case RootCallbackEvent.PaymentOnSuccess: {
          // The item in this case is a payment with all the
          // purchases attached.
          if (!item?.items?.length) {
            refetch();
            console.warn(`Weird...no payment items returned.`); // Doing this w/ applying credits. Not worrying about it for now.
            return;
          }

          for (const purchase of item?.purchases ?? []) {
            rootCallback({
              event: RootCallbackEvent.UpdateSingleTransaction,
              transaction: purchase,
            });
          }
          break;
        }
        default:
          console.log(`Unknown event ${event} in rootCallback.`);
          break;
      }
    },
    [addManagedActionItem, createToast, localData?.items, refetch],
  );

  const mergeTransactions = useCallback(
    ({
      beforeCallback,
      afterCallback,
    }: {
      beforeCallback?: () => void;
      afterCallback?: () => void;
    }) => {
      beforeCallback?.();
      const newData = mergeTransactionsStandalone({
        transactions: localData?.items ?? [],
        options: mergeOptions,
        // trace: true,
      });

      const entire = {
        ...(localData as ConsolidatedTransactionGetResponse),
        items: newData.transactions.map((i) => ({
          ...i,
          dirty: Date.now(),
        })) as PatientTransaction[],
      };
      // setLocalData(() => entire);
      // TODO: Weird, to allow a "new transaction" to be created, for some
      // reason, I set the data in a useEffect. I can't remember why. Might
      // be a better way.
      setDataManually(entire);

      setSlotContext((p) => {
        entire?.items.forEach((row: PatientTransaction, idx: number) => {
          if (p[idx]) p[idx].merged = !!row.merged;
        });
        return { ...p };
      });

      afterCallback?.();
      setJustMerged(true);
    },
    [localData, mergeOptions, setDataManually],
  );

  const rollback = useCallback(() => {
    if (editingNewData || isEncounterMode || mode === 'route') {
      close();
      return;
    }
    if (isSaving) return;
    setCurrentPhase(phases[currentPhase.next]);
    clearManagedActionItems();
    rollbackForCancel();
  }, [
    clearManagedActionItems,
    close,
    currentPhase.next,
    editingNewData,
    isEncounterMode,
    isSaving,
    mode,
    rollbackForCancel,
  ]);

  const transactionsHaveTooManyDiagnoses = useMemo(() => {
    const transactionsWithDiagnosesCount = localItems.map((parent) => {
      const allDiagnosisKeys = new Set();

      if (Array.isArray(parent.items)) {
        parent.items.forEach((item) => {
          if (Array.isArray(item.diagnoses)) {
            item.diagnoses.forEach((dx) => {
              allDiagnosisKeys.add(`${dx.codeSet}-${dx.code}`);
            });
          }
        });
      }
      const totalDiagnoses = allDiagnosisKeys.size;
      return {
        ...parent,
        diagnosesCount: totalDiagnoses,
      };
    });
    return transactionsWithDiagnosesCount.some(
      (parent) => parent.diagnosesCount > 12,
    );
  }, [localItems]);

  /**
   * R e t u r n
   */
  if (mixedTypes) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              Mixed transaction types are not supported.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (failed) {
    return (
      <div>
        <div className="flex flex-row justify-center items-center h-96">
          <div className="flex flex-col items-center">
            <FaceFrownIcon className="h-12 w-12 text-gray-400" />
            <div className="text-gray-400 text-lg">
              The transaction could not be loaded.
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ((firstLocalItem.id ?? -1) === -1 && !firstLocalItem.billingKey) {
    return (
      <Modal
        isOpen={isOpen}
        close={close}
        isFullScreen={true}
        addClasses={`bg-transparent`}
        omitClasses="bg-white"
      >
        <div
          className="rounded-lg overflow-hidden bg-white relative"
          ref={topOfPageRef}
        >
          {tabSummary}
          <div
            className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
            style={{ top: '2rem', right: '2.5rem' }}
            onClick={close}
          >
            <XMarkIcon />
          </div>
          <Loading
            flag={isFetching}
            style={`standard-gray`}
            addClassName="bg-gray-50"
          />

          <div className="flex flex-col mt-4 sm:grid-cols-2 xl:grid-cols-3 w-full px-4 pb-4">
            <div
              className="col-span-1 dark:border-darkGray-800 dark:bg-darkGray-700 dark:hover:border-darkGray-500"
              key={`ad-hoc-appt`}
            >
              {!isAnyRestRunning ? (
                locations.length > 0 ? (
                  <>
                    <div className="font-bold font-sans text-md text-gray-600">
                      Supplement or Supply Purchase by Location
                    </div>

                    {!!me?.selectedClinic?.locations?.length && (
                      <div className="flex p-4 space-x-4">
                        {me.selectedClinic.locations.map((location) => (
                          <div
                            className="flex "
                            key={`location-${location.ID}`}
                          >
                            <LocationCard
                              location={location}
                              onClick={(location: ClinicLocation) => {
                                // console.log(
                                //   `new adhoc transaction for ${location}`,
                                // );
                                newTransaction('ad-hoc', location);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <cite>Locations are not set up.</cite>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
        <MakeBrowserWait isWaiting={isAnyRestRunning || isMagicHappening} />
      </Modal>
    );
  }

  const handleSaveClick = async (e: any) => {
    clearManagedActionItems();
    const origItems = data?.items ?? [];
    const diff = ChiroUpJSON.compare({
      obj1: { value: localItems },
      obj2: {
        value: origItems,
      },
      options: {
        ignoreLeafKey: {
          updatedAt: true,
        },
        compareLeafAs: {
          amount: 'number',
          locked: 'boolean',
        },
        strict: true,
      },
    });
    const localIsClean = Object.keys(diff).length === 0;
    let lifeCycleIsDirty = false;

    // Is there anything dirty from the lifeCycle perspective?
    if (lifeCycle && lifeCycle.isDirty) {
      lifeCycleIsDirty = lifeCycle.isDirty();
      if (lifeCycleIsDirty) {
        if (trace) {
          console.log(
            '......there are dirty things according to the lifecycle.',
          );
        }
      }
      if (lifeCycleIsDirty) {
        if (lifeCycle?.save) {
          try {
            lifeCycle?.save?.();
          } catch (e) {
            console.error(e);
            addManagedActionItem({
              id: 'life-cycle-save-error',
              title: 'There was an error in the lifecycle save.',
            });
            setSaveAnywayModalOpen(false);
            return;
          }
        } else {
          addManagedActionItem({
            id: 'no-life-cycle-save',
            title: 'There is a lifecycle, but no save method.',
          });
          setSaveAnywayModalOpen(false);
          return;
        }
        if (localIsClean) return;
      }
    }

    if (localIsClean && !lifeCycleIsDirty && !lockedChanged && !justMerged) {
      addManagedActionItem({
        id: 'no-changes',
        title: 'No changes detected.',
        persistent: true,
      });
      setSaveAnywayModalOpen(false);
      return;
    }

    const res: any | null | undefined = await saveConsolidatedTransaction({
      payload: localData?.items,
    });
    if (!res) {
      addManagedActionItem({
        id: 'save-failed',
        title: 'Save failed. Check the console for details errors.',
        persistent: true,
      });
    } else if (res.statusCode !== 200) {
      addManagedActionItem({
        id: 'save-failed',
        title: res.message,
        persistent: true,
      });
    } else {
      // Leave it in edit mode if we're in encounter mode.
      if (!isEncounterMode) {
        setCurrentPhase(phases[currentPhase.next]);
      }

      setTimeout(() => {
        setBackgroundDirty(true);
        // FYI, save ONLY RETURNS THE ITEMS!
        if (localData?.items.every((item) => item.id < 0)) {
          setLocalData((p) => {
            if (!p) return p;
            return {
              ...p,
              items: res.items,
            } as ConsolidatedTransactionGetResponse;
          });
        } else {
          setDataManually(
            {
              ...(localData as ConsolidatedTransactionGetResponse),
              items: res.items,
            },
            { updateSnapshot: true },
          );
        }
        setJustMerged(false);
        setLockedChanged(false);
      }, 0);
    }
    setSaveAnywayModalOpen(false);
  }; // handleSaveClick

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      isFullScreen={true}
      addClasses={`bg-transparent`}
      omitClasses="bg-white"
    >
      <div
        className="rounded-lg overflow-hidden bg-white relative shadow-lg"
        ref={topOfPageRef}
      >
        {tabSummary}
        <div
          className="h-6 w-6 cursor-pointer font-black absolute text-gray-600"
          style={{ top: '2rem', right: '2.5rem' }}
          onClick={close}
        >
          <XMarkIcon />
        </div>
        <Loading
          flag={isFetching}
          style={`standard-gray`}
          addClassName="bg-gray-50"
        />
        {trace ? (
          <pre data-id="debug-is-here" className="bg-yellow-200 p-8">
            {ChiroUpJSON.pretty({
              mode,
              myActiveTabname,
              location,
              isAnyRestRunning,
              isFetching,
              isSaving,
              isError,
              payAvailability,
              isEditingNew,
              providerOptions,
              singleProviderOption,
              // transactionId,
              backgroundDirty,
              currentPhase,
              types: localItems.map((row) => ({
                type: row?.type,
                subtype: row?.subtype,
                billingKey: row?.billingKey,
              })),
              isMagicHappening,
            })}
          </pre>
        ) : null}
        <div
          data-id="consolidated-button-bar"
          className={classNames(
            isFetching || isError ? 'hidden' : '',
            'bg-gray-200 flex flex-row justify-between pl-4 pt-4 pr-12 w-full',
          )}
          // Yes, I hate myself. Needed an easy button. Don't judge too harshly, big story. [BWM]
          style={
            isNotAdHoc && isNotEncounterMode
              ? { top: '3.5rem', right: '0' }
              : {}
          }
        >
          <div className="flex flex-row font-bold text-lg text-gray-600">
            {isNotAdHoc && isNotEncounterMode ? (
              <div className="w-16">&nbsp;</div>
            ) : null}
            <div className="pl-3 pt-2">{mainTitle}</div>
          </div>
          <ButtonGroup
            buttons={buttonGroupButtons}
            disabled={isFetching || isSaving || !localItems.length}
            isEmptyOkay={true}
          />
          <div>
            {currentPhase.name === 'View' &&
              !isBalanceTransfer &&
              !!localData &&
              !isFetching &&
              !isSaving &&
              !isError &&
              hasRole([UserRoles.Admin, UserRoles.Biller, UserRoles.Staff]) && (
                <div className="flex justify-end h-8 space-x-4 pr-1">
                  <Button
                    text="Pay"
                    onClick={() => {
                      setShowPatientPaymentModal(true);
                    }}
                    color={ButtonColors.primary}
                    disabled={
                      isAnyRestRunning ||
                      Object.keys(payAvailability).filter(
                        (k) => payAvailability[k],
                      ).length === 0
                    }
                    tooltip={
                      Object.keys(payAvailability).filter(
                        (k) => payAvailability[k],
                      ).length === 0
                        ? 'Pay button is disabled because there is no remaining patient balance.'
                        : ''
                    }
                  />
                  <Button
                    text="Edit"
                    onClick={() => {
                      const lockout = Object.values(myActiveTabname).some(
                        (v) => v !== 'Detail',
                      );
                      if (lockout) {
                        const countofVisibleTabs = localItems.reduce(
                          (a, row) => {
                            if (!row?.merged) a++;
                            return a;
                          },
                          0,
                        ) as number;

                        addManagedActionItem({
                          id: 'tab-lockout',
                          persistent: true,
                          title: `The detail tab must be active ${
                            countofVisibleTabs === 1
                              ? 'to edit'
                              : 'in all sections to edit'
                          }.`,
                        });
                        return;
                      }
                      takeSnapshot();
                      setCurrentPhase(phases[currentPhase.next]);
                    }}
                    trivialTooltip={trivialTooltip}
                    color={buttonColor}
                  />
                </div>
              )}
            {isNotReadOnly && (
              <div className="flex flex-row justify-end space-x-2 h-8 pr-2">
                <Button
                  text="Cancel"
                  onClick={rollback}
                  color={ButtonColors.plain}
                />
                {isEncounterMode || isAdHoc ? null : localItems.length <=
                  1 ? null : (
                  <Button
                    text="Merge"
                    onClick={(e: any) => {
                      e?.preventDefault?.();
                      e?.stopPropagation?.();
                      setConfirmMergeOpen(true);
                    }}
                    color={buttonColor}
                    disabled={mergeContext.isMerged || isAnyRestRunning}
                    tooltip={mergeButtonTooltip}
                  />
                )}

                <Button
                  text="Save"
                  loading={isSaving}
                  disabled={!localData || isFetching || isSaving}
                  color={buttonColor}
                  trivialTooltip={trivialTooltip}
                  onClick={async (e: any) => {
                    if (transactionsHaveTooManyDiagnoses) {
                      setSaveAnywayModalOpen(true);
                    } else {
                      await handleSaveClick(e);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <ManagedActionItems
          containerClassName={classNames('bg-gray-200', 'pr-12 pt-4')}
          innerClassName="rounded-lg text-sm border border-gray-200 bg-orange-50 p-4"
          style={{
            paddingLeft: isAdHoc || isEncounterMode ? '1.5rem' : '5.6rem',
          }}
        />
        <div
          className={classNames('flex flex-row space-x-4 pb-8', 'bg-gray-200')}
        >
          <div className="pl-6 pr-12 py-6 flex flex-col space-y-6 grow ">
            {!isFetchingNotes && memoCount > 0 ? (
              <div
                className="border border-gray-300 bg-white rounded-lg p-4 inset-8"
                style={{
                  marginLeft: isAdHoc || isEncounterMode ? '1.5rem' : '4rem',
                }}
              >
                <PatientAlertsContainer
                  patientId={consolidatedPatient?.ID ?? '-1'}
                  location={NoteLocation.Transaction}
                  addContainerClassName={''}
                />
              </div>
            ) : null}
            {localItems.length
              ? localItems.map((row: PatientTransaction, idx) => (
                  <div key={`txn.${idx}`} className="bg-white">
                    <SingleTransaction
                      actionsOnClick={actionsOnClick}
                      buttonColor={buttonColor}
                      isLast={idx === localItems.length - 1}
                      close={close}
                      context={slotContext}
                      currentPhase={currentPhase}
                      encounterContext={encounterContext}
                      hoverTextPrimary={hoverTextPrimary}
                      isBalanceTransfer={isBalanceTransfer}
                      isFetching={isAnyRestRunning}
                      isNotReadOnly={isNotReadOnly}
                      isReadOnly={isReadOnly}
                      isSaving={isSaving}
                      itemId={itemId}
                      mergeContext={mergeContext}
                      ord={idx}
                      policies={localData?.policies ?? []}
                      rootCallback={rootCallback}
                      refetch={refetch}
                      row={row}
                      setBackgroundDirty={setBackgroundDirty}
                      setContext={setSlotContext}
                      setCurrentPhase={setCurrentPhase}
                      setPayAvailability={setPayAvailability}
                      synchronize={synchronize}
                      textPrimary={textPrimary}
                      trace={trace}
                      setTransactionManually={setTransactionManually}
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
        {isError ? (
          <div className="w-full flex justify-center p-8">
            <div className="h-24 w-24 text-red-500">
              <FaceFrownIcon />
            </div>
          </div>
        ) : null}
      </div>
      {showPatientPaymentModal && patient ? (
        <Payment
          patientId={consolidatedPatient?.ID}
          isOpen={true}
          passedTransactions={localItems}
          isRouted={false}
          onClosed={() => setShowPatientPaymentModal(false)}
          onSuccess={(item) => {
            rootCallback({
              event: RootCallbackEvent.PaymentOnSuccess,
              item,
            });
          }}
          disableCreateCredit={true}
        />
      ) : null}
      {confirmMergeOpen ? (
        <ConfirmModal
          isOpen={true}
          confirm={(e: any) => {
            e?.preventDefault?.();
            e?.stopPropagation?.();
            mergeTransactions({
              beforeCallback: () => {
                setConfirmMergeOpen(false);
              },
            });
          }}
          close={() => setConfirmMergeOpen(false)}
          confirmText="Continue"
          focusOnCancel={true}
          confirmDisabled={
            Object.values(mergeOptions?.providers ?? {}).every(
              (value) => value === false,
            ) ||
            (countofProviders < 2 && !singleProviderOption)
          }
        >
          <div className="mt-4 font-sans font-light text-sm flex flex-col space-y-2">
            {/* <pre>{ChiroUpJSON.pretty({ mergeOptions, providerOptions })}</pre> */}
            <p>
              This combines items from multiple transactions into single
              transactions for billing purposes. The options below control the
              process. Use with caution, once merged,{' '}
              <em>reversing the process completely is a manual operation</em>.
            </p>
            {/* <pre>
              {ChiroUpJSON.pretty({
                singleProviderOption,
                isNull: singleProviderOption === null,
                undef: singleProviderOption === undefined,
                countofProviders,
              })}
            </pre> */}
            {countofProviders > 1 ? (
              <p>
                By default, the transactions for all rendering providers will be
                affected. By unchecking a provider, they will be ignored.
              </p>
            ) : singleProviderOption ? (
              <p>
                All <cite>{singleProviderOption.displayName}</cite> transactions
                will be affected.
              </p>
            ) : (
              <AlertBlock
                issues={[
                  {
                    text: 'No providers found.',
                  },
                ]}
              />
            )}
            <p>
              When <cite>merge units</cite> is checked, the number of units for
              a given service code on the merged transaction will be the sum of
              the units on the individual transactions instead of the merged
              transaction having multiple items for the same service code.
            </p>
            <p>
              When <cite>merge treatments</cite> is checked, all treatments for
              a particular provider will be put under a single transaction.
            </p>
          </div>

          <div className="font-sans font-medium text-md mt-4 text-primary-500 mb-2">
            Options
          </div>
          <div className="flex flex-col space-y-2">
            <ProvidersToMerge
              providers={providerOptions}
              options={mergeOptions}
              onClick={(provider) => {
                setMergeOptions((p) => {
                  if (!p) return p;
                  const key = provider?.id ?? '';
                  if (typeof p?.providers?.[key] !== 'boolean') {
                    p.providers = p.providers ?? {};
                    p.providers[key] = true;
                  } else {
                    p = {
                      ...p,
                      providers: {
                        ...p.providers,
                        [key]: !p.providers[key],
                      },
                    };
                  }
                  return p;
                });
              }}
            />
            <Checkbox
              value={mergeOptions.units}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    units: !p.units,
                  };
                });
              }}
              label="Merge units"
            />
            <Checkbox
              value={mergeOptions.treatments}
              onChange={() => {
                setMergeOptions((p) => {
                  return {
                    ...p,
                    treatments: !p.treatments,
                  };
                });
              }}
              label="Merge treatments"
            />
          </div>
        </ConfirmModal>
      ) : null}
      <ConfirmModal
        isOpen={saveAnywayModalOpen}
        close={() => setSaveAnywayModalOpen(false)}
        confirm={handleSaveClick}
        description="You are saving a purchase with more than 12 diagnoses."
        loading={isSaving}
      />
      <MakeBrowserWait isWaiting={isAnyRestRunning || isMagicHappening} />
    </Modal>
  );
};

export default ConsolidatedTransactionModal;

type ProvidersToMerge = {
  providers?: {
    [key: string]: ProviderOnTransactionType & { selected?: boolean };
  };
  options: MergeTransactionsOptions;
  onClick: (provider?: ProviderOnTransactionType) => void;
  containerClassName?: string;
};

const ProvidersToMerge: React.FC<ProvidersToMerge> = ({
  providers,
  onClick,
  options = {},
  containerClassName = '',
}: ProvidersToMerge) => {
  const aProviders = useMemo(() => {
    if (!providers) return [];
    return Object.values(providers);
  }, [providers]);

  if (!aProviders.length) return null;

  return (
    <>
      {aProviders.map((provider) => {
        return (
          <Checkbox
            key={provider.id}
            value={options?.providers?.[provider.id]}
            onChange={() => {
              onClick(provider);
            }}
            label={`Merge for ${provider.displayName}`}
          />
        );
      })}
    </>
  );
};
