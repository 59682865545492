import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Checkbox, Input, InputMasked, Select } from '@chiroup/components';
import useHealthcareProvidersAll from '../../../../../hooks/useHealthcareProvidersAll';
import useClinicUsers from '../../../../../hooks/useClinicUsers';
import userService from '../../../../../services/user.service';
import { MeContext } from '../../../../../contexts/me.context';
import { combineUserDetails, providerOptionsMemo } from './helpers';
import HealthcareProviderCreate from '../../../../settings/database/healthcare-providers/HealthcareProviderCreate';
import { USStatesAndTerritories } from '@chiroup/core/constants/UsStatesAndTerritories';
import { titleCaseEnumToArrayOfOptions } from '@chiroup/core/functions/enumToArrayOfOptions';
import {
  Visit,
  IdNumberType,
  ProviderType,
  ClaimNatureSelectOptions,
  EPSDTRefferalType,
} from '@chiroup/core/types/PatientVisit.type';
import {
  ProviderLicense,
  ProviderCommercialNumber,
} from '@chiroup/core/types/UserSettingsBilling.type';
import { STRING_STRING_HASH } from '@chiroup/core/constants/globals';

type Props = {
  value: Partial<Visit>;
  setVisitForm?: React.Dispatch<React.SetStateAction<Visit>>;
  disabled?: boolean;
  grid?: {
    start?: string;
    pair?: string;
    label?: string;
    value?: string;
  };
  dontShowFalse?: boolean;
};

const updateNestedFields = (
  setVisitForm: React.Dispatch<React.SetStateAction<Visit>>,
  key: string,
  value: any,
) => {
  setVisitForm((prev) => {
    return {
      ...prev,
      encounterInfo: {
        ...prev.encounterInfo,
        claimInfo: {
          ...prev.encounterInfo?.claimInfo,
          [key]: value,
        },
      },
    };
  });
};

const ClaimInfoForm: React.FC<Props> = ({
  value,
  setVisitForm,
  disabled,
  grid,
  dontShowFalse,
}) => {
  const {
    nature,
    onsetDate,
    // relatedEvent,
    // relatedEventDate,
    EPSDTRefferal,
    unableWorkStartDate,
    unableWorkEndDate,
    disabilityStartDate,
    disabilityEndDate,
    hospitalStartDate,
    hospitalEndDate,
    providerType,
    providerId,
    providerName,
    providerNpi,
    idNumber,
    idNumberType,
    employment,
    auto,
    autoState,
    otherAccident,
    accidentDate,
    acuteManifestationChronicConditionDate,
    firstVisitDate,
    initialTreatmentDate,
    lastXRayDate,
    latestVisitDate,
    prescriptionDate,
    reportEndDate,
    reportStartDate,
  } = value.encounterInfo?.claimInfo || {};

  const [claimInfoFormUsers, setClaimInfoFormUsers] = useState<any[]>([]);

  const { me } = useContext(MeContext);

  const isReadonly = useMemo(() => {
    return disabled || !setVisitForm;
  }, [disabled, setVisitForm]);

  const { data: healthcareProviders, refetch } =
    useHealthcareProvidersAll(isReadonly);
  const { data: users } = useClinicUsers({
    disabled: isReadonly,
  });

  const providerOptions = useMemo(
    () =>
      providerOptionsMemo(
        users ?? [],
        healthcareProviders?.data ?? [],
        providerType,
      ),
    [providerType, users, healthcareProviders?.data],
  );

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (users?.length && me?.selectedClinic?.ID) {
        try {
          const billingData = await userService.listBilling(
            me.selectedClinic.ID,
          );
          if (isMounted) {
            setClaimInfoFormUsers(combineUserDetails(users, billingData));
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (!isReadonly) fetchData();
    return () => {
      isMounted = false;
    }; // Cleanup function to prevent memory leaks
  }, [users, me?.selectedClinic?.ID, isReadonly]);

  const handleProviderChange = (id: string | number) => {
    if (!setVisitForm) return;
    updateNestedFields(setVisitForm, 'providerId', id);
    updateNestedFields(setVisitForm, 'providerNpi', undefined);
    updateNestedFields(setVisitForm, 'idNumber', undefined);
    updateNestedFields(setVisitForm, 'idNumberType', undefined);
    let provider;
    if (typeof id === 'string') {
      provider = claimInfoFormUsers?.find((user) => user.ID === id);
    } else if (typeof id === 'number') {
      provider = healthcareProviders?.data?.find(
        (provider) => provider.ID === id,
      );
      updateNestedFields(setVisitForm, 'providerNpi', provider?.npiNumber);
    }
    updateNestedFields(setVisitForm, 'providerNpi', provider?.npiNumber);
    if (idNumberType) {
      switch (idNumberType) {
        case IdNumberType.StateLicenseNumber:
          updateNestedFields(setVisitForm, 'idNumber', provider?.licenseNumber);
          break;
        case IdNumberType.UPINNumber:
          updateNestedFields(setVisitForm, 'idNumber', provider?.upin);
          break;
        case IdNumberType.ProviderCommercialNumber:
          updateNestedFields(
            setVisitForm,
            'idNumber',
            provider?.commercialNumber,
          );
          break;
        default:
          break;
      }
    }
  };

  const handleIdTypeChange = (type: string) => {
    if (!setVisitForm) return;
    updateNestedFields(setVisitForm, 'idNumberType', type);
    if (providerId) {
      const provider =
        typeof providerId === 'string'
          ? claimInfoFormUsers?.find((user) => user.ID === providerId)
          : healthcareProviders?.data?.find(
              (provider) => provider.ID === providerId,
            );
      switch (type) {
        case IdNumberType.StateLicenseNumber:
          updateNestedFields(
            setVisitForm,
            'idNumber',
            provider?.licenseNumber ||
              provider?.stateLicenseNumber ||
              provider?.providerLicenses?.[0]?.licenseNumber,
          );
          break;
        case IdNumberType.UPINNumber:
          updateNestedFields(setVisitForm, 'idNumber', provider?.upin);
          break;
        case IdNumberType.ProviderCommercialNumber:
          updateNestedFields(
            setVisitForm,
            'idNumber',
            provider?.commercialNumber ||
              provider?.providerCommercialNumbers?.[0]?.number,
          );
          break;
        default:
          break;
      }
    }
  };

  const handleProviderTypeChange = (type: string) => {
    if (!setVisitForm) return;
    updateNestedFields(setVisitForm, 'providerType', type);
    updateNestedFields(setVisitForm, 'providerId', undefined);
    updateNestedFields(setVisitForm, 'providerNpi', undefined);
    updateNestedFields(setVisitForm, 'idNumber', undefined);
    updateNestedFields(setVisitForm, 'idNumberType', undefined);
  };

  const handleValueChange = useCallback(
    (key: string) => (eventValue: any) => {
      if (!setVisitForm) return;
      updateNestedFields(setVisitForm, key, eventValue);
    },
    [setVisitForm],
  );

  const onCreate = () => {
    refetch();
  };

  const getIdNumberOptions = useCallback(
    (type?: string, providerType?: string, providerId?: string) => {
      if (
        type &&
        providerId &&
        (providerType === ProviderType.OrderingProvider ||
          providerType === ProviderType.SupervisingProvider)
      ) {
        const provider = claimInfoFormUsers?.find(
          (user) => user.ID === providerId,
        );
        switch (type) {
          case IdNumberType.StateLicenseNumber:
            return provider?.providerLicenses?.map(
              (license: ProviderLicense) => ({
                text: license.licenseNumber,
                value: license.licenseNumber,
              }),
            );
          case IdNumberType.ProviderCommercialNumber:
            return provider?.providerCommercialNumbers?.map(
              (pcn: ProviderCommercialNumber) => ({
                text: pcn.number,
                value: pcn.number,
              }),
            );
          default:
            return [];
        }
      }
      return [];
    },
    [claimInfoFormUsers],
  );

  const idNumberOptions = useMemo(
    () => getIdNumberOptions(idNumberType, providerType, String(providerId)),
    [getIdNumberOptions, idNumberType, providerType, providerId],
  );

  const uiClaimNature = useMemo(() => {
    return (
      ClaimNatureSelectOptions.find((option) => option.value === nature)
        ?.text || nature
    );
  }, [nature]);

  const pairBlock = (label: any, value: any) => {
    const useLabel = label || '- no label -',
      useValue = value || '- no value -';
    if (!value) return null;
    return (
      <div className={grid?.pair}>
        <div className={grid?.label}>{useLabel}</div>
        <div className={grid?.value}>{useValue}</div>
      </div>
    );
  };

  /**
   * [7707376711 by Brian] Apologies, but this form was never intended to be used
   * in two places. It was just to gather the information. Unfortunately, there is
   * a requirement to display this somewhere else. So, there are two options: 1)
   * Duplicate, 2) make this work two ways. For long-term maintainability, it works
   * two ways via the data structures in this function. The plus side is that this
   * shows all [most] of the labels in one spot so they can be normalized to all
   * having the same pattern.
   *
   * @param key
   * @returns
   */
  const componentSelect = (key: string) => {
    const subkey = grid ? 'false' : 'true';
    const labels = {
      accidentDate: 'Accident date',
      acuteManifestationChronicConditionDate:
        'Acute manifestation of a chronic condition',
      auto: 'Auto Accident',
      autoState: 'State accident occurred In',
      disabilityEndDate: 'Disability end date',
      disabilityStartDate: 'Disability start date',
      employment: 'Employment',
      EPSDTRefferal: 'EPSDT referral',
      firstVisitDate: 'Property & casualty first fate of contact',
      hospitalEndDate: 'Hospital end date',
      hospitalStartDate: 'Hospital start date',
      idNumber: 'ID number',
      idNumberType: 'ID number type',
      initialTreatmentDate: 'Initial treatment date',
      lastXRayDate: 'Last X-ray date',
      latestVisitDate: 'Latest visit or consultation date',
      nature: 'Nature of condition',
      onsetDate: 'Onset of current symptoms or illness',
      otherAccident: 'Other accident',
      prescriptionDate: 'Prescription date',
      providerCommercialNumber: 'Provider commercial number',
      providerLicenseNumber: 'Provider license number',
      providerName: 'Provider name',
      providerNPI: 'Provider NPI',
      providerType: 'Provider type',
      reportEndDate: 'Report end date (relinquished care date)',
      reportStartDate: 'Report start date (assumed care date)',
      unableWorkEndDate: 'Unable to work end date',
      unableWorkStartDate: 'Unable to work start date',
    } as STRING_STRING_HASH;
    const stat = {
      accidentDate: {
        true: (
          <InputMasked
            name="accidentDate"
            label={labels?.[key]}
            value={accidentDate}
            onChange={handleValueChange('accidentDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && accidentDate === undefined
            ? null
            : pairBlock(labels?.[key], accidentDate),
      },
      acuteManifestationChronicConditionDate: {
        true: (
          <InputMasked
            name="acuteManifestationChronicConditionDate"
            label={labels?.[key]}
            value={acuteManifestationChronicConditionDate}
            onChange={handleValueChange(
              'acuteManifestationChronicConditionDate',
            )}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && acuteManifestationChronicConditionDate === undefined
            ? null
            : pairBlock(labels?.[key], acuteManifestationChronicConditionDate),
      },
      auto: {
        true: (
          <Checkbox
            name="auto"
            value={auto ?? false}
            onChange={handleValueChange('auto')}
            label={labels?.[key]}
            className="col-span-4"
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && auto === undefined
            ? null
            : pairBlock(
                'Condition related to auto accident',
                auto ? 'Yes' : 'No',
              ),
      },
      autoState: {
        true: (
          <Select
            label={labels?.[key]}
            name="autoState"
            value={autoState}
            onChange={handleValueChange('autoState')}
            options={USStatesAndTerritories}
            className="col-span-2"
            limit={1}
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && autoState === undefined
            ? null
            : pairBlock(
                labels?.[key],
                USStatesAndTerritories.find((i) => i && i.value === autoState)
                  ?.text ?? autoState,
              ),
      },
      disabilityEndDate: {
        true: (
          <InputMasked
            name="disabilityEndDate"
            label={`End date`}
            value={disabilityEndDate}
            onChange={handleValueChange('disabilityEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && disabilityEndDate === undefined
            ? null
            : pairBlock(labels?.[key], disabilityEndDate),
      },
      disabilityStartDate: {
        true: (
          <InputMasked
            name="disabilityStartDate"
            label={`Start date`}
            value={disabilityStartDate}
            onChange={handleValueChange('disabilityStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && disabilityStartDate === undefined
            ? null
            : pairBlock(labels?.[key], disabilityStartDate),
      },
      employment: {
        true: (
          <Checkbox
            name="employment"
            value={employment ?? false}
            onChange={handleValueChange('employment')}
            label={labels?.[key]}
            className="col-span-4"
            disabled={
              disabled ||
              (!!value?.encounterInfo?.claimInfo?.auto &&
                !!value?.encounterInfo?.claimInfo?.otherAccident)
            }
          />
        ),
        false:
          dontShowFalse && employment === undefined
            ? null
            : pairBlock(
                `Condition related to employment`,
                employment ? 'Yes' : 'No',
              ),
      },
      EPSDTRefferal: {
        true: (
          <Select
            value={EPSDTRefferal}
            onChange={handleValueChange('EPSDTRefferal')}
            name="epsdtReferral"
            label={labels?.[key]}
            options={titleCaseEnumToArrayOfOptions(EPSDTRefferalType)}
            className="col-span-2"
            limit={1}
            disabled={disabled}
            clearable
          />
        ),
        false:
          dontShowFalse && EPSDTRefferal === undefined
            ? null
            : pairBlock(labels?.[key], EPSDTRefferal ? 'Yes' : 'No'),
      },
      firstVisitDate: {
        true: (
          <InputMasked
            name="firstVisitDate"
            label={labels?.[key]}
            value={firstVisitDate}
            onChange={handleValueChange('firstVisitDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && firstVisitDate === undefined
            ? null
            : pairBlock(labels?.[key], firstVisitDate),
      },
      hospitalEndDate: {
        true: (
          <InputMasked
            name="hospitalEndDate"
            label={'End date'}
            value={hospitalEndDate}
            onChange={handleValueChange('hospitalEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && hospitalEndDate === undefined
            ? null
            : pairBlock(labels?.[key], hospitalEndDate),
      },
      hospitalStartDate: {
        true: (
          <InputMasked
            name="hospitalStartDate"
            label={'Start date'}
            value={hospitalStartDate}
            onChange={handleValueChange('hospitalStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && hospitalStartDate === undefined
            ? null
            : pairBlock(labels?.[key], hospitalStartDate),
      },
      idNumber: {
        true: (
          <Input
            name="idNumber"
            label={labels?.[key]}
            value={idNumber}
            onChange={handleValueChange('idNumber')}
            className="col-span-2"
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && idNumber === undefined
            ? null
            : pairBlock('Provider ID number', idNumber),
      },
      idNumberType: {
        true: (
          <Select
            value={idNumberType}
            name="idNumberType"
            label={labels?.[key]}
            onChange={handleIdTypeChange}
            options={titleCaseEnumToArrayOfOptions(IdNumberType)}
            className="col-span-2"
            limit={1}
            clearable
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && idNumberType === undefined
            ? null
            : pairBlock(
                'Provider ID number type',
                (titleCaseEnumToArrayOfOptions(IdNumberType) ?? []).find(
                  (i) => i && i.value === idNumberType,
                )?.text ?? null,
              ),
      },
      initialTreatmentDate: {
        true: (
          <InputMasked
            name="initialTreatmentDate"
            label={labels?.[key]}
            value={initialTreatmentDate}
            onChange={handleValueChange('initialTreatmentDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && initialTreatmentDate === undefined
            ? null
            : pairBlock(labels?.[key], initialTreatmentDate),
      },
      lastXRayDate: {
        true: (
          <InputMasked
            name="lastXRayDate"
            label={labels?.[key]}
            value={lastXRayDate}
            onChange={handleValueChange('lastXRayDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && lastXRayDate === undefined
            ? null
            : pairBlock(labels?.[key], lastXRayDate),
      },
      latestVisitDate: {
        true: (
          <InputMasked
            name="latestVisitDate"
            label={labels?.[key]}
            value={latestVisitDate}
            onChange={handleValueChange('latestVisitDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && latestVisitDate === undefined
            ? null
            : pairBlock(labels?.[key], latestVisitDate),
      },
      nature: {
        true: (
          <Select
            value={nature}
            onChange={handleValueChange('nature')}
            name="conditionNature"
            label={labels?.[key]}
            options={ClaimNatureSelectOptions}
            className="col-span-2"
            limit={1}
            disabled={disabled}
            clearable
          />
        ),
        false:
          dontShowFalse && uiClaimNature === undefined
            ? null
            : pairBlock(labels?.[key], uiClaimNature),
      },
      onsetDate: {
        true: (
          <InputMasked
            name="onsetDate"
            label={labels?.[key]}
            value={onsetDate}
            onChange={handleValueChange('onsetDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && onsetDate === undefined
            ? null
            : pairBlock(labels?.[key], onsetDate),
      },
      otherAccident: {
        true: (
          <Checkbox
            name="otherAccident"
            value={otherAccident ?? false}
            onChange={handleValueChange('otherAccident')}
            label={labels?.[key]}
            className="col-span-1"
            disabled={
              disabled ||
              (!!value?.encounterInfo?.claimInfo?.auto &&
                !!value?.encounterInfo?.claimInfo?.employment)
            }
          />
        ),
        false:
          dontShowFalse && otherAccident === undefined
            ? null
            : pairBlock(labels?.[key], otherAccident ? 'Yes' : 'No'),
      },
      prescriptionDate: {
        true: (
          <InputMasked
            name="prescriptionDate"
            label={labels?.[key]}
            value={prescriptionDate}
            onChange={handleValueChange('prescriptionDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && prescriptionDate === undefined
            ? null
            : pairBlock(labels?.[key], prescriptionDate),
      },
      providerCommercialNumber: {
        true: (
          <Input
            name="providerCommercialNumber"
            label={labels?.[key]}
            value={idNumber}
            onChange={handleValueChange('idNumber')}
            className="col-span-2"
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && idNumber === undefined
            ? null
            : pairBlock(labels?.[key], idNumber),
      },
      providerLicenseNumber: {
        true: (
          <Input
            name="providerLicenseNumber"
            label={labels?.[key]}
            value={idNumber}
            onChange={handleValueChange('idNumber')}
            className="col-span-2"
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && idNumber === undefined
            ? null
            : pairBlock(labels?.[key], idNumber),
      },
      providerName: {
        true: (
          <Select
            value={providerId}
            onChange={handleProviderChange}
            name="providerName"
            label={labels?.[key]}
            options={providerOptions}
            autocomplete
            limit={1}
            className="col-span-2"
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && providerId === undefined
            ? null
            : pairBlock(labels?.[key], providerName || providerId),
      },
      providerNPI: {
        true: (
          <Input
            name="providerNPI"
            label={labels?.[key]}
            value={providerNpi}
            onChange={handleValueChange('providerNpi')}
            className="col-span-2"
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && providerNpi === undefined
            ? null
            : pairBlock(labels?.[key], providerNpi),
      },
      providerType: {
        true: (
          <Select
            label={labels?.[key]}
            name="providerType"
            options={titleCaseEnumToArrayOfOptions(ProviderType)}
            value={providerType}
            onChange={handleProviderTypeChange}
            className="col-span-2"
            limit={1}
            clearable
            disabled={disabled}
          />
        ),
        false:
          dontShowFalse && providerType === undefined
            ? null
            : pairBlock(
                labels?.[key],
                (titleCaseEnumToArrayOfOptions(ProviderType) ?? []).find(
                  (i) => i && i.value === providerType,
                )?.text ?? null,
              ),
      },
      reportEndDate: {
        true: (
          <InputMasked
            name="reportEndDate"
            label={labels?.[key]}
            value={reportEndDate}
            onChange={handleValueChange('reportEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && reportEndDate === undefined
            ? null
            : pairBlock(labels?.[key], reportEndDate),
      },
      reportStartDate: {
        true: (
          <InputMasked
            name="reportStartDate"
            label={labels?.[key]}
            value={reportStartDate}
            onChange={handleValueChange('reportStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && reportStartDate === undefined
            ? null
            : pairBlock(labels?.[key], reportStartDate),
      },
      unableWorkEndDate: {
        true: (
          <InputMasked
            name="unableWorkEndDate"
            label={`End date`}
            value={unableWorkEndDate}
            onChange={handleValueChange('unableWorkEndDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && unableWorkEndDate === undefined
            ? null
            : pairBlock('Unable to work end date', unableWorkEndDate),
      },
      unableWorkStartDate: {
        true: (
          <InputMasked
            name="unableWorkStartDate"
            label={'Start date'}
            value={unableWorkStartDate}
            onChange={handleValueChange('unableWorkStartDate')}
            className="col-span-2"
            disabled={disabled}
            patternFormat="##/##/####"
            placeholder="MM/DD/YYYY"
          />
        ),
        false:
          dontShowFalse && unableWorkStartDate === undefined
            ? null
            : pairBlock('Unable to work start date', unableWorkStartDate),
      },
    } as any;
    return stat?.[key]?.[subkey] || null;
  };

  if (grid) {
    return (
      <>
        {componentSelect('nature')}
        {componentSelect('onsetDate')}
        {componentSelect('EPSDTRefferal')}
        <div>&nbsp;</div>
        {componentSelect('acuteManifestationChronicConditionDate')}
        {componentSelect('firstVisitDate')}
        {componentSelect('initialTreatmentDate')}
        {componentSelect('lastXRayDate')}
        {componentSelect('latestVisitDate')}
        {componentSelect('prescriptionDate')}
        {componentSelect('reportStartDate')}
        {componentSelect('reportEndDate')}
        {componentSelect('unableWorkStartDate')}
        {componentSelect('unableWorkEndDate')}
        {componentSelect('disabilityStartDate')}
        {componentSelect('disabilityEndDate')}
        {componentSelect('hospitalStartDate')}
        {componentSelect('hospitalEndDate')}
        {componentSelect('providerType')}
        {componentSelect('providerName')}
        {componentSelect('providerNPI')}
        {componentSelect('idNumberType')}
        {componentSelect('idNumber')}
        {componentSelect('employment')}
        {componentSelect('auto')}
        {componentSelect('accidentDate')}
        {componentSelect('autoState')}
        {componentSelect('otherAccident')}
      </>
    );
  }

  return (
    <>
      {componentSelect('nature')}
      {componentSelect('onsetDate')}
      {componentSelect('EPSDTRefferal')}
      <div className="p-4 col-span-4 bg-gray-300 rounded-md ">
        <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
          <p className="col-span-4">Condition Related Dates</p>
          {componentSelect('acuteManifestationChronicConditionDate')}
          {componentSelect('firstVisitDate')}
          {componentSelect('initialTreatmentDate')}
          {componentSelect('lastXRayDate')}
          {componentSelect('latestVisitDate')}
          {componentSelect('prescriptionDate')}
          {componentSelect('reportStartDate')}
          {componentSelect('reportEndDate')}

          <p className="pt-6">Other Related Dates</p>
          <p className="col-span-4 pt-2 font-light text-md">
            Dates patient unable to work
          </p>
          {componentSelect('unableWorkStartDate')}
          {componentSelect('unableWorkEndDate')}

          <p className="col-span-4 pt-4 font-light text-md">Disability dates</p>
          {componentSelect('disabilityStartDate')}
          {componentSelect('disabilityEndDate')}

          <p className="col-span-4 pt-4 font-light text-md">
            Hospital dates related to current services
          </p>
          {componentSelect('hospitalStartDate')}
          {componentSelect('hospitalEndDate')}
        </div>
      </div>
      <div className="col-span-4 pt-4 relative">
        <p>Provider Details</p>
        <span className="absolute right-0">
          <HealthcareProviderCreate onCreate={onCreate} providerOnly />
        </span>
      </div>
      {componentSelect('providerType')}

      {!!providerType && (
        <>
          {componentSelect('providerName')}

          {!!providerId && componentSelect('providerNPI')}
          {!!providerId && componentSelect('idNumberType')}
          {!!idNumberType && providerType === ProviderType.ReferringProvider ? (
            componentSelect('idNumber')
          ) : !!idNumberType &&
            (providerType === ProviderType.SupervisingProvider ||
              providerType === ProviderType.OrderingProvider) ? (
            idNumberType === IdNumberType.UPINNumber ? (
              componentSelect('idNumber')
            ) : (
              <Select
                value={idNumber}
                name="idNumber"
                label="ID number"
                onChange={handleValueChange('idNumber')}
                options={idNumberOptions}
                limit={1}
                className="col-span-2"
                hint={
                  !idNumberOptions?.length
                    ? 'You have no ID numbers of this type'
                    : undefined
                }
                clearable
                disabled={disabled || !idNumberOptions?.length}
              />
            )
          ) : null}
        </>
      )}

      <div className="p-4 col-span-4 bg-gray-300 rounded-md">
        <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4">
          <p className="col-span-4">Condition Related To:</p>
          {componentSelect('employment')}

          <div className="col-span-1 flex items-center ">
            {componentSelect('auto')}
          </div>

          <div className="col-span-3 flex items-center ">
            {auto && (
              <>
                {componentSelect('accidentDate')}
                {componentSelect('autoState')}
              </>
            )}
          </div>

          {componentSelect('otherAccident')}

          {otherAccident && (
            <div className="col-span-3 flex items-center ">
              {componentSelect('accidentDate')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ClaimInfoForm;
