/**
 * [2024-07-16.1355 by Brian]
 *      Added a getRenderingProviderDetails function to get the extra
 *      fields from UserClinicBilling needed for EDI creation.
 *
 * [2023-12-05.0917 by Brian]
 *      A couple of helpers. There is a getProvider and getUser, but they
 *      both do the same thing. Maybe less confusion if the nomenclatures
 *      can be kept in sync.
 */
import { Connection } from 'mysql2/promise';
import { NotFoundError } from './errors';
import { EDI_SUBMITTER_TYPE_ENUM } from '../constants/globals';
import { KnownClearingHouses } from '../types/BillingCode.type';
import { HealthcareProvider } from '../types/HealthcareProvider.type';
import { ChiroUpIdPattern } from '../types/Integration.type';
import { User, RenderingProviderUser } from '../types/User.type';

export const getReferringProviderDetails = async ({
  connection,
  id,
}: {
  connection: Connection;
  id: number;
}) => {
  const [row] = (await connection.query(
    `SELECT * FROM Provider WHERE ID = :id;`,
    {
      id,
    },
  )) as unknown as any[];

  return !!row && row.length > 0 ? (row[0] as any) : null;
};

const single = async ({
  connection,
  id,
}: {
  connection: Connection;
  id: string;
}) => {
  const [row] = (await connection.query(`SELECT * FROM User WHERE ID = :id;`, {
    id,
  })) as unknown as any[];

  return !!row && row.length > 0 ? (row[0] as User) : null;
};

export const getClinicProvider = async ({
  connection,
  id,
  clinicId,
}: {
  connection: Connection;
  id: string;
  clinicId: number;
}) => {
  try {
    const [provider] = (await connection.query(
      `SELECT u.*, uc.encounterSupervision, uc.billingSupervision, uc.supervisor FROM User u 
    INNER JOIN UserClinic uc ON u.ID = uc.userId AND uc.clinicId = :clinicId
    WHERE u.ID = :id ;`,
      {
        id,
        clinicId,
      },
    )) as unknown[][] as User[][];
    return provider[0] as User;
  } catch (e) {
    console.error(e);
    throw new NotFoundError('Error getting clinic provider');
  }
};

export const getProvider = async ({
  connection,
  providerId,
}: {
  connection: Connection;
  providerId: string;
}) => {
  return await single({ connection, id: providerId });
};

export const getRenderingProviderUser = async ({
  connection,
  providerId,
  clinicId = null,
}: {
  connection: Connection;
  providerId: string;
  clinicId: number | null;
}) => {
  const [row] = (await connection.query(
    `
      SELECT u.*
           , ucb.npiNumber npi
           , ucb.taxonomyCode
           , ucb.taxIdNumber
           , ucb.taxIdType
           , ucb.upin
           , uc.supervisor
           , uc.billingSupervision
           , uc.encounterSupervision
       FROM User u
  LEFT JOIN UserClinicBilling ucb
         ON ucb.userId = u.ID
        AND ucb.clinicId = :clinicId
  LEFT JOIN UserClinic uc
         ON uc.userId = u.ID
         AND uc.clinicId = :clinicId
      WHERE u.ID = :id
      ;`,
    {
      id: providerId,
      clinicId,
    },
  )) as unknown as any[];

  return !!row && row.length > 0 ? (row[0] as RenderingProviderUser) : null;
};

export const getUser = async ({
  connection,
  userId,
}: {
  connection: Connection;
  userId: string;
}) => {
  return await single({ connection, id: userId });
};

/**
 * The name of this function comes from the type that it returns.
 *
 * Healthcare providers -- referring, billing, etc. -- come from the table
 * 'Provider' and are updated in the UI via "Admin" => "Facilities and
 * Providers". These providers _are not_ users like the normal providers
 * (or string providerIds).
 *
 * Note that facilities are also saved in this table and they are excluded
 * from the query.
 *
 * @param param0
 * @returns
 */
export const getHealthcareProvider = async ({
  connection,
  id,
}: {
  connection: Connection;
  id: number;
}) => {
  if (isNaN(id)) return null;
  try {
    const [row] = (await connection.query(
      `SELECT * FROM Provider WHERE ID = :id AND facility = 0;`,
      {
        id,
      },
    )) as unknown as any[];

    return !!row && row.length > 0 ? (row[0] as HealthcareProvider) : null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getProviderEdiDetails = async ({
  clinicId,
  connection,
  id = null,
  billingProfileId = null,
}: {
  clinicId: number;
  connection: Connection;
  id: string | null;
  billingProfileId: number | null;
}): Promise<ClinicProviderEdiDetailsType | null> => {
  if (!id || !billingProfileId) return null;
  if (!ChiroUpIdPattern.test(id)) return null;
  if (isNaN(clinicId) || isNaN(billingProfileId)) return null;

  /**
   * [2024-03-20.1537 by Brian]
   *   This returns the billing profile details. AKA the billing provider.
   *   The profile is the configuration of the legal entity that receives
   *   payments from payors. Probably one-to-one with Trizetto accounts but
   *   that remains to be seen.
   *
   *   This changed from going from the rendering provider to the billing
   *   provider. There were requirements to allow one rendering provider to
   *   be associated with more than one billing profile. So, the billing
   *   profile can be changed on the transaction to any profile associated
   *   with the rendering provider. [Confused much?]
   */
  const [res]: any[] = await connection.query(
    `SELECT -- Submitter details.
            COALESCE(bp.submitterType, '${EDI_SUBMITTER_TYPE_ENUM.Default}') submitterType
          , COALESCE(bp.submitterLastName, '') submitterLname
          , COALESCE(bp.submitterFirstName, '') submitterFname
          , COALESCE(bp.submitterMiddleInitial, '') submitterMi
          , COALESCE(bp.submitterName, '') submitterName
          , COALESCE(bp.submitterContactName, '') submitterContactName
          , COALESCE(bp.submitterPhoneNumber, '') submitterCommunicationNumber
          , COALESCE(bp.submitterExtension, '') submitterExtension
          , COALESCE(bp.submitterEmail, '') submitterEmail
          -- Non-submitter detail
          , COALESCE(bp.type, '${EDI_SUBMITTER_TYPE_ENUM.Default}') type
          , COALESCE(bp.lastName, '') lname
          , COALESCE(bp.firstName, '') fname
          , COALESCE(bp.middleInitial, '') mi
          , COALESCE(bp.name, '') name
          , COALESCE(bp.ID, null) billingProviderId
          , COALESCE(bp.contactName, '') contactName
          , COALESCE(bp.phone, bp.email, '') communicationNumber
          , COALESCE(bp.extension, '') extension
          , COALESCE(bp.email, '') email
          , COALESCE(bp.taxonomyCode, null) taxonomyCode
          , COALESCE(bp.suffix, '') suffix
          , COALESCE(bp.npi, '') npi
          , COALESCE(bp.addressLine1, '') address1
          , COALESCE(bp.addressLine2, '') address2
          , COALESCE(bp.city, '') city
          , COALESCE(bp.state, '') state
          , COALESCE(bp.zip, '') postalCode
          , COALESCE(bp.taxIdType, '') taxIdType
          , COALESCE(bp.taxId, '') taxId
          , COALESCE(bp.payToAddressLine1, '') payToAddress1
          , COALESCE(bp.payToCity, '') payToCity
          , COALESCE(bp.payToAddressLine2, '') payToAddress2
          , COALESCE(bp.payToState, '') payToState
          , COALESCE(bp.payToZipCode, '') payToPostalCode
          , COALESCE(bp.payToNonPersonEntity, '') payToNonPersonEntity
          , COALESCE(bp.payToPerson, '') payToPerson
          , COALESCE(bp.clearinghouse, '') clearinghouse
          , COALESCE(u.signature, '') signature
          , COALESCE(cbcs.includeDescription, 0) includeDescription
          , COALESCE(cbcs.acceptAssignment, 0) acceptAssignment
          , COALESCE(cbcs.signatureOnFile, 0) signatureOnFile
       FROM BillingProfiles bp
       JOIN User u
         ON u.ID = :id
  LEFT JOIN ClinicBillingClaimsSetting cbcs
         ON cbcs.clinicId = bp.clinicId
      WHERE bp.ID = :billingProfileId
        AND bp.clinicId = :clinicId
        AND bp.active = 1`,
    { clinicId, id, billingProfileId },
  );
  const raw = (res?.[0] as any) || null;
  if (!raw) return null;
  return {
    settings: {
      includeDescription: !!raw.includeDescription,
      acceptAssignment: !!raw.acceptAssignment,
      signatureOnFile: !!raw.signatureOnFile,
    },
    submitter: {
      type: raw.submitterType,
      lname: raw.submitterLname,
      fname: raw.submitterFname,
      mi: raw.submitterMi,
      name: raw.submitterName,
      contactName: raw.submitterContactName,
      communicationNumber: raw.submitterCommunicationNumber,
      extension: raw.submitterExtension,
      email: raw.submitterEmail,
      clearinghouse: raw.clearinghouse,
      hasDetails:
        raw.submitterType === EDI_SUBMITTER_TYPE_ENUM.Individual
          ? (raw.submitterLname || raw.submitterFname) !== ''
          : raw.submitterName !== '',
    },
    provider: {
      id: raw.billingProviderId,
      type: raw.type,
      lname: raw.lname,
      fname: raw.fname,
      mi: raw.mi,
      name: raw.name,
      contactName: raw.contactName,
      communicationNumber: raw.communicationNumber,
      extension: raw.extension,
      email: raw.email,
      taxonomyCode: raw.taxonomyCode,
      suffix: raw.suffix,
      npi: raw.npi,
      address1: raw.address1,
      address2: raw.address2,
      city: raw.city,
      state: raw.state,
      postalCode: raw.postalCode,
      taxIdType: raw.taxIdType,
      taxId: raw.taxId,
    },
    payTo: {
      address1: raw.payToAddress1,
      address2: raw.payToAddress2,
      city: raw.payToCity,
      state: raw.payToState,
      postalCode: raw.payToPostalCode,
      nonPersonEntity: raw.payToNonPersonEntity,
      person: raw.payToPerson,
      hasDetails:
        (raw.payToAddress1 ||
          raw.payToCity ||
          raw.payToState ||
          raw.payToPostalCode) !== '',
    },
  } as ClinicProviderEdiDetailsType;
};

export type CommonProviderEdiDetailsType = {
  id: string;
  type: EDI_SUBMITTER_TYPE_ENUM;
  lname: string;
  fname: string;
  mi: string;
  name: string;
  contactName: string;
  communicationNumber: string;
  extension: string;
  email: string;
};

export type ClinicProviderEdiDetailsType = {
  settings: {
    includeDescription: boolean;
    acceptAssignment: boolean;
    signatureOnFile: boolean;
  };
  submitter: CommonProviderEdiDetailsType & {
    hasDetails: boolean;
    clearinghouse: KnownClearingHouses;
  };
  provider: CommonProviderEdiDetailsType & {
    taxonomyCode: string | null;
    suffix: string;
    npi: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    taxIdType: string;
    taxId: string;
    signature: string;
  };
  payTo: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    nonPersonEntity: boolean;
    person: string;
    hasDetails: boolean;
  };
};
