import { fetchAuthSession } from '@aws-amplify/auth';
import { ChiroUpAPI } from '@chiroup/client-core/functions/ChiroUpAPI';
import { fileTypes } from '@chiroup/core/constants/fileTypes';
import { DownloadProgressType } from '@chiroup/core/enums/DownloadProgress.enum';
import { NoteLocation } from '@chiroup/core/enums/NoteTypes.enum';
import { ClinicLocation } from '@chiroup/core/types/Clinic.type';
import {
  convertDayjsToUTC,
  convertFromUTC,
  createDayjs,
} from '@chiroup/core/functions/time';
import {
  Appointment,
  AppointmentForEither,
  AppointmentForUI,
  AppointmentHistoryResult,
  Appointments,
  AppointmentUser,
  AvailableSlotsResponse,
  CreateRecurringAppointmentsParams,
} from '@chiroup/core/types/Appointment.type';
import { AppointmentActivity } from '@chiroup/core/types/AppointmentActivity.type';
import {
  CommTypes,
  Communication,
  EHRManualEntryActivity,
} from '@chiroup/core/types/Communication.type';
import { Condition } from '@chiroup/core/types/Condition.type';
import { Exercise, ExercisesByPhase } from '@chiroup/core/types/Exercise.type';
import { Modalities } from '@chiroup/core/types/Modalities.type';
import { PatientNotifications } from '@chiroup/core/types/Notification.type';
import { Patient } from '@chiroup/core/types/Patient.type';
import { PatientNote } from '@chiroup/core/types/PatientNote.type';
import { PatientRequest } from '@chiroup/core/types/PatientRequest.type';
import { PatientSurvey } from '@chiroup/core/types/PatientSurvey.type';
import {
  currentOrAllEnum,
  PatientTrack,
} from '@chiroup/core/types/PatientTrack.type';
import {
  Complaint,
  EncounterInfo,
  OfficeExerciseHistory,
  Visit,
  VisitPlanType,
} from '@chiroup/core/types/PatientVisit.type';
import { TestOrder } from '@chiroup/core/types/Test.type';
import { Vital } from '@chiroup/core/types/Vital.type';
import { WorkflowStatus } from '@chiroup/core/types/Workflow.type';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { v4 as uuidv4 } from 'uuid';
import { SendSurveysFormValues } from '../components/patients/hooks/usePatientCommunications';
import { CreatePatient } from '../components/patients/types/CreatePatient.type';

export interface SaveExercisesParams {
  clinicId?: number;
  userId?: string;
  patientId?: string;
  exercises?: Exercise[];
  carePlanId?: number;
  complete?: boolean;
}

export type AppointmentDurationChangeParams = {
  day: string;
  userId: string;
  appointmentId: string;
  duration: number;
};

export type PatientNotification = {
  notificationType: PatientNotifications;
  id: string;
};

const patientService = () => {
  const list = async (
    params: {
      search?: string;
      limit?: number;
      skip?: string;
      showDeceased?: boolean;
    },
    selectedClinic?: number,
  ) => {
    return ChiroUpAPI.get('api', `/patients/${selectedClinic}/patients`, {
      queryParams: params,
    });
  };

  type lastAppointmentCacheType = {
    expires: number;
    previous: AppointmentForUI;
    latest: AppointmentForUI;
  };

  const lastAppointmentCache: { [key: string]: lastAppointmentCacheType } = {};

  function cacheLastAppointment(
    id: string,
    previous: AppointmentForUI | null | undefined,
    latest: AppointmentForUI | null | undefined,
  ) {
    /**
     * We don't need a memory leak. This only holds them for an hour
     * and they are cleaned up every time a new one is cached. So,
     * no garbage collection should be needed.
     */
    Object.keys(lastAppointmentCache).forEach((key) => {
      if (lastAppointmentCache[key].expires < new Date().getTime()) {
        delete lastAppointmentCache[key];
      }
    });
    //if (!previous || !latest) return;

    lastAppointmentCache[id] = {
      expires: new Date().getTime() + 1000 * 60 * 5, // Only available once or for 5 min.
      previous: (previous || {}) as AppointmentForUI,
      latest: (latest || {}) as AppointmentForUI,
    };
  }

  const getPatientSSN = async ({
    clinicId,
    patientId,
  }: {
    clinicId?: number;
    patientId?: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/patients/${patientId}/ssn`,
      {},
    );
  };

  const duplicates = async (
    selectedClinic?: number,
  ): Promise<{
    email: Patient[][];
    lastNameDateOfBirth: Patient[][];
    name: Patient[][];
    phone: Patient[][];
  }> => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${selectedClinic}/patient-duplicates`,
      {},
    );
  };

  const pending = async (selectedClinic?: number): Promise<Patient[]> => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${selectedClinic}/patient-pending`,
      {},
    );
  };

  const approve = async (
    selectedClinic: number,
    patientId: string,
  ): Promise<Patient> => {
    return ChiroUpAPI.put(
      'api',
      `/patients/${selectedClinic}/patient-pending/${patientId}`,
      {
        body: {
          action: 'approve',
        },
      },
    );
  };

  const deny = async (
    selectedClinic: number,
    patientId: string,
  ): Promise<Patient> => {
    return ChiroUpAPI.put(
      'api',
      `/patients/${selectedClinic}/patient-pending/${patientId}`,
      {
        body: {
          action: 'deny',
        },
      },
    );
  };

  const merge = async (
    selectedClinic: number,
    patientIds: string[],
    confirm?: boolean,
    newPatientData?: CreatePatient,
  ): Promise<Patient> => {
    return ChiroUpAPI.post('api', `/patients/${selectedClinic}/patient-merge`, {
      body: {
        patientIds,
        confirm,
        newPatientData,
      },
    });
  };

  const exists = async (
    params: {
      fname?: string;
      lname?: string;
      email?: string;
      phone?: string;
      dateOfBirth?: string;
    },
    selectedClinic?: number,
  ) => {
    const res: { data: Patient[]; lastKey: string } = await ChiroUpAPI.get(
      'api',
      `/patients/${selectedClinic}/patients/check-exists`,
      {
        queryParams: params,
      },
    );
    return res;
  };

  const findOne = (id?: string, selectedClinic?: number): Promise<Patient> => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${selectedClinic}/patients/${id}`,
      {},
    );
  };

  const update = (patient: Partial<Patient>, selectedClinic?: number) => {
    const { ID, ...restOfPatient } = patient;
    return ChiroUpAPI.put('api', `/patients/${selectedClinic}/patients/${ID}`, {
      body: restOfPatient,
    });
  };

  const del = (patientId: string, selectedClinic?: number) => {
    return ChiroUpAPI.del(
      'api',
      `/patients/${selectedClinic}/patients/${patientId}`,
      {},
    );
  };

  const create = (val: CreatePatient, selectedClinic?: number) => {
    return ChiroUpAPI.post('api', `/patients/${selectedClinic}/patients`, {
      body: val,
    });
  };

  // Surveys
  const listSurveys = (
    params: { lastKey?: string },
    patientId: string,
    selectedClinic: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${selectedClinic}/patients/${patientId}/surveys`,
      {
        queryParams: params,
      },
    );
  };

  const sendSurvey = (
    values: SendSurveysFormValues,
    selectedClinic?: number,
    encounterId?: string,
  ): Promise<Communication> => {
    const { patientId, ...body } = values;
    if (encounterId) {
      body.encounterId = encounterId;
    }
    return ChiroUpAPI.post(
      'api',
      `/patients/${selectedClinic}/patients/${patientId}/surveys`,
      {
        body,
      },
    );
  };

  const getSurvey = (
    id?: string,
    patientId?: string,
    selectedClinic?: number,
  ): Promise<PatientSurvey> => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${selectedClinic}/patients/${patientId}/surveys/${id}`,
      {},
    );
  };

  const editVisit = (
    id: string,
    values: Partial<Visit>,
    selectedClinic?: number,
    patientId?: string,
    canUpdateSigned = false,
  ) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${selectedClinic}/${patientId}/${id}`,
      { body: values, queryParams: { canUpdateSigned } },
    );
  };

  const saveVisitExerciseHistory = (
    id: string,
    values: Partial<OfficeExerciseHistory>,
    selectedClinic?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${selectedClinic}/${patientId}/${id}/exercise-history`,
      { body: values },
    );
  };

  const deleteVisitExerciseHistory = (
    id: string,
    selectedClinic?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/encounters/${selectedClinic}/${patientId}/${id}/exercise-history`,
      {},
    );
  };

  const getVisitExerciseHistory = async (
    id: string,
    patientId?: string,
    carePlanId?: number | null,
    selectedClinic?: number,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${selectedClinic}/${patientId}/${id}/exercise-history${
        carePlanId ? `?carePlanId=${carePlanId}` : ''
      }`,
      {},
    );
  };

  const getVisit = async ({
    id,
    patientId,
    clinicId,
  }: {
    id: string;
    patientId: string;
    clinicId?: number;
  }) => {
    const res = (await ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/${id}/`,
      {},
    )) as Visit;

    const timezone = res.tz as string;

    return {
      ...res,
      createdAt: createDayjs({ datetime: res.createdAt as number, timezone }),
      updatedAt: createDayjs({ datetime: res.updatedAt as number, timezone }),
      visitDate: createDayjs({ datetime: res.visitDate as number, timezone }),
      signedAt: createDayjs({ datetime: res.signedAt as number, timezone }),
      modalitiesCompletedAt: createDayjs({
        datetime: res.modalitiesCompletedAt as number,
        timezone,
      }),
      modalitiesLastUpdated: createDayjs({
        datetime: res.modalitiesLastUpdated as number,
        timezone,
      }),
    };
  };

  const getMostRecentVisit = (
    patientId: string,
    clinicId?: number,
    hasAccess?: boolean,
  ): Promise<{
    activeComplaints: Complaint[];
    inactiveComplaints: Complaint[];
    mostRecentVisit: Visit;
  } | null> => {
    if (!hasAccess) {
      return Promise.resolve(null);
    }
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/most-recent-visit`,
      {},
    );
  };

  const addVisit = (
    patientId?: string,
    selectedClinic?: number,
    body?: any,
  ): Promise<{ visitId: number }> => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${selectedClinic}/${patientId}`,
      { body },
    );
  };

  const addAppointment = async (
    selectedClinic?: number,
    locationId?: number,
    sessionId?: string,
    body?: any,
    notify?: boolean,
    doubleBook?: boolean,
  ): Promise<AppointmentForUI> => {
    const res = await ChiroUpAPI.post(
      'api',
      `/encounters/${selectedClinic}/appointment?sessionId=${sessionId}`,
      {
        body: {
          appointment: {
            ...body,
            // startTimes are on each of the slots.
            // startTime: convertDayjsToUTC({
            //   datetime: body?.startTime as Dayjs,
            // }),
            locationId,
          },
          notify,
          doubleBook,
        },
      },
    );

    return convertAppointmentForUI<AppointmentForUI>(res);
  };

  const listDashboardAppointments = async ({
    patientId,
    clinicId,
    queryParams,
  }: {
    patientId: string;
    clinicId: number;
    queryParams: {
      startDate?: string;
      endDate?: string;
      statuses?: string;
      limit?: number;
      skip: number;
    };
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/appointment`,
      {
        queryParams,
      },
    );
  };
  const listAppointmentsByDates = async (
    selectedClinic: number,
    locationId: number,
    queryParams: {
      startDate: string;
      endDate: string;
    },
  ): Promise<Appointments> => {
    const res = await ChiroUpAPI.get<{ appointments: Appointment[] }>(
      'api',
      `/encounters/${selectedClinic}/appointment?location=${locationId}`,
      {
        queryParams,
      },
    );

    return convertAppointmentsForUI<Appointments>(res?.appointments || []);
  };

  const getPatientAppointmentHistory = async ({
    clinicId,
    locationId,
    patientId,
  }: {
    clinicId: number;
    locationId: number;
    patientId: string;
  }): Promise<AppointmentHistoryResult> => {
    return ChiroUpAPI.get('api', `/encounters/${clinicId}/appointment`, {
      queryParams: {
        appointmentHistory: true,
        location: locationId,
        patientId,
      },
    });
  };

  const listAppointmentsByPatient = async (
    selectedClinic: number,
    patientId: string,
    complete?: boolean,
  ): Promise<AppointmentForUI[]> => {
    if (!patientId) {
      return Promise.resolve([]);
    }

    const res = await ChiroUpAPI.get<{ appointments: Appointment[] }>(
      'api',
      `/encounters/${selectedClinic}/appointment`,
      {
        queryParams: { patientId, complete },
      },
    );

    return convertAppointmentsForUI<AppointmentForUI[]>(
      res?.appointments || [],
    );
  };

  const listAppointmentActivity = (
    selectedClinic: number,
    appointmentId: string,
    groupId?: number,
  ): Promise<AppointmentActivity[]> => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${selectedClinic}/appointment/${appointmentId}/activity`,
      {
        queryParams: { groupId },
      },
    );
  };

  const getAvailability = async (
    selectedClinic: number,
    queryParams: {
      location: number;
      startDate: string;
      endDate: string;
      treatment: number;
    },
  ): Promise<AvailableSlotsResponse> => {
    const res = (await ChiroUpAPI.get(
      'api',
      `/encounters/${selectedClinic}/availability`,
      {
        queryParams,
      },
    )) as AvailableSlotsResponse;

    return Object.entries(res).reduce(
      (obj: AvailableSlotsResponse, [key, value]) => {
        obj[key] = Object.entries(value).reduce(
          (
            obj2: {
              [key: string]: AppointmentForUI[];
            },
            [key2, value2],
          ) => {
            obj2[key2] = convertAppointmentsForUI<AppointmentForUI[]>(value2);
            return obj2;
          },
          {},
        );
        return obj;
      },
      {},
    );
  };

  const lastAppointmentUndo = async (
    id: string,
    clinicId?: number,
    sessionId?: string,
  ) => {
    const cachedObject: lastAppointmentCacheType = lastAppointmentCache[id];
    const restPath = `/encounters/${clinicId}/appointment/${cachedObject?.previous.id}?sessionId=${sessionId}`;
    const payload = {
      appointment: {
        ...cachedObject?.previous,
        updatedAt: new Date().getTime(),
        startTime: convertDayjsToUTC({
          datetime: cachedObject?.previous?.startTime as Dayjs,
        }),
      },
      dataForClient: {
        type: 'updateAppointment',
      },
    };

    if (cachedObject) {
      /**
       * We only allow this once. If it is used for undoing or notifying
       * it is toast.
       */
      delete lastAppointmentCache[id];

      return ChiroUpAPI.put('api', restPath, {
        body: payload,
      });
    } else {
      return new Promise<any>((_, reject) => {
        reject({
          status: 404,
          data: { message: 'The last-saved update could not be found.' },
        });
      });
    }
  };

  const lastAppointmentNotify = async (id: string) => {
    const cachedObject: lastAppointmentCacheType = lastAppointmentCache[id];
    const patientId = cachedObject?.latest?.patientId;
    const clinicId = cachedObject?.latest?.clinicId;
    const appointmentId = cachedObject?.latest?.id;
    if (!patientId || !clinicId) {
      return new Promise((_, reject) => {
        reject({
          status: 404,
          data: { message: 'The request could not be generated.' },
        });
      });
    }
    delete lastAppointmentCache[id]; // Only works ONCE to notify or undo, not both.
    return ChiroUpAPI.post('api', `/patients/${clinicId}/${patientId}/notify`, {
      body: {
        notificationType: PatientNotifications.AppointmentRescheduled,
        id: appointmentId,
      },
    });
  };

  const updateAppointment = async (
    selectedClinic?: number,
    sessionId?: string,
    appointment?: Partial<AppointmentForUI>,
    dataForClient?: any,
    notify?: boolean,
    removeRecurringId?: boolean,
    appliedFee?: {
      amount: number;
      subtype: string;
      description: string;
      name: string;
    },
    doubleBook?: boolean,
  ): Promise<AppointmentForUI | any> => {
    /**
     * In order to undo an appointment or to even notify the user of changes,
     * we have to keep a record of what was saved and the previous version.
     * Each POST gets a unique ID that can be used to roll it back or to
     * create a notification.
     */
    const id = uuidv4();

    /**
     * Note: This can be called with _partial_ appointments. That is, just changing
     * the duration of an appointment not the startTime. Without a startTime, it's
     * tough to check it. This means, of course, that the duration of past appointments
     * can be set in via a manual XHR request. Since the UI does not support it, the
     * exposure is pretty minimal.
     */

    const restPath = `/encounters/${selectedClinic}/appointment/${appointment?.id}?sessionId=${sessionId}`;
    const res = await ChiroUpAPI.put<Appointment>('api', restPath, {
      body: {
        appointment: {
          ...appointment,
          startTime: appointment?.startTime
            ? convertDayjsToUTC({
                datetime: appointment?.startTime as Dayjs,
              })
            : undefined,
        },
        dataForClient,
        notify,
        removeRecurringId,
        appliedFee,
        doubleBook,
      },
    });
    if (appointment?.deleted) {
      return res;
    }
    const convertedAppointment = convertAppointmentForUI<AppointmentForUI>(res);
    cacheLastAppointment(
      id,
      dataForClient?.wholeAppointment,
      convertedAppointment,
    );
    return { ...convertedAppointment, transactionId: id };
  };

  const updateAppointmentFromSchedule = async (
    selectedClinic?: number,
    sessionId?: string,
    appointment?: Partial<AppointmentForUI>,
    dataForClient?: any,
    notify?: boolean,
  ): Promise<AppointmentForUI | any> => {
    const id = uuidv4();
    const restPath = `/encounters/${selectedClinic}/appointment/schedule/${appointment?.id}?sessionId=${sessionId}`;
    const res = await ChiroUpAPI.put<Appointment>('api', restPath, {
      body: {
        appointment: {
          ...appointment,
          startTime: appointment?.startTime
            ? convertDayjsToUTC({
                datetime: appointment?.startTime as Dayjs,
              })
            : undefined,
        },
        dataForClient,
        notify,
      },
    });
    const convertedAppointment = convertAppointmentForUI<AppointmentForUI>(res);
    cacheLastAppointment(
      id,
      dataForClient?.wholeAppointment,
      convertedAppointment,
    );
    return { ...convertedAppointment, transactionId: id };
  };

  const getAppointment = async (
    timezone: string,
    clinicId?: number,
    appointmentId?: string,
  ): Promise<AppointmentForUI> => {
    const res = await ChiroUpAPI.get<Appointment>(
      'api',
      `/encounters/${clinicId}/appointment/${appointmentId}`,
      {},
    );
    return convertAppointmentForUI<AppointmentForUI>(res);
  };

  const deleteVisit = (
    id: string,
    patientId: string,
    selectedClinic: number,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/encounters/${selectedClinic}/${patientId}/${id}`,
      {},
    );
  };

  const listVisit = async (
    patientId: string,
    params: { skip?: number; tracks?: number },
    selectedClinic: number,
  ) => {
    const res = await ChiroUpAPI.get(
      'api',
      `/encounters/${selectedClinic}/${patientId}`,
      {
        queryParams: params,
      },
    );

    return {
      ...res,
      data:
        res?.data?.map((visit: any) => {
          const timezone = visit.tz as string;
          return {
            ...visit,
            startTime: createDayjs({ datetime: visit.startTime, timezone }),
            endTime: createDayjs({ datetime: visit.endTime, timezone }),
            createdAt: createDayjs({ datetime: visit.createdAt, timezone }),
            updatedAt: createDayjs({ datetime: visit.updatedAt, timezone }),
            visitDate: createDayjs({ datetime: visit.visitDate, timezone }),
            signedAt: createDayjs({ datetime: visit.signedAt, timezone }),
            modalitiesCompletedAt: createDayjs({
              datetime: visit.modalitiesCompletedAt,
              timezone,
            }),
            modalitiesLastUpdated: createDayjs({
              datetime: visit.modalitiesLastUpdated,
              timezone,
            }),
          };
        }) || [],
    };
  };

  // Communication
  const listCommunication = (
    params: {
      lastKey?: string;
      patientId?: string;
      recipientId?: string;
      encounterId?: string;
      chart?: boolean;
      type?: string | string[] | null;
      startDate?: string;
      endDate?: string;
    },
    selectedClinic: number,
    searchParams?: {
      dateRange?: DateValueType;
      type: string | string[] | null;
    },
  ) => {
    if (searchParams?.type || searchParams?.dateRange) {
      const { type, dateRange } = searchParams;
      const startDate = dateRange?.startDate?.toString();
      const endDate = dateRange?.endDate?.toString();
      if (type) {
        params.type = type;
      }
      if (startDate && endDate) {
        params.startDate = startDate;
        params.endDate = endDate;
      }
    }

    return ChiroUpAPI.get('api', `/${selectedClinic}/communication`, {
      queryParams: {
        ...params,
      },
    });
  };

  const getCommunication = (
    clinicId?: number,
    id?: string,
  ): Promise<Communication> => {
    return ChiroUpAPI.get('api', `/${clinicId}/communication/${id}`, {});
  };

  const getRequest = (
    clinicId?: number,
    patientId?: string,
    id?: string,
  ): Promise<PatientRequest> => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/patients/${patientId}/requests/${id}`,
      {},
    );
  };

  const resetPassword = (
    clinicId?: number,
    patientId?: string,
    body?: { temporaryPassword?: string },
  ): Promise<Patient> => {
    return ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/patients/${patientId}`,
      {
        body,
      },
    );
  };

  const sendReviewLink = (
    clinicId: number,
    userId: string,
    methodValue: string,
    method: string,
    patientId?: string,
    location?: number,
    encounterId?: string,
  ): Promise<Communication> => {
    if (patientId) {
      return ChiroUpAPI.post(
        'api',
        `/patients/${clinicId}/patient/${patientId}/google-review-link`,
        { body: { method, methodValue, location, encounterId } },
      );
    } else {
      return ChiroUpAPI.post(
        'api',
        `/patients/${clinicId}/google-review-link`,
        { body: { method, methodValue, location, encounterId } },
      );
    }
  };

  const listExercises = async (
    clinicId?: number,
    patientId?: string,
    carePlanId?: number | string | null,
  ): Promise<ExercisesByPhase> => {
    const res: ExercisesByPhase = await ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/patients/${patientId}/exercises${
        carePlanId ? `?carePlanId=${Number(carePlanId)}` : ''
      }`,
      {},
    );

    return {
      1: res[1]?.map((exer) => ({ ...exer, $id: exer.ID, $uiid: uuidv4() })),
      2: res[2]?.map((exer) => ({ ...exer, $id: exer.ID, $uiid: uuidv4() })),
      3: res[3]?.map((exer) => ({ ...exer, $id: exer.ID, $uiid: uuidv4() })),
    };
  };

  const saveExercises = ({
    clinicId,
    userId,
    patientId,
    exercises,
    carePlanId,
    complete = false,
  }: SaveExercisesParams): Promise<ExercisesByPhase> => {
    return ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/patients/${patientId}/exercise/update`,
      {
        body: { exercises, carePlanId },
        queryParams: { complete },
      },
    );
  };

  const getReport = async (clinicId?: number, conditionReportId?: string) => {
    const res = await ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/report/condition/${conditionReportId}`,
      {},
    );
    const report = res;
    if (
      ((report && !report.refConditions) || !report.refConditions?.length) &&
      report.condition?.name !== 'Condition Report'
    ) {
      report.exercisePlan = true;
    }

    if (report?.releaseReport?.releaseDate) {
      report.releaseReport.releaseDate = dayjs(
        report.releaseReport.releaseDate,
      ).format('MM/DD/YYYY');
    }

    return report;
  };

  const updateReport = async (
    value: any,
    clinicId?: number,
    userId?: string,
    conditions?: Condition[],
  ) => {
    return ChiroUpAPI.patch(
      'api',
      `/patients/${clinicId}/report/condition/${value.ID}`,
      {
        body: value,
      },
    );
  };

  const createReport = async (
    value: any,
    clinicId?: number,
    conditions?: Condition[],
    encounterId?: string,
  ) => {
    const sum = value?.summary;

    const conditionsNameAndIds: { name: string; ID: number }[] | undefined =
      conditions?.map((condition) => {
        return { name: condition.name, ID: condition.ID };
      });

    const initialReportBody = {
      visits: value?.visits,
      conditions: conditionsNameAndIds || [],
      summary: sum,
      reportType: CommTypes.InitialReport,
      encounterId: encounterId,
      isReferred: value?.isReferred,
      healthCareProvider: {
        ID: value?.healthcareID,
        name: '',
      },
    };

    if (encounterId) {
      value.encounterId = encounterId;
    }
    const res = await ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/report/condition`,
      {
        body: value,
      },
    );

    let pcpCommunication: Communication | undefined;
    if (encounterId && !value?.exercisePlan && value?.summary) {
      pcpCommunication = await saveEncounterPCPReport(
        initialReportBody,
        clinicId || -1,
        value?.patient,
      );
    }
    if (pcpCommunication && res) {
      res.pcpCommunication = pcpCommunication;
    }
    return res;
  };

  const saveReport = async (
    value: any,
    clinicId?: number,
    userId?: string,
    conditions?: Condition[],
    encounterId?: string,
  ) => {
    if (value.ID) {
      return updateReport(value, clinicId, userId, conditions);
    } else {
      return createReport(value, clinicId, conditions, encounterId);
    }
  };

  const emailReport = async (
    body: {
      ID: number | string;
      temporaryPassword?: string;
      communicationUrl?: string;
      locationId?: number;
      type?: string;
      email?: string;
    },
    clinicId?: number,
    userId?: string,
  ): Promise<string> => {
    const res = await ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/report/condition/${body.ID}/email`,
      {
        body,
      },
    );
    return res?.[0]?.url;
  };

  const emailExercises = async (
    data: any,
    clinicId?: number,
  ): Promise<string> => {
    const res = await ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/exercises/${data.ID}/email`,
      { body: data },
    );
    return res?.[0]?.url;
  };

  const deleteReport = async (
    body: { ID?: number; deleted: boolean; patient?: string },
    clinicId?: number,
    userId?: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/patients/${clinicId}/report/condition/${body.ID}`,
      {},
    );
  };

  const downloadConsentForm = async (url?: string) => {
    return ChiroUpAPI.get('api', `/consents/download`, {
      queryParams: { key: url },
    });
  };

  const saveCarePlanTest = async ({
    body,
    clinicId,
    visitId,
    patientId,
  }: {
    body: Partial<TestOrder>;
    clinicId?: number;
    visitId?: string;
    patientId?: string;
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/${patientId}/${visitId}/test`,
      {
        body,
      },
    );
  };

  const listCarePlanModalities = async (
    clinicId?: number,
    visitId?: string,
    patientId?: string,
    careplan = false,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/${visitId}/modality`,
      {
        queryParams: { careplan },
      },
    );
  };

  const saveCarePlanModality = async (
    body: Partial<Modalities>[],
    complete: boolean,
    completedAt?: number | null,
    clinicId?: number,
    visitId?: string,
    patientId?: string,
    plan?: VisitPlanType,
  ) => {
    return await ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/${patientId}/${visitId}/modality`,
      {
        body: { modalities: body, complete: complete, completedAt, plan },
      },
    );
  };

  const savePatientVitals = async (
    body: Partial<Vital>,
    clinicId?: number,
    visitId?: string,
    patientId?: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/${patientId}/${visitId}/vitals`,
      {
        body,
      },
    );
  };

  const deleteCarePlanTest = async (
    id: number,
    clinicId?: number,
    visitId?: string,
    patientId?: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/encounters/${clinicId}/${patientId}/${visitId}/test/${id}`,
      {},
    );
  };

  const listCarePlanTests = async (
    carePlanId: number | null,
    clinicId?: number,
    visitId?: string,
    patientId?: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/${visitId}/test${
        carePlanId ? `?carePlanId=${carePlanId}` : ''
      }`,
      {},
    );
  };

  type UploadTestResultsParams = {
    file: File;
    clinicId?: number;
    patientId?: string;
    visitId?: string;
    id?: number;
  };

  const uploadTestResults = async ({
    file,
    clinicId,
    patientId,
    visitId,
    id,
  }: UploadTestResultsParams): Promise<string | void> => {
    try {
      const fileExtension = file.name.split('.').pop();
      if (!fileExtension || !fileTypes.includes(fileExtension)) {
        throw new Error('Invalid file type');
      }

      const payload = await ChiroUpAPI.post(
        'api',
        `/encounters/${clinicId}/${patientId}/${visitId}/test/${id}`,
        {
          body: { fileExtension: fileExtension, fileType: file.type },
        },
      );

      const form: FormData = new FormData();

      for (const field in payload.fields) {
        form.append(field, payload.fields[field]);
      }
      form.append('Content-Type', file.type);
      form.append('file', file);

      await fetch(payload.url, {
        method: 'POST',
        body: form,
      });

      await fetch(payload.url, { method: 'POST', body: form });
      return payload.fields.key;
    } catch (error) {
      console.error(error);
    }
  };

  type UploadChartFileParams = {
    file: File;
    clinicId?: number;
    patientId?: string;
  };

  const uploadChartFile = async ({
    file,
    clinicId,
    patientId,
  }: UploadChartFileParams): Promise<string | void> => {
    try {
      const fileExtension = file.name.split('.').pop();
      if (!fileExtension || !fileTypes.includes(fileExtension)) {
        throw new Error('Invalid file type');
      }

      const payload = await ChiroUpAPI.post(
        'api',
        `/encounters/${clinicId}/${patientId}/chart/files`,
        {
          body: { fileExtension: fileExtension, fileType: file.type },
        },
      );

      const form: FormData = new FormData();

      for (const field in payload.fields) {
        form.append(field, payload.fields[field]);
      }
      form.append('Content-Type', file.type);
      form.append('file', file);

      await fetch(payload.url, {
        method: 'POST',
        body: form,
      });

      await fetch(payload.url, { method: 'POST', body: form });
      return payload.fields.key;
    } catch (error) {
      console.error(error);
    }
  };

  const createPatientNote = async (
    body: Partial<PatientNote>,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/patients/${patientId}/notes`,
      {
        body,
      },
    );
  };
  const updatePatientNote = async (
    body: Partial<PatientNote>,
    clinicId?: number,
    patientId?: string,
  ) => {
    const { id, ...rest } = body;
    return ChiroUpAPI.put(
      'api',
      `/patients/${clinicId}/patients/${patientId}/notes/${id}`,
      {
        body: rest,
      },
    );
  };
  const deletePatientNote = async (
    noteId: string,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/patients/${clinicId}/patients/${patientId}/notes/${noteId}`,
      {},
    );
  };
  const patientNotesList = async (
    params: {
      search?: {
        category: string | null;
        type: string | string[] | null;
        locations?: NoteLocation[] | null;
      };
      limit?: number;
      skip?: number;
    },
    encounter = false,
    patientId: string,
    clinicId?: number,
  ) => {
    const queryParams: { [key: string]: string | string[] | number } = {};

    if (params.search) {
      if (params.search.category) {
        queryParams.category = params.search.category;
      }
      if (params.search.type) {
        queryParams.type = params.search.type;
      }
      if (params.search.locations) {
        queryParams.locations = params.search.locations;
      }
    }

    if (params.limit) {
      queryParams.limit = params.limit;
    }
    if (params.skip) {
      queryParams.skip = params.skip;
    }
    if (encounter) {
      queryParams.encounter = String(encounter);
    }

    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/patients/${patientId}/notes`,
      {
        queryParams,
      },
    );
  };

  const getEncounterPCPReport = async (
    clinicId: number,
    patientId: string,
    reportId: string,
    reportType: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/${reportId}/${reportType}/pcpreport`,
      {},
    );
  };

  const saveEncounterPCPReport = async (
    body: any,
    clinicId: number,
    patientId: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/${patientId}/pcpreport`,
      {
        body,
      },
    );
  };

  const saveManualPCPReport = async (
    body: any,
    clinicId: number,
    patientId: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/entry`,
      {
        body: {
          encounterDate: null,
          data: body,
        },
      },
    );
  };

  const updateManualPCPReport = async (
    body: any,
    clinicId: number,
    patientId: string,
    reportId: string,
  ) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/entry/${reportId}/pcpReport`,
      {
        body,
      },
    );
  };

  const updateEncounterPCPReport = async (
    body: any,
    clinicId: number,
    patientId: string,
    reportId: string,
  ) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/${patientId}/pcpreport/${reportId}`,
      {
        body,
      },
    );
  };

  const createChartEntry = async (
    body: Partial<EHRManualEntryActivity>,
    clinicId?: number,
    patientId?: string,
    encounterId?: string,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/entry`,
      {
        body,
      },
    );
  };

  const updateChartEntry = async (
    body: Partial<EHRManualEntryActivity>,
    clinicId?: number,
    patientId?: string,
    encounterId?: string,
  ) => {
    const { id, ...rest } = body;
    const communicationId = rest.communicationId;
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/entry/${communicationId}/${id}`,
      {
        body: rest,
      },
    );
  };

  const deleteChartEntry = async (
    id: string,
    communicationId: string,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/entry/${communicationId}/${id}`,
      {},
    );
  };

  const getChartEntry = async (
    communicationId: string,
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/entry/${communicationId}`,
      {},
    );
  };
  const unsignNote = ({
    id,
    selectedClinic,
    patientId,
    force = false,
  }: {
    id: string;
    selectedClinic?: number;
    patientId?: string;
    force?: boolean;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${selectedClinic}/${patientId}/${id}/unsign`,
      {
        queryParams: { force },
      },
    );
  };

  const listSignedSoapNotes = async (
    params: { lastKey?: string; patientId?: string },
    clinicId?: number,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${params?.patientId}/chart/list-signed-soap-notes`,
      {
        queryParams: {
          ...params,
        },
      },
    );
  };

  const signedSoapNotes = async (
    arr: string[],
    clinicId?: number,
    patientId?: string,
  ) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/chart/signed-soap-notes`,
      {
        queryParams: {
          arr: arr.join(','),
        },
      },
    );
  };

  const checkRecurringAvailability = async (
    appointmentUsedToCreateRecurringTimeStamp: number,
    clinicId: number,
    appointment: Partial<AppointmentForUI>,
  ) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/appointment/check-recurring-availability`,
      {
        body: {
          appointmentUsedToCreateRecurringTimeStamp,
          appointment: appointment,
          daysOfWeek: appointment.daysOfWeek?.join(','),
        },
      },
    );
  };

  const createRecurringAppointments = async ({
    body,
    clinicId,
    sessionId,
    locationId,
    notify,
  }: CreateRecurringAppointmentsParams) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/appointment/appointment-recurring?sessionId=${sessionId}`,
      {
        body: {
          appointment: { ...body, locationId },
          notify,
        },
      },
    );
  };

  const deleteRecurringAppointments = async (
    appointment: AppointmentForUI,
    clinicId: number,
    sessionId: string,
  ) => {
    return ChiroUpAPI.del(
      'api',
      `/encounters/${clinicId}/appointment/${appointment.id}/appointment-recurring?sessionId=${sessionId}`,
      {},
    );
  };

  const updateRecurringAppointments = async ({
    body,
    clinicId,
    sessionId,
    locationId,
    notify,
    originalAppointment,
  }: CreateRecurringAppointmentsParams) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/appointment/appointment-recurring?sessionId=${sessionId}`,
      {
        body: {
          appointment: { ...body, locationId },
          originalAppointment: originalAppointment
            ? {
                ...originalAppointment,
                startTime: convertDayjsToUTC({
                  datetime: originalAppointment.startTime,
                }),
              }
            : undefined,
          notify,
        },
      },
    );
  };

  const getPreviousPlan = async ({
    clinicId,
    patientId,
    carePlanId,
    visitNumber,
    encounterDate,
    trackId,
  }: {
    clinicId: number;
    patientId: string;
    carePlanId: number;
    visitNumber: number;
    encounterDate: number;
    trackId?: number;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/careplan/${carePlanId}/previous-plan`,
      {
        queryParams: { visitNumber, encounterDate, trackId },
      },
    );
  };
  const getDashboard = async ({
    clinicId,
    locationId,
    userId,
    startDate,
    endDate,
    skip,
  }: {
    clinicId: number;
    locationId?: number;
    userId: string;
    startDate: string;
    endDate: string;
    skip?: number;
  }) => {
    const res = await ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/dashboard`,
      {
        queryParams: { skip, startDate, endDate, locationId },
      },
    );
    return res;
  };

  const getDashboardNew = async ({
    clinicId,
    locationId,
    userId,
    startDate,
    endDate,
    skip,
    workflowStatus,
  }: {
    clinicId: number;
    workflowStatus: WorkflowStatus;
    locationId?: number;
    userId: string;
    startDate: string;
    endDate: string;
    skip?: number;
  }) => {
    const res = await ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/dashboard-new`,
      {
        queryParams: { skip, startDate, endDate, locationId, workflowStatus },
      },
    );
    return res;
  };

  const getBillingBalances = async ({
    clinicId,
    patientId,
  }: {
    clinicId: number;
    patientId: string;
  }) => {
    if (!patientId || !clinicId) {
      return Promise.resolve(null);
    } // no REST if you're wicked.
    return ChiroUpAPI.get(
      'api',
      `/transactions/${clinicId}/patients/${patientId}/billing/balances`,
      {},
    );
  };

  const editCarePlan = async ({
    patientId,
    carePlanId,
    encounterInfo,
    clinicId,
  }: {
    patientId: string;
    carePlanId: number;
    encounterInfo: Partial<EncounterInfo>;
    clinicId: number;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/patients/${patientId}/careplan/${carePlanId}`,
      {
        body: { carePlan: encounterInfo },
      },
    );
  };

  const EHRMigrationImportPatientsList = async ({
    clinicId,
    patients,
  }: {
    clinicId?: number;
    patients: any[];
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/patients/${clinicId}/patient-ehr-migration`,
      {
        body: {
          patients,
        },
      },
    );
  };

  const EHRMigrationImportPatients = async ({
    clinicId,
    data,
  }: {
    clinicId?: number;
    data: any[];
  }) => {
    const accessToken = (await fetchAuthSession()).tokens?.idToken;
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    return await axios
      .post(
        `${
          import.meta.env.VITE_CHIROUP_API
        }/settings/${clinicId}/migration/import/patients`,
        { data },
        options,
      )
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  };

  const EHRMigrationImportAppointments = async ({
    clinicId,
    data,
  }: {
    clinicId?: number;
    data: any[];
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/settings/${clinicId}/migration/import/appointments`,
      {
        body: {
          data,
        },
      },
    );
  };

  const EHRMigrationImportInsurance = async ({
    clinicId,
    data,
  }: {
    clinicId?: number;
    data: any[];
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/settings/${clinicId}/migration/import/insurance`,
      {
        body: {
          data,
        },
      },
    );
  };

  const getEncounterHistory = async ({
    clinicId,
    patientId,
    encounterId,
  }: {
    clinicId: number;
    patientId: string;
    encounterId: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/${patientId}/${encounterId}/history`,
      {},
    );
  };

  const EHRMigrationProgress = async ({
    clinicId,
    type,
  }: {
    clinicId?: number;
    type: DownloadProgressType;
  }) => {
    if (!clinicId) return;
    return ChiroUpAPI.get(
      'api',
      `/settings/${clinicId}/migration/progress/${type}`,
      {},
    );
  };

  const deleteComplaints = async ({
    clinicId,
    patientId,
    encounterId,
    complaintIds,
    force,
    currentOrAll,
  }: {
    clinicId?: number;
    patientId?: string;
    encounterId?: string;
    complaintIds: string[];
    force: boolean;
    currentOrAll?: currentOrAllEnum;
  }) => {
    const complaints = complaintIds.join(',');
    return ChiroUpAPI.del(
      'api',
      `/encounters/${clinicId}/${patientId}/${encounterId}/${complaints}/complaint/delete-complaints`,
      {
        queryParams: { force, currentOrAll },
      },
    );
  };

  const pauseComplaints = async ({
    clinicId,
    patientId,
    encounterId,
    complaintIds,
    type,
  }: {
    clinicId?: number;
    patientId?: string;
    encounterId?: string;
    complaintIds: string[];
    type: string;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/${patientId}/complaint/pause-complaints`,
      {
        body: {
          encounterId,
          complaintIds,
          type,
        },
      },
    );
  };

  const trackList = async ({
    clinicId,
    patientId,
  }: {
    clinicId?: number;
    patientId?: string;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/patients/${clinicId}/tracks/${patientId}`,
      {},
    );
  };
  const trackCreate = async ({
    clinicId,
    patientId,
    track,
  }: {
    clinicId?: number;
    patientId?: string;
    track: PatientTrack;
  }) => {
    return ChiroUpAPI.post('api', `/patients/${clinicId}/tracks/${patientId}`, {
      body: track,
    });
  };
  const trackUpdate = async ({
    clinicId,
    patientId,
    track,
  }: {
    clinicId?: number;
    patientId?: string;
    track: PatientTrack;
  }) => {
    return ChiroUpAPI.put('api', `/patients/${clinicId}/tracks/${patientId}`, {
      body: track,
    });
  };
  const trackDelete = async ({
    clinicId,
    patientId,
    trackId,
  }: {
    clinicId?: number;
    patientId: string;
    trackId: number;
  }) => {
    //soft delete so put.
    return ChiroUpAPI.put(
      'api',
      `/patients/${clinicId}/tracks/${patientId}/${trackId}`,
      {},
    );
  };

  const expireSurvey = async ({
    clinicId,
    patientId,
    surveyId,
  }: {
    clinicId: number;
    patientId: string;
    surveyId: string;
  }): Promise<any> => {
    return ChiroUpAPI.put(
      'api',
      `/patients/${clinicId}/patients/${patientId}/surveys/${surveyId}/expire`,
      { body: {} },
    );
  };

  const expireRequest = async ({
    clinicId,
    patientId,
    requestId,
    param,
    consent = false,
  }: {
    clinicId: number;
    patientId: string;
    requestId: string;
    param?: string;
    consent?: boolean;
  }): Promise<any> => {
    return ChiroUpAPI.put(
      'api',
      `/patients/${clinicId}/patients/${patientId}/requests/${requestId}/expire`,
      {
        body: {
          otherType: param,
          consent,
        },
      },
    );
  };

  const emailAppointmentsSummary = async (
    patient: Patient,
    appointments: AppointmentForUI[],
    clinic: any,
    primaryLocation: any,
    locations: ClinicLocation[] = [],
  ): Promise<any> => {
    const payload = {
      patientId: patient.ID,
      clinic,
      appointments,
      primaryLocation,
      locations,
    };

    return ChiroUpAPI.post(
      'api',
      `/patients/${clinic.ID}/patients/${patient.ID}/appointments/email`,
      {
        body: payload,
      },
    );
  };

  const updateRecording = async ({
    clinicId,
    recordingId,
    summary,
    description,
  }: {
    clinicId: number;
    recordingId: number;
    summary: string;
    description: string;
  }) => {
    return ChiroUpAPI.put(
      'api',
      `/encounters/${clinicId}/recording/${recordingId}`,
      {
        body: { summary, description },
      },
    );
  };

  const getRecording = async ({
    clinicId,
    recordingId,
  }: {
    clinicId: number;
    recordingId: number;
  }) => {
    return ChiroUpAPI.get(
      'api',
      `/encounters/${clinicId}/recording/${recordingId}`,
      {},
    );
  };

  const deleteRecording = async ({
    clinicId,
    recordingId,
  }: {
    clinicId: number;
    recordingId: number;
  }) => {
    return ChiroUpAPI.del(
      'api',
      `/encounters/${clinicId}/recording/${recordingId}`,
      {},
    );
  };

  const generateSubjectiveFromRecordings = async ({
    clinicId,
    visitId,
    noteSoFar,
  }: {
    clinicId: number;
    visitId: string;
    noteSoFar: string | { type: 'doc'; content: any[] };
  }) => {
    return ChiroUpAPI.post(
      'api',
      `/encounters/${clinicId}/visits/${visitId}/generate-subjective`,
      {
        body: { noteSoFar },
      },
    );
  };

  return {
    addAppointment,
    addVisit,
    approve,
    checkRecurringAvailability,
    create,
    createPatientNote,
    createRecurringAppointments,
    del,
    deleteCarePlanTest,
    deletePatientNote,
    deleteReport,
    deleteVisit,
    deleteVisitExerciseHistory,
    deny,
    downloadConsentForm,
    duplicates,
    editVisit,
    emailReport,
    exists,
    findOne,
    getAppointment,
    getAvailability,
    getCommunication,
    getEncounterPCPReport,
    getMostRecentVisit,
    getReport,
    getRequest,
    getSurvey,
    getVisit,
    getVisitExerciseHistory,
    lastAppointmentNotify,
    lastAppointmentUndo,
    list,
    listAppointmentsByDates,
    listAppointmentsByPatient,
    listAppointmentActivity,
    listCarePlanModalities,
    listCarePlanTests,
    listCommunication,
    listExercises,
    listSurveys,
    listVisit,
    merge,
    patientNotesList,
    pending,
    resetPassword,
    saveCarePlanModality,
    saveCarePlanTest,
    saveEncounterPCPReport,
    saveExercises,
    savePatientVitals,
    saveReport,
    saveVisitExerciseHistory,
    sendReviewLink,
    sendSurvey,
    update,
    updateAppointment,
    updateEncounterPCPReport,
    updatePatientNote,
    uploadTestResults,
    uploadChartFile,
    EHRMigrationImportPatientsList: EHRMigrationImportPatientsList,
    EHRMigrationImportPatients: EHRMigrationImportPatients,
    EHRMigrationImportAppointments: EHRMigrationImportAppointments,
    EHRMigrationImportInsurance: EHRMigrationImportInsurance,
    createChartEntry,
    getChartEntry,
    updateChartEntry,
    deleteChartEntry,
    unsignNote,
    listSignedSoapNotes,
    signedSoapNotes,
    deleteRecurringAppointments,
    updateRecurringAppointments,
    getPreviousPlan,
    emailExercises,
    getBillingBalances,
    getDashboard,
    editCarePlan,
    getPatientSSN,
    listDashboardAppointments,
    saveManualPCPReport,
    updateManualPCPReport,
    getEncounterHistory,
    EHRMigrationProgress,
    deleteComplaints,
    pauseComplaints,
    getDashboardNew,
    updateAppointmentFromSchedule,
    trackList,
    trackUpdate,
    trackDelete,
    trackCreate,
    expireSurvey,
    expireRequest,
    emailAppointmentsSummary,
    getPatientAppointmentHistory,
    updateRecording,
    getRecording,
    deleteRecording,
    generateSubjectiveFromRecordings,
  };
};

export default patientService();

const convertAppointmentsForUI = <T>(
  appointments: AppointmentForEither[] | Appointments,
): T => {
  if (Array.isArray(appointments)) {
    return appointments.map((appt) => convertAppointmentForUI(appt)) as T;
  } else {
    return Object.entries(appointments).reduce(
      (obj: Appointments, [key, value]) => {
        obj[key] = Object.entries(value).reduce(
          (
            obj2: {
              [key: string]: AppointmentUser;
            },
            [key1, value1],
          ) => {
            if (value1.isRoom) {
              obj2[key1] = {
                id: value1.id,
                name: value1.name,
                appointments: convertAppointmentsForUI(
                  value1.appointments as Appointment[],
                ) as AppointmentForUI[],
                hours: value1.hours,
                isRoom: value1.isRoom || false,
              };
            } else {
              obj2[key1] = {
                id: value1.id,
                name: value1.name,
                fname: value1.fname,
                lname: value1.lname,
                profileImage: value1.profileImage,
                appointments: convertAppointmentsForUI(
                  value1.appointments as Appointment[],
                ) as AppointmentForUI[],
                hours: value1.hours,
                overrides: value1.overrides,
              };
            }

            return obj2;
          },
          {},
        );
        return obj;
      },
      {},
    ) as T;
  }
};

const convertAppointmentForUI = <T>(appointment: AppointmentForEither): T => {
  return {
    ...appointment,
    startTime: convertFromUTC({
      datetime: appointment?.startTime,
      timezone: appointment?.tz,
    }),
  } as T;
};
