import { Icons, TiptapEditorBase, tiptapExtensions } from '@chiroup/components';
import { FormError } from '@chiroup/core/types/ErrorResponse.type';
import type { JSONContent } from '@tiptap/react';
import { forwardRef, useContext, useMemo } from 'react';
import { MeContext } from '../../../contexts/me.context';
import Code from './Code';
import Macro from './Macro';
import codeSuggestion from './codeSuggestion';
import macroSuggestion from './macroSuggestion';

type Props = {
  value?: JSONContent | string;
  name?: string;
  label?: string;
  onChange?: (val?: any) => any;
  className?: string;
  containerClassName?: string;
  small?: boolean;
  toolbar?: boolean;
  html?: boolean;
  text?: boolean;
  disabled?: boolean;
  macros?: boolean;
  billingCodes?: boolean;
  extraButtons?: {
    icon: Icons;
    type: 'setContent';
    fn: () => any;
  }[];
  errors?: FormError;
  labelColor?: boolean;
  rightSide?: React.ReactNode;
};

export const TiptapEditor = forwardRef<any, Props>(
  (
    {
      value,
      name,
      label,
      className = 'border block w-full border-gray-300 rounded-b-md transition duration-150 ease-in-out sm:leading-5 dark:bg-darkGray-700 dark:border-darkGray-600 dark:text-darkGray-200 outline-none p-2',
      containerClassName,
      onChange,
      small,
      toolbar = true,
      html = false,
      text = false,
      disabled = false,
      macros = false,
      billingCodes = false,
      extraButtons,
      errors,
      labelColor = false,
      rightSide,
    },
    ref,
  ) => {
    const { me } = useContext(MeContext);

    const extensions = useMemo(() => {
      let copiedExtensions = [...tiptapExtensions];
      if (macros) {
        copiedExtensions.push(
          Macro.configure({
            HTMLAttributes: {
              class: 'macro',
            },
            suggestion: macroSuggestion({ macros: me.macros || [] }),
          }),
        );
      }
      if (billingCodes) {
        copiedExtensions = copiedExtensions.filter((i) => i.name !== 'codes');
        copiedExtensions.push(
          Code.configure({
            HTMLAttributes: {
              class:
                'billing-code rounded-lg border border-gray-300 bg-gray-50 px-1',
            },
            suggestion: codeSuggestion({ clinicId: me.selectedClinic?.ID }),
          }),
        );
      }
      return copiedExtensions;
    }, [macros, billingCodes, me.macros, me.selectedClinic?.ID]);

    return (
      <TiptapEditorBase
        value={value}
        name={name}
        label={label}
        className={className}
        containerClassName={containerClassName}
        onChange={onChange}
        small={small}
        toolbar={text ? false : toolbar}
        html={html}
        text={text}
        disabled={disabled}
        extraButtons={extraButtons}
        errors={errors}
        labelColor={labelColor}
        extensions={extensions}
        ref={ref}
        rightSide={rightSide}
      />
    );
  },
);
